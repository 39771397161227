import { Checkbox, Collapse } from "antd";
import { Button, Drawer, Space, Menu } from "antd";
import { useState } from "react";
import React from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";

import MapWayPointSelector from "./map-waypoint-selector";
import {
  createCommand_RunRecentFailedJob,
  createCommand_set_position_by_waypoint,
} from "../command-builder";
import RunRecentFailedJobCommandBuilder from "./run-recent-failed-job";
import GoCartMoveCommander from "./gocart-move-control";
import MissionCommandButtons from "../../missions/ui-mission-command";
import GoCartJoystickCommand from "./gocart-joystick-command";

const { Panel } = Collapse;

const GoCartCommandBuilderContainer2 = (props) => {
  const onCommandCreated = props.OnCommandCreated;
  const isJoystickEnabled = props.JoystickEnabled;
  const joystickToken = props.JoystickToken;

  const handleSetPositionByWayPoint_SelectionConfirmed = (waypoint) => {
    console.log("Selected waypoint is null", waypoint);
    if (waypoint == null) {
      return;
    }

    const cmd = createCommand_set_position_by_waypoint(waypoint.name, 3);
    if (onCommandCreated) onCommandCreated(cmd);
  };

  const handleRunRecentFailedJobCommandCreated = (cmd) => {
    Modal.confirm({
      title: "Do you want to run the recent failed job?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (onCommandCreated) onCommandCreated(cmd);
      },
      onCancel() {},
    });
  };

  const handleMoveCommand = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd, true);
  };

  const handleJoystickCommand = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd, true);
  };

  const handleMissionCommand = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd);
  };

  return (
    <>
      <Collapse defaultActiveKey={0}>
        <Panel header="Move control" key="0">
          <GoCartMoveCommander
            //   visible={isRobotControlEnabled}
            //   onMoveCommand={handleMoveCommand}
            EnableKeyboard={true}
            OnCommandCreated={handleMoveCommand}
          ></GoCartMoveCommander>
        </Panel>

        {isJoystickEnabled && (
          <Panel header="Joystick" key="12">
            <GoCartJoystickCommand
              JoystickToken={joystickToken}
              OnCommandCreated={handleJoystickCommand}
            ></GoCartJoystickCommand>
          </Panel>
        )}

        <Panel header="Mission" key="6">
          <MissionCommandButtons
            OnCommandCreated={handleMissionCommand}
          ></MissionCommandButtons>
        </Panel>

        <Panel header="Command: Set position by waypoint" key="1">
          <MapWayPointSelector
            OnWayPointSelectionConfirmed={
              handleSetPositionByWayPoint_SelectionConfirmed
            }
          ></MapWayPointSelector>
        </Panel>
        <Panel header="Command: Run_Recent_Failed_Job " key="3">
          <RunRecentFailedJobCommandBuilder
            OnCommandCreated={handleRunRecentFailedJobCommandCreated}
          ></RunRecentFailedJobCommandBuilder>
        </Panel>
        {/* <Panel header="Send Message To Etom" key="5">
          <p></p>
        </Panel>
        <Panel header="Send Message To GoCart" key="4">
          <p></p>
        </Panel> */}

        {/* <Panel header="Mission" key="6">
          <GoCartMissionControl></GoCartMissionControl>
        </Panel> */}
      </Collapse>
    </>
  );
};
export default GoCartCommandBuilderContainer2;
