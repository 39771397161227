// import { Button, notification, Space } from "antd";
import "./robot-status.css";
import { Avatar, Badge, Col, Progress, Row, Space, Tooltip } from "antd";
import { Fragment } from "react";
import { red, green } from "@ant-design/colors";
import BatteryIcon from "../status/battery-status";
import RobotConnectionStatus from "./robot-connection-status";
import {
  HomeOutlined,
  LoadingOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
} from "@ant-design/icons";
const RobotStatusViewer = (props) => {
  const robotStatus = {
    ...RobotStatusViewer.defaultProps.RobotStatus,
    ...props.RobotStatus,
  };
  const robotInfo = {
    ...RobotStatusViewer.defaultProps.RobotInfo,
    ...props.RobotInfo,
  };
  // const connectionStatus = {
  //   ...RobotStatusViewer.defaultProps.ConnectionStatus,
  //   ...props.ConnectionStatus,
  // };
  const missionInfo = {
    ...RobotStatusViewer.defaultProps.MissionInfo,
    ...props.MissionInfo,
  };
  //   console.log(robotStatus);
  const speed =
    robotStatus.velo?.velo_dx == null
      ? null
      : Math.round(robotStatus.velo?.velo_dx * 100);
  // const speed = (robotStatus?.velo?.velo_dx==null??null);
  return (
    <Fragment>
      <Row gutter={16}>
        <Col offset={1}>
          <div className="robot-status">
            <Badge
              showZero
              count={
                robotStatus?.connection_info?.count == null
                  ? "."
                  : robotStatus?.connection_info?.count
              }
              offset={[5, 20]}
              color={
                robotStatus?.connection_info?.count == null
                  ? "gray"
                  : robotStatus?.connection_info?.count <= 2
                  ? "green"
                  : robotStatus?.connection_info?.count <= 3
                  ? "orange"
                  : "red"
              }
            >
              <div className="avatar-container">
                <img src={robotInfo.icon} className="avatar" />
              </div>
            </Badge>
            <div className="robot-name">{robotInfo.robot_id}</div>
          </div>
        </Col>
        <Col span={4}>
          {/* <Space direction="vertical"> */}
          <BatteryIcon
            Level={robotStatus.battery.battery_level}
            Charging={robotStatus.battery.now_charging}
          ></BatteryIcon>
          <div className="status-info">
            <Space>
              Speed:{" "}
              {speed == null && (
                <Tooltip title="Unknown speed"> (...) </Tooltip>
              )}
              {speed != null && (
                <Progress
                  percent={speed}
                  steps={4}
                  status={speed < 88 ? "success" : "exception"}
                  strokeColor={[green[6], green[5], green[4], red[3], red[5]]}
                  format={(percent) =>
                    percent == 0 ? "0" : percent < 100 ? `${percent}%` : "Max"
                  }
                  // format={(percent) => `${percent}%`}
                ></Progress>
              )}
            </Space>
          </div>
          <div className="status-info">
            <Space>
              Status:
              {!robotStatus.status && (
                <Tooltip title="Unknown status"> (...) </Tooltip>
              )}
              {robotStatus.status}
            </Space>
          </div>
          {/* </Space> */}
        </Col>

        <Col>
          <RobotConnectionStatus
            // RobotId={robotStatus.RobotId}
            // ServerConnectionStatus={connectionStatus.ServerConnectionStatus}
            // PeerConnectionStatus={connectionStatus.PeerConnectionStatus}
            // RobotOnlineStatus={connectionStatus.RobotOnlineStatus}
            ShowAlert={true}
            ShowStatus={true}
          ></RobotConnectionStatus>
        </Col>
        <Col>
          {missionInfo?.MissionId && (
            <div className="status-info">
              <Space>
                Mission:
                {missionInfo?.MissionId} ({missionInfo?.Status})
              </Space>
              <div>
                {missionInfo?.Jobs && (
                  <Progress
                    // size={"small"}
                    // steps={missionInfo.Jobs.length}
                    // type="circle"
                    percent={
                      (missionInfo.CurrentJobIndex / missionInfo.Jobs.length) *
                      100
                    }
                    format={(percent) =>
                      `${missionInfo.CurrentJobIndex}/${missionInfo.Jobs.length} Jobs`
                    }
                  ></Progress>
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

RobotStatusViewer.defaultProps = {
  RobotInfo: {
    robot_id: null,
    icon: null,
    name: null,
  },
  RobotStatus: {
    error_info: null,
    battery: { battery_level: null, now_charging: null },
    manual_mode: null,
    velo: { velo_dx: null, velo_dy: null, velo_dz: null },
    connection_info: { count: null, user_ids: [] },
  },
  ConnectionStatus: {
    ServerConnectionStatus: null,
    PeerConnectionStatus: null,
    RobotOnlineStatus: null,
  },
  MissionInfo: null,
  // MissionInfo: {
  //   MissionId: "34343dfdf2344",
  //   Jobs: [3, 3, 4],
  //   CurrentJobIndex: 2,
  //   Status: "Finished",
  // },
};

export default RobotStatusViewer;
