import axios from "axios";
import { getFetchURL } from "../../common/setting";

export const getUserRobotListService = async (token) => {
  const url = getFetchURL("/api/user/robots");
  // const url = getFetchURL("/robots_user");
  return await axios.get(url, {
    headers: { authorization: token },
  });
};
