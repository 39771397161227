import { v4 as randomUUID } from "uuid";

export const missionsReducer = (state, action) => {
  switch (action.type) {
    case "SET_MISSIONLIST": {
      const origMissionsDescriptions = action.payload;
      const guiMissionsDescriptions = origMissionsDescriptions.map(
        (mission) => {
          if (mission.key == null) {
            return { ...mission, key: randomUUID() };
          } else {
            return mission;
          }
        }
      );

      return { ...state, missionsDescriptions: [...guiMissionsDescriptions] };
    }

    case "ADD_NEW_MISSION_AT": {
      const index = action.payload.index;

      const newMission = {
        ...defaultMissionDescription,
        key: randomUUID(),
      };

      const newMissionList = [...state.missionsDescriptions];
      newMissionList.splice(index + 1, 0, newMission);

      return {
        ...state,
        missionsDescriptions: newMissionList,
      };
    }

    case "UPDATE_MISSION_AT": {
      const index = action.payload.index;
      const updatedList = state.missionsDescriptions.map((mission, idx) => {
        if (index === idx) {
          return { ...mission, ...action.payload.missionDesc };
        } else {
          return mission;
        }
      });

      return { ...state, missionsDescriptions: updatedList };
    }

    case "UPDATE_MISSION": {
      const updatedList = state.missionsDescriptions.map((mission) => {
        if (mission.key === action.key) {
          return { ...mission, ...action.payload.missionDesc };
        } else {
          return mission;
        }
      });

      return { ...state, missionsDescriptions: updatedList };
    }

    case "DELETE_MISSION_AT": {
      const index = action.payload.index;
      const deletedList = [...state.missionsDescriptions];
      deletedList.splice(index, 1);

      return {
        ...state,
        missionsDescriptions: deletedList,
      };
    }

    case "DELETE_MISSION": {
      const deletedList = state.missionDescriptions.filter(
        (mission) => mission.key !== action.payload.key
      );

      return {
        ...state,
        missionsDescriptions: deletedList,
      };
    }

    default:
      return state;
  }
};

const defaultMissionDescription = {
  key: randomUUID(),
  mission_type: "SPOT_MISSION",
  mission_name: "Patrol mission",
  description: "SPOT patrol mission",
  mission_jobs: [{ job_id: 0, job_description: null }],
};

export const initialMissionBuildState = {
  missionsDescriptions: [defaultMissionDescription],
};
