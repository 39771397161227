import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PATH_TO_WARNING_AUDIO, getFetchURL } from "../../common/setting";
import { useRef } from "react";
import {
  Col,
  Radio,
  message,
  Layout,
  Row,
  Space,
  Switch,
  Alert,
  Button,
} from "antd";
import SpotControlPanel from "../../components/spot/ui-commands/spot-control-pannel";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";

import {
  UnlockOutlined,
  LockOutlined,
  CheckOutlined,
  CloseOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import RobotStatusViewer from "../../components/status/robot-status";
import { useServerConnection } from "../../contexts/ServerConnectionContext";
import { useRobotPeerConnection } from "../../contexts/RobotConnectionContext";
import VideoTrackViewerNew from "../../components/video-viewer/VideoTrackViewerNew";

const SpotTeleControlView = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const { id } = useParams();

  const {
    isOpen: signal_ready,
    isConnecting,
    message: signal_message,
    sendMessage: signal_send,
    subscribe,
    error: signal_Error,
    reconnectCountDown,
  } = useServerConnection();

  const audioAlarm = useRef(null);

  const {
    setRobotId,
    connectionState: rtcConnectionState,
    mediaTracks,
    channelMessage,
    channelSend,
    connect,
    // close: closePeerConnection,
    error,
    robotOnlineStatus,
  } = useRobotPeerConnection();

  const [robotStatus, setRobotStatus] = useState(null);
  const [robotErrorInfo, setRobotErrorInfo] = useState(null);
  const [missionStatus, setMissionStatus] = useState(null);

  // const {
  //   data: mapData,
  //   error: mapDataError,
  //   isPending: isPendingMap,
  // } = useFetchState(getFetchURL("/map/robot/" + id));

  const handleMoveCommand = (cmd) => {
    if (cmd == null) return;
    console.log("move command: ", cmd);
    if (channelSend == null) {
      alert("data channel is null!");
      return;
    }

    let move_cmd = JSON.stringify(cmd);

    const channelLabel = "chat";

    channelSend(channelLabel, move_cmd);
    console.log("command has been sent:", move_cmd);
  };

  const send_command_msg_to_robot = (cmd) => {
    let msg_cmd = JSON.stringify(cmd);

    const channelLabel = "chat";

    channelSend(channelLabel, msg_cmd);
    console.log("command has been sent:", msg_cmd);
  };

  useEffect(() => {
    setRobotId(id);
  }, [id]);

  useEffect(() => {
    if (!channelMessage) return;
    // log_debug("received message from robot: ", channelMessage);
    if (channelMessage.channel == "chat") {
      const data = JSON.parse(channelMessage.data);
      const msg_type = data["msg_type"];

      if (msg_type == "robot_state") {
        const msg_body = data["msg_body"];
        let robotInfo = msg_body["common_info"];
        const errorInfo = msg_body["error_info"];
        const missionInfo = msg_body["mission_info"];
        // robotInfo["icon"] = "/images/gocart.jpg";
        robotInfo["error_info"] = errorInfo;
        const connectionInfo = msg_body["connection_info"];
        if (connectionInfo != null) {
          robotInfo["connection_info"] = msg_body["connection_info"];
        }
        // console.log("robotInfo: ", robotInfo);
        // TODO: check map

        setRobotErrorInfo(errorInfo);
        setRobotStatus(robotInfo);
        setMissionStatus(missionInfo);
      }
    }
  }, [channelMessage]);

  // const onCameraOptionChanged = (cameraOption) => {
  //   console.log("camera changed to", cameraOption);
  // };

  const cameraOptions = [
    { label: "RGB", value: "rgb" },
    { label: "Depth", value: "depth" },
    { label: "RGB & Depth", value: "rgb&depth" },
  ];
  const [selectedCameraOption, setSelectedCameraOption] = useState("rgb");

  const onCameraOptionChanged = ({ target: { value } }) => {
    setSelectedCameraOption(value);

    const change_camera_cmd = {
      cmd: "camera_setting",
      data: {
        cmd_type: "camera",
        params: { channel: value },
      },
    };
    send_command_msg_to_robot(change_camera_cmd);
  };

  const playAlarm = (play) => {
    const audio = audioAlarm.current;
    if (play) audio.play();
    else audio.pause();
  };

  useEffect(() => {
    if (!robotErrorInfo) playAlarm(false);
    else playAlarm(true);

    // console.log("Error Info: ", robotErrorInfo);
  }, [robotErrorInfo]);

  const [isRobotControlEnabled, setRobotControlEnabled] = useState(true);

  const handleOnCommandCreated = (command, quite = false) => {
    console.log("Created command: ", command);
    if (channelSend == null) {
      alert("data channel is null!");
      return;
    }

    let cmd_message = JSON.stringify(command);

    const channelLabel = "chat";

    channelSend(channelLabel, cmd_message);

    console.log("Quite: ", quite);
    if (!quite) {
      messageApi.open({
        type: "info",
        content: "Command has been sent to robot!",
        style: {
          marginTop: "10vh",
        },
      });
    }

    console.log("command has been sent:", cmd_message);
  };

  const handleOnMissionCreatedOK = (missionDescription, quite = false) => {
    console.log("Mission description created: ", missionDescription);
    if (!signal_ready) {
      alert("connection to server is not ready!");
      return;
    }

    missionDescription["RobotId"] = id;

    const spot_mission_url = getFetchURL("/robot-mission");
    fetch(spot_mission_url, {
      body: JSON.stringify(missionDescription),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => {
        if (!response.ok) throw Error("Failed to make the mission");
        return response.json();
      })
      .then((data) => {
        console.log(JSON.stringify(data));
        if (data["ack"] === "success") {
          // save to db
          const job_data = data["job"];
          alert("Mission created!\nMission_id: " + job_data["mission_id"]);
        } else alert("Result: " + data["ack"] + "\n" + data["message"]);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          alert("Error: " + err.message);
        }
      });
  };

  const handleOnSortieCreatedOK = (missionDescriptions, quite = false) => {
    console.log("Mission description created: ", missionDescriptions);
    if (!signal_ready) {
      alert("connection to server is not ready!");
      return;
    }

    missionDescriptions["RobotId"] = id;

    const spot_mission_url = getFetchURL("/missions-assign/" + id);
    fetch(spot_mission_url, {
      body: JSON.stringify(missionDescriptions),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => {
        if (!response.ok) throw Error("Failed to make the mission");
        return response.json();
      })
      .then((data) => {
        console.log(JSON.stringify(data));
        if (data["ack"] === "success") {
          // save to db
          const job_data = data["job"];
          alert("Mission created!\nMission_ids: " + job_data["missions_ids"]);
        } else alert("Result: " + data["ack"] + "\n" + data["message"]);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          alert("Error: " + err.message);
        }
      });
  };

  const robotInfo = { robot_id: id, icon: "/images/spot.jpg", name: id };
  const connectionsStatus = {
    ServerConnectionStatus: signal_ready ? "connected" : "disconnected",
    PeerConnectionStatus: rtcConnectionState,
    RobotOnlineStatus: robotOnlineStatus,
  };

  return (
    <Layout>
      <Content>
        <div style={{ height: "100%", maxHeight: "100vh" }}>
          {contextHolder}
          <Row>
            <Col span={24}>
              <RobotStatusViewer
                RobotStatus={robotStatus}
                RobotInfo={robotInfo}
                ConnectionStatus={connectionsStatus}
                MissionInfo={missionStatus}
              ></RobotStatusViewer>
            </Col>
          </Row>

          <Row>
            <Col span={22}>
              <Radio.Group
                options={cameraOptions}
                onChange={onCameraOptionChanged}
                value={selectedCameraOption}
              />
              <Space>
                {/* <Checkbox>Show command panel </Checkbox> */}
                Show control:
                <Switch
                  onChange={(checked) => {
                    setRobotControlEnabled(checked);
                  }}
                  // checkedChildren={<UnlockOutlined />}
                  unCheckedChildren={<LockOutlined />}
                  checkedChildren={<CheckOutlined />}
                  // unCheckedChildren={<CloseOutlined />}
                  defaultChecked
                  checked={isRobotControlEnabled}
                />
              </Space>
              <audio
                ref={audioAlarm}
                id="alarm-sound"
                src={PATH_TO_WARNING_AUDIO}
                loop
              ></audio>
            </Col>
          </Row>
          <Row
          // gutter={16}
          >
            <Col span={24}>
              <div id="media" className={robotErrorInfo ? "blinking-div" : ""}>
                <VideoTrackViewerNew
                  mediaTracks={mediaTracks}
                  ShowDelayTime={true}
                  CameraStyle={{ height: "100%", maxHeight: "65vh" }}
                ></VideoTrackViewerNew>
              </div>
              {/* <div>
              <div>
                <SpotMoveCommander
                  onMoveCommand={handleMoveCommand}
                ></SpotMoveCommander>
              </div>
            </div> */}
            </Col>
          </Row>
        </div>
      </Content>
      <Sider
        width={450}
        theme="light"
        // trigger={
        //   !isRobotControlEnabled ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
        // }
        // zeroWidthTriggerStyle={zeroWidthTriggerStyle}
        collapsible
        collapsed={!isRobotControlEnabled}
        onCollapse={(value) => setRobotControlEnabled(!value)}
        breakpoint="md"
        collapsedWidth="0"
      >
        <SpotControlPanel
          OnCommandCreated={handleOnCommandCreated}
          OnMissionDescriptionCreatedOK={handleOnMissionCreatedOK}
          OnSortieDescriptionCreatedOK={handleOnSortieCreatedOK}
        ></SpotControlPanel>
      </Sider>
    </Layout>
  );
};

export default SpotTeleControlView;
