import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Input, Row, Slider, Space } from "antd";

const RobotPoseSelectionUI = (props) => {
  const [open, setOpen] = useState(props.Show);
  const [pose, setPose] = useState(props.Pose);
  const Title = props?.Title ?? "Select pose";

  //   const OnRobotLocationSelected = props.OnRobotLocationSelected;
  const OnValueChanged = props.OnValueChanged;
  const OnConfirmed = props.OnConfirmed;
  const OnCanceled = props.OnCanceled;

  useEffect(() => {
    setOpen(props.Show);
    setPose(props.Pose);
  }, [props.Pose, props.Show]);

  const onCancelButtonClicked = (evt) => {
    setOpen(false);
    if (OnCanceled) OnCanceled(evt);
  };

  const onConfirmedButtonClicked = (evt) => {
    setOpen(false);
    if (OnConfirmed) OnConfirmed(pose);
  };

  const onPoseChanged = (newPose) => {
    setPose(newPose);
    if (OnValueChanged) OnValueChanged(newPose);
  };

  return (
    <>
      <Drawer
        title={Title}
        placement="bottom"
        onClose={onCancelButtonClicked}
        open={open}
        width={500}
        height={250}
        extra={
          <Space>
            <Button
              onClick={onCancelButtonClicked}
              type="button"
              className="btn-secondary"
            >
              Cancel
            </Button>
            <Button onClick={onConfirmedButtonClicked} type="primary" block>
              Confirm
            </Button>
          </Space>
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            <Input
              placeholder="Please enter x"
              value={pose.x}
              onChange={(e) => {
                console.log("x changed: ", e);
                const newPose = {
                  ...pose,
                  x: e.target.value,
                };
                onPoseChanged(newPose);
              }}
            />
          </Col>
          <Col span={12}>
            <Input
              value={pose.y}
              onChange={(e) => {
                console.log("y changed: ", e);
                const newPose = {
                  ...pose,
                  y: e.target.value,
                };
                onPoseChanged(newPose);
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={2} style={{ textAlign: "left" }}>
            <label>Theta:</label>
          </Col>
          <Col span={14}>
            <Slider
              min={-314}
              max={314}
              value={pose.theta * 100}
              onChange={(val) => {
                const newPose = {
                  ...pose,
                  theta: val / 100,
                };
                onPoseChanged(newPose);
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default RobotPoseSelectionUI;
