import { Button, Col, message, Row, Select, Space } from "antd";
import { getFetchURL } from "../../../common/setting";
import useFetchState from "../../../utils/useFetchState";
import { useEffect, useState } from "react";
import { createCommand_set_position_by_waypoint } from "../command-builder";

const MapWayPointSelector = (props) => {
  const onWaypointSelectionChanged = props.OnWaypointSelectionChanged;
  const onWayPointSelectionConfirmed = props.OnWayPointSelectionConfirmed;

  const [messageApi, contextHolder] = message.useMessage();

  const fetchMapURL = getFetchURL("/maps");

  const {
    data: mapsItems,
    error: mapsFetchError,
    isPending: mapsFetchPending,
    setData: setMaps,
  } = useFetchState(fetchMapURL);

  const [selectedMap, setSelectedMap] = useState(null);

  const [waypointsData, setWaypointsData] = useState(null);
  const [isWaypointsLoading, setIsWaypointsLoading] = useState(true);
  const [errorWaypointsLoading, setErrorWaypointsLoading] = useState(null);

  const [selectedWaypoint, setSelectedWaypoint] = useState(null);

  const getDisplayingMapItems = () => {
    if (mapsItems == null) return [];

    const items = mapsItems.map((item) => {
      return {
        value: item["id"],
        label: item["name"],
      };
    });

    return items;
  };

  useEffect(() => {
    if (!selectedMap) {
      setWaypointsData(null);
      return;
    }

    const url = getFetchURL("/maps/" + selectedMap);
    const abortCtrl = new AbortController();
    // console.log("Fetch from url: ", url);
    fetch(url, { signal: abortCtrl.signal })
      .then((response) => {
        if (!response.ok)
          throw Error("Could not fetch the data from " + url + '"');
        return response.json();
      })
      .then((mapDetail) => {
        setWaypointsData(mapDetail?.resources?.waypoints);
        setIsWaypointsLoading(false);
        setErrorWaypointsLoading(null);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          setIsWaypointsLoading(false);
          setErrorWaypointsLoading(err.message);
        }
      });

    return () => abortCtrl.abort();
  }, [selectedMap]);

  const onButtonOkClicked = () => {
    //TODO: build set position command
    if (selectedWaypoint == null) {
      messageApi.open({
        type: "error",
        content: "Waypoint is not selected!",
      });
    }
    if (onWayPointSelectionConfirmed) {
      const waypoint = getWaypointDetails(selectedWaypoint);
      onWayPointSelectionConfirmed(waypoint);
    }
  };

  const getDisplayingMapWaypointsItems = () => {
    if (waypointsData == null) return [];

    const items = waypointsData.map((item) => {
      return {
        value: item["id"],
        label: item["name"],
      };
    });

    return items;
  };

  const getWaypointDetails = (waypointId) => {
    if (waypointsData == null || waypointId == null) return null;

    const result = waypointsData.find((waypoint) => waypoint.id === waypointId);
    return result;
  };

  return (
    <>
      {contextHolder}

      <Row gutter={24}>
        <Col span={6} style={{ textAlign: "left" }}>
          {" "}
          <label>Map:</label>
        </Col>
        <Col span={16}>
          <Select
            showSearch
            style={{
              width: 200,
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={getDisplayingMapItems()}
            onSelect={(val) => {
              setSelectedMap(val);
              setSelectedWaypoint(null);
              // console.log("Selected map: ", val);
            }}
            value={selectedMap}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6} style={{ textAlign: "left" }}>
          {" "}
          <label>Waypoint:</label>
        </Col>
        <Col span={12}>
          <Select
            showSearch
            style={{
              width: 200,
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={getDisplayingMapWaypointsItems()}
            onSelect={(val) => {
              setSelectedWaypoint(val);

              if (onWaypointSelectionChanged)
                onWaypointSelectionChanged(getWaypointDetails(val));
            }}
            value={selectedWaypoint}
          />{" "}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col>
          <Space>
            <Button type="button" className="btn-secondary" block>
              Cancel
            </Button>
            <Button type="primary" onClick={onButtonOkClicked} block>
              {" "}
              OK
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default MapWayPointSelector;
