import { Button } from "antd";
import { createCommand_RunRecentFailedJob } from "../command-builder";

const RunRecentFailedJobCommandBuilder = (props) => {
  const onCommandCreated = props.OnCommandCreated;
  const onButtonOkClicked = () => {
    if (onCommandCreated) {
      const cmd = createCommand_RunRecentFailedJob();
      onCommandCreated(cmd);
    }
  };

  return (
    <div>
      <p>
        It is abbreviated as CMD_RUN_RECENT_FAILED_JOB. If there is a failed
        job, a new job is created from the where it was stopped. Failed job can
        be checked by job_id in CMD_GET_RECENT_FAILED_JOB.
      </p>
      <Button type="primary" onClick={onButtonOkClicked}>
        {" "}
        Execute
      </Button>
    </div>
  );
};
export default RunRecentFailedJobCommandBuilder;
