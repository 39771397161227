const PROTOCOL = window.location.protocol;
const HOSTNAME = window.location.hostname;
// export const HOSTNAME = "10.252.117.23"; // keti desktop win
// export const HOSTNAME = "10.252.101.114";  // keti laptop_del ubuntu
// export const HOSTNAME = "175.126.123.199"; // cafe24 server
export const PORT = "8001";

export const PATH_JOB_LIST = "/jobs";

const HOST = HOSTNAME + ":" + PORT;

export const getDBFetchURL = (relativePath) => {
  var url = PROTOCOL + "//" + HOST + relativePath;
  return url;
};

export const RobotReservationDB_PORT = "8002";

export const getRobotReservationDBFetchURL = (relativePath) => {
  var url =
    PROTOCOL + "//" + HOSTNAME + ":" + RobotReservationDB_PORT + relativePath;
  return url;
};

export const Map_JSON_DB_PORT = "8003";

export const getMapDBFetchURL = (relativePath) => {
  var url = PROTOCOL + "//" + HOSTNAME + ":" + Map_JSON_DB_PORT + relativePath;
  return url;
};
