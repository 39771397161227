export const PATH_TO_WARNING_AUDIO = "/audio/warning.wav";

export const WAITING_TIME_BEFORE_AUTO_RECONNECT = 3000;
export const WAITING_TIME_TO_CHECK_FOR_DISCONNECTED = 3000;
export const MAX_NUMBER_OF_ATTEMP_TO_CHECK_FOR_DISCONNECTED = 3;

export const PROTOCOL = window.location.protocol;
// export const PROTOCOL = "http:";
export const HOSTNAME = window.location.hostname;
// export const HOSTNAME = "10.252.117.23"; // keti desktop win
// export const HOSTNAME = "10.252.101.114";  // keti laptop_del ubuntu
// export const HOSTNAME = "175.126.123.199"; // cafe24 server
// export const PORT = "8180";

export const PATH_ROBOTS_LIST = "/robots";

// const HOST = HOSTNAME + ":" + PORT + "/api";
const HOST = HOSTNAME + "/api";

// const PATH_WS_USER = "/wsuser";
const PATH_WS_USER = "/wsuser/v_0_2";

export const getFetchURL = (relativePath) => {
  var url = PROTOCOL + "//" + HOST + relativePath;
  return url;
};

export const getRobotsListFetchURL = () => {
  return getFetchURL(PATH_ROBOTS_LIST);
};

export const getCalibrationSeverURL = (relativePath) => {
  var url = PROTOCOL + "//" + HOSTNAME + ":" + 8089 + relativePath;
  return url;
};

export const getCalibrationURL = () => {
  var relativePath = "/calibrate";
  var url = PROTOCOL + "//" + HOSTNAME + ":" + 8089 + relativePath;
  return url;
};

export const getConnect2RobotWsURL = () => {
  if (PROTOCOL !== "https:") return "ws://" + HOST + PATH_WS_USER;
  else return "wss://" + HOST + PATH_WS_USER;
};
