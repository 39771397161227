import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  initialMissionBuildState,
  missionsReducer,
} from "../reducer/missionsReducer";
import { fetchJobDescriptions } from "../../../services/robot-job-schemas-services";

export const MissionBuilderContext = createContext(null);
export const MissionBuilderDispatchContext = createContext(null);

export function MissionsBuildProvider({ children }) {
  const [missionBuildState, dispatch] = useReducer(
    missionsReducer,
    initialMissionBuildState
  );
  const [dataLoading, setDataLoading] = useState(false);

  const [spotJobDescriptionList, setSpotJobDescriptionList] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    const getJobDescListPromise = fetchJobDescriptions();

    getJobDescListPromise
      .then((jobList) => {
        setSpotJobDescriptionList(jobList);
      })
      .finally(() => {
        setDataLoading(false);
      });
  }, []);

  const ret = {
    missionBuildState,
    spotJobDescriptionList,
    dataLoading,
  };

  return (
    <MissionBuilderContext.Provider value={ret}>
      <MissionBuilderDispatchContext.Provider value={{ dispatch }}>
        {children}
      </MissionBuilderDispatchContext.Provider>
    </MissionBuilderContext.Provider>
  );
}

export const useMissionBuilder = () => useContext(MissionBuilderContext);
export const useMissionBuilerDispatch = () =>
  useContext(MissionBuilderDispatchContext);
