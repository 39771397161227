import { Fragment, useEffect, useState } from "react";
import {
  createCommand_Connect,
  createCommand_EStop,
  createCommand_PowerOn,
  createCommand_Sit,
  createCommand_Stand,
  createCommand_Stop,
  createCommand_StrafeLeft,
  createCommand_StrafeRight,
  createCommand_TurnLeft,
  createCommand_TurnRight,
  createCommand_Walk,
  createCommand_WalkBackward,
  createCommand_WalkForward,
  createCommand_joint_joystick,
  createCommand_moveJoyStick,
} from "../spot-command-builder";
import "./spot-move-control.css";
import GamepadController from "../../joystick/JoystickController";

const SpotJoystickCommand = (props) => {
  const onCommandCreated = props.OnCommandCreated;
  const [spotMode, setSpotMode] = useState("");

  const button_dir_forward = 12;
  const button_dir_backward = 13;
  const button_dir_right = 15;
  const button_dir_left = 14;

  const button_cmd_b_stand = 1;
  const button_cmd_a_sit = 0;
  const button_cmd_x_walk = 2;
  const button_cmd_y_stop = 3;

  const button_cmd__r1 = 5;
  const button_cmd__r2 = 7;
  const button_cmd__l1 = 4;
  const button_cmd__l2 = 6;

  const button_cmd_back = 8;
  const button_cmd_start = 9;

  const button_cmd_logo = 16;

  const axes_dir_hor = 0;
  const axes_dir_ver = 1;
  const axes_ctr_hor = 2;
  const axes_ctr_ver = 3;

  const handleJoyStickCommand = (joyMsg) => {
    const axes = joyMsg.axes;
    const buttons = joyMsg.buttons;

    const isActivate = Math.abs(buttons[button_cmd__l1]) >= 0.1;

    // if (!isActivate) {
    //   return null;
    // }

    const ctrlKey = Math.abs(buttons[button_cmd__r1]) > 0.1;
    let cmd = null;

    if (buttons[button_dir_forward] == 1) {
      cmd = createCommand_WalkForward();
      onCommandCreated(cmd, true);
      return;
    } else if (buttons[button_dir_backward] == 1) {
      cmd = createCommand_WalkBackward();
      onCommandCreated(cmd, true);
      return;
    } else if (buttons[button_dir_left] == 1) {
      if (ctrlKey) cmd = createCommand_TurnLeft();
      else cmd = createCommand_StrafeLeft();
      onCommandCreated(cmd, true);
      return;
    } else if (buttons[button_dir_right] == 1) {
      if (ctrlKey) cmd = createCommand_TurnRight();
      else cmd = createCommand_StrafeRight();
      onCommandCreated(cmd, true);
      return;
    }

    if (buttons[button_cmd_a_sit] == 1) {
      cmd = createCommand_Sit();
      setSpotMode("READY");
      onCommandCreated(cmd, true);
      return;
    } else if (buttons[button_cmd_b_stand] == 1) {
      cmd = createCommand_Stand();
      setSpotMode("STAND");
      onCommandCreated(cmd, true);
      return;
    } else if (buttons[button_cmd_x_walk] == 1) {
      cmd = createCommand_Walk();
      setSpotMode("WALK");
      onCommandCreated(cmd, true);
      return;
    }

    if (buttons[button_cmd_y_stop] == 1) {
      cmd = createCommand_Stop();
      onCommandCreated(cmd, true);
      return;
    } else if (buttons[button_cmd_start] == 1) {
      cmd = createCommand_Connect();
      onCommandCreated(cmd, true);
      return;
    } else if (buttons[button_cmd_back] == 1) {
      cmd = createCommand_EStop();
      onCommandCreated(cmd, true);
      return;
    } else if (buttons[button_cmd_logo] == 1) {
      cmd = createCommand_PowerOn();
      onCommandCreated(cmd, true);
      return;
    }

    const is_axes_activated = axes.some((axis) => Math.abs(axis) >= 0.05);
    if (is_axes_activated) {
      if (ctrlKey || spotMode == "STAND") {
        const yaw = -axes[0] * 0.7;
        const roll = axes[2] * 0.7;
        const pitch = axes[1] * 0.7;
        const height = -axes[3] * 0.2;
        // const height = 0;

        cmd = createCommand_joint_joystick(
          Math.round((yaw + Number.EPSILON) * 100) / 100,
          Math.round((roll + Number.EPSILON) * 100) / 100,
          Math.round((pitch + Number.EPSILON) * 100) / 100,
          Math.round((height + Number.EPSILON) * 100) / 100
        );
        onCommandCreated(cmd, true);
        return;
      }
      if (spotMode == "WALK") {
        const axis_x = axes[0];
        const axis_y = axes[1];
        const axis_theta = axes[2];

        cmd = createCommand_moveJoyStick(axis_x, -axis_y, axis_theta);
        onCommandCreated(cmd, true);
        return;
      }
    }
    return;
  };

  return (
    <Fragment>
      <GamepadController
        // disabled={rtcConnectionState != "connected"}
        onGamepadStateUpdated={(joyMsg) => {
          handleJoyStickCommand(joyMsg);
        }}
        // debug={true}
        showVirtualJoystick={true}
      ></GamepadController>
    </Fragment>
  );
};

export default SpotJoystickCommand;
