import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Input, Popconfirm, Table, Tooltip } from "antd";
import {
  DownOutlined,
  CloseCircleTwoTone,
  DiffTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  inputType,
  handleSave,
  bindingFieldName,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const getFieldKey = () => {
    if (bindingFieldName) {
      return dataIndex + "." + bindingFieldName;
    } else {
      return dataIndex;
    }
  };

  const getFieldValue = () => {
    const val = record[dataIndex];
    if (bindingFieldName) {
      return val[bindingFieldName];
    }
    return val;
  };

  const setFieldValue = (newRecord, newVal) => {
    if (bindingFieldName) {
      return (newRecord[dataIndex][bindingFieldName] = newVal);
    }
    return (newRecord[dataIndex] = newVal);
  };

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [getFieldKey()]: getFieldValue(),
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();

      let oldVal = getFieldValue();
      let newVal = values[getFieldKey()];

      const noChanged = oldVal == newVal;

      if (noChanged) return;

      const newRecord = { ...record };
      setFieldValue(newRecord, newVal);

      handleSave(record, {
        ...record,
        ...newRecord,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        // style={{
        //   margin: 0,
        // }}
        name={getFieldKey()}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export const EditableTable = ({
  dataSource,
  setDataSource,
  handleDelete,
  handleAdd,
  handleSave,
  dataColumns,
  ...tableProps
}) => {
  const count = dataSource?.length ?? 0;
  //   const [count, setCount] = useState(2);
  //   const handleDelete = (key) => {
  //     const newData = dataSource.filter((item) => item.key !== key);
  //     setDataSource(newData);
  //   };

  const defaultColumns = [
    ...dataColumns,
    {
      title: "operation",
      dataIndex: "operation",
      key: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Tooltip title="Delete">
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteTwoTone />
            </Popconfirm>
          </Tooltip>
        ) : null,
    },
  ];

  //   const handleAdd = () => {
  //     const newData = {
  //       key: count,
  //       name: `Edward King ${count}`,
  //       age: "32",
  //       address: `London, Park Lane no. ${count}`,
  //     };
  //     setDataSource([...dataSource, newData]);
  //     setCount(count + 1);
  //   };

  //   const handleSave = (row) => {
  //     const newData = [...dataSource];
  //     const index = newData.findIndex((item) => row.key === item.key);
  //     const item = newData[index];
  //     newData.splice(index, 1, {
  //       ...item,
  //       ...row,
  //     });
  //     setDataSource(newData);
  //   };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        bindingFieldName: col.bindingFieldName,
      }),
    };
  });
  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        size="small"
        {...tableProps}
      />
      <Button
        onClick={handleAdd}
        block
        style={{
          marginTop: 10,
        }}
        icon={<PlusCircleTwoTone />}
      >
        Add row
      </Button>
    </div>
  );
};
export default EditableTable;
