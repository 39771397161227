import { Fragment, useEffect, useState } from "react";
import {
  createCommand_Move,
  createCommand_joystick_finish,
  createCommand_joystick_move,
  createCommand_joystick_move_finish,
  createCommand_joystick_move_start,
  createCommand_joystick_start,
} from "../command-builder";
import GamepadController from "../../joystick/JoystickController";

const GoCartJoystickCommand = (props) => {
  const onCommandCreated = props.OnCommandCreated;
  const [joystickToken, setJoystickToken] = useState(props.JoystickToken);
  const triggerButtonIndex = props.TriggerButtonIndex;

  const button_dir_forward = 12;
  const button_dir_right = 15;
  const button_dir_left = 14;
  const button_dir_backward = 13;

  const button_cmd_y_start = 0;
  const button_cmd_a_sit = 3;
  const button_cmd_x = 2;
  const button_cmd_b_stop = 1;

  const button_cmd__r1 = 5;
  const button_cmd__r2 = 7;
  const button_cmd__l1 = 4;
  const button_cmd__l2 = 6;

  const button_cmd_back = 8;
  const button_cmd_start = 9;

  const button_cmd_logo = 16;

  const axes_dir_hor = 0;
  const axes_dir_ver = 1;
  const axes_ctr_hor = 2;
  const axes_ctr_ver = 3;

  useEffect(() => {
    setJoystickToken(props.JoystickToken);
  }, [props.JoystickToken]);

  const getJoyStickCommand = (joyMsg) => {
    const axes = joyMsg.axes;
    const buttons = joyMsg.buttons;

    const isActivate = Math.abs(buttons[button_cmd__l1]) >= 0.1;

    // if (!isActivate) {
    //   return null;
    // }

    // if (buttons[button_dir_forward] == 1) {
    //   return createCommand_Move("forward");
    // }
    // if (buttons[button_dir_backward] == 1) {
    //   return createCommand_Move("backward");
    // }

    // if (buttons[button_dir_left] == 1) {
    //   return createCommand_Move("left");
    // }

    // if (buttons[button_dir_right] == 1) {
    //   return createCommand_Move("right");
    // }

    if (buttons[button_cmd_a_sit] == 1) {
      if (joystickToken != null) {
        setJoystickToken(null);
        return createCommand_joystick_finish(joystickToken);
      }
    }

    if (buttons[button_cmd_y_start] == 1) {
      if (joystickToken == null) {
        return createCommand_joystick_start();
      }
    }

    // let joystickTriggerButtonPressed = true;
    // if (triggerButtonIndex !== "undefined" && triggerButtonIndex != null) {
    //   joystickTriggerButtonPressed = buttons[triggerButtonIndex] > 0.9;
    // }

    // const is_axes_activated = axes.some((axis) => Math.abs(axis) >= 0.05);
    // if (
    //   is_axes_activated &&
    //   joystickToken != null &&
    //   joystickTriggerButtonPressed
    // ) {
    //   const axis_x = getMapValue(axes[0], -1, 1, -32767, 32767);
    //   const axis_y = getMapValue(axes[1], -1, 1, -32767, 32767);
    //   const cmd = createCommand_joystick_move(
    //     joystickToken,
    //     Math.round(axis_x),
    //     Math.round(axis_y),
    //     1
    //   );
    //   return cmd;
    // }
    return null;
  };

  const getMapValue = (x, in_min, in_max, out_min, out_max) => {
    return ((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  };

  const handleOnJoystickCommandCreated = (joyMsg, quite = false) => {
    // console.log("joyMsg: ", joyMsg);
    const cmd = getJoyStickCommand(joyMsg);
    if (cmd != null) {
      console.log("joyStickCommand created: ", cmd);
      onCommandCreated(cmd, true);
    }
  };

  const handleOnJoystickMove = (joyMsg) => {
    console.log("JoystickMoving: ", joyMsg);
    const axes = joyMsg.axes;
    const buttons = joyMsg.buttons;

    let joystickTriggerButtonPressed = true;
    if (triggerButtonIndex !== "undefined" && triggerButtonIndex != null) {
      joystickTriggerButtonPressed = buttons[triggerButtonIndex] > 0.9;
    }

    const is_axes_activated = axes.some((axis) => Math.abs(axis) >= 0.05);
    if (
      is_axes_activated &&
      joystickToken != null &&
      joystickTriggerButtonPressed
    ) {
      const axis_x = getMapValue(axes[0], -1, 1, -32767, 32767);
      const axis_y = getMapValue(axes[1], -1, 1, -32767, 32767);
      const cmd = createCommand_joystick_move(
        joystickToken,
        Math.round(axis_x),
        Math.round(axis_y),
        1
      );
      onCommandCreated(cmd, true);
    }
  };

  const handleOnJoystickMoveStart = (joyMsg) => {
    console.log("JoystickMoveStart: ", joyMsg);
    if (joystickToken != null) {
      const cmd = createCommand_joystick_move_start(joystickToken);
      onCommandCreated(cmd, true);
    }
  };

  const handleOnJoystickMoveEnd = (joyMsg) => {
    console.log("JoystickMoveEnd: ", joyMsg);
    if (joystickToken != null) {
      const cmd = createCommand_joystick_move_finish(joystickToken);
      onCommandCreated(cmd, true);
    }
  };

  return (
    <Fragment>
      <GamepadController
        // disabled={rtcConnectionState != "connected"}
        onGamepadStateUpdated={(joyMsg) => {
          handleOnJoystickCommandCreated(joyMsg, true);
        }}
        onGamepadJoystickMove={(joyMsg) => {
          handleOnJoystickMove(joyMsg);
        }}
        onGamepadJoystickMoveStart={(joyMsg) => {
          handleOnJoystickMoveStart(joyMsg);
        }}
        onGamepadJoystickMoveEnd={(joyMsg) => {
          handleOnJoystickMoveEnd(joyMsg);
        }}
        // debug={true}
        showVirtualJoystick={true}
      ></GamepadController>
    </Fragment>
  );
};

export default GoCartJoystickCommand;
