import "../../css/controls.css";
import "./css/gocart-controller.css";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PATH_TO_WARNING_AUDIO } from "../../common/setting";
import { useRef } from "react";
import GoCartMapViewer from "../../components/go-cart/gocart-map-viewer";
import {
  Checkbox,
  Col,
  Layout,
  message,
  Modal,
  Row,
  Space,
  Switch,
} from "antd";

import {
  UnlockOutlined,
  LockOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import GoCartCommandBuilderContainer2 from "../../components/go-cart/ui-commands/gocart-control-panel";
import RobotStatusViewer from "../../components/status/robot-status";
import { useServerConnection } from "../../contexts/ServerConnectionContext";
import { useRobotPeerConnection } from "../../contexts/RobotConnectionContext";
import VideoTrackViewerNew from "../../components/video-viewer/VideoTrackViewerNew";

const GOCART_ICON_PATH = "/images/gocart.png";
const GoCartTeleControlView = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const { id } = useParams();

  const queryParams = new URLSearchParams(window.location.search);
  const qstrUseJoystick = queryParams.get("UseJoystick");
  const useJoystick =
    qstrUseJoystick != null && qstrUseJoystick.toLowerCase() === "true";

  const [gocartJoystickToken, setJoystickToken] = useState(null);

  // const [signal_ready, signal_message, signal_send, signal_Error] =
  //   useContext(WebsocketContext);
  const {
    isOpen: signal_ready,
    isConnecting,
    message: signal_message,
    sendMessage: signal_send,
    subscribe,
    error: signal_Error,
    reconnectCountDown,
  } = useServerConnection();

  const [mapId, setMapId] = useState(null);

  const [robotErrorInfo, setRobotErrorInfo] = useState(null);

  const localStreamRef = useRef(null);

  const [isRobotControlEnabled, setRobotControlEnabled] = useState(false);

  const audioAlarm = useRef(null);

  // const webRtcConfig = {
  //   robotId: id,
  //   dataChannels: ["chat"],
  //   signal_ready: signal_ready,
  //   signal_message: signal_message,
  //   signal_send: signal_send,
  //   LocalStream: getLocalVideoStream,
  // };

  // const [
  //   rtcConnectionState,
  //   mediaTracks,
  //   channelMessage,
  //   channelSend,
  //   error,
  //   robotOnlineStatus,
  // ] = useWebRTC(webRtcConfig);

  const {
    setRobotId,
    connectionState: rtcConnectionState,
    mediaTracks,
    channelMessage,
    channelSend,
    connect,
    // close: closePeerConnection,
    error,
    robotOnlineStatus,
  } = useRobotPeerConnection();

  const [robotStatus, setRobotStatus] = useState(null);
  const [missionStatus, setMissionStatus] = useState(null);

  const handleMoveCommand = (cmd) => {
    console.log("move command: ", cmd);
    if (channelSend == null) {
      alert("data channel is null!");
      return;
    }

    let move_cmd = JSON.stringify({
      cmd: "command",
      data: { cmd_type: "move", params: { direction: cmd, speed: 2 } },
    });

    const channelLabel = "chat";

    channelSend(channelLabel, move_cmd);
    console.log("command has been sent:", move_cmd);
  };

  const handleOnCommandCreated = (command, quite = false) => {
    console.log("Created command: ", command);
    if (channelSend == null) {
      alert("data channel is null!");
      return;
    }

    let cmd_message = JSON.stringify(command);

    const channelLabel = "chat";

    channelSend(channelLabel, cmd_message);
    if (!quite) {
      messageApi.open({
        type: "info",
        content: "Command has been sent to robot!",
        style: {
          marginTop: "10vh",
        },
      });
    }
    console.log("command has been sent:", cmd_message);
  };

  // useEffect(() => {
  //   if (rtcConnectionState == "connected") setRobotControlEnabled(true);
  //   else setRobotControlEnabled(false);
  // }, [rtcConnectionState]);

  useEffect(() => {
    setRobotId(id);
  }, [id]);

  useEffect(() => {
    if (!channelMessage) return;
    // log_debug("received message from robot: ", channelMessage);
    if (channelMessage.channel == "chat") {
      const data = JSON.parse(channelMessage.data);
      const msg_type = data["msg_type"];

      if (msg_type == "robot_state") {
        const msg_body = data["msg_body"];
        let robotInfo = msg_body["common_info"];
        const errorInfo = msg_body["error_info"];
        const missionInfo = msg_body["mission_info"];
        robotInfo["icon"] = GOCART_ICON_PATH;
        robotInfo["error_info"] = errorInfo;
        const connectionInfo = msg_body["connection_info"];
        if (connectionInfo != null) {
          robotInfo["connection_info"] = msg_body["connection_info"];
        }
        // console.log("robotInfo: ", robotInfo);
        // TODO: check map
        const map_id = robotInfo["map"]?.["id"];
        if (map_id != mapId) {
          console.log("Change map: ", mapId, map_id);
          console.log("Map: ", robotInfo["map"]);
          setMapId(map_id);
        }
        setRobotErrorInfo(errorInfo);
        setRobotStatus(robotInfo);
        setMissionStatus(missionInfo);
      } else if (msg_type == "robot_message_raw") {
        let robotInfo = null;
        let errorInfo = null;
        let mapInfo = null;

        const msg_body = data["msg_body"];
        const robot_msg_body = msg_body["msg_body"];

        if ("worker_info" in robot_msg_body) {
          const worker_info = robot_msg_body["worker_info"];
          // console.log("worker_info: ", worker_info);

          const map_id = worker_info["map"]["id"];
          if (map_id != mapId) setMapId(map_id);

          let pathPlan = null;
          if ("path_plan" in worker_info) {
            pathPlan = worker_info["path_plan"];
            // console.log("Path plan: ", pathPlan);
          }

          robotInfo = {
            uuid: worker_info["uuid"],
            name: worker_info["name"],
            mode: worker_info["service_mode"],
            status: worker_info["status"],
            type: worker_info["robot_type"],
            width: worker_info["robot_width"],
            length: worker_info["robot_length"],
            state: worker_info["robot_state"],
            pose: worker_info["pose"],
            battery: {
              battery_level: worker_info["battery"]?.["battery_level"],
              now_charging: worker_info["battery"]?.["now_charging"],
              charge_source: worker_info["battery"]?.["charge_source"],
            },
            velo: {
              dx: worker_info["velo"]?.["velo_dx"],
              dy: worker_info["velo"]?.["velo_dy"],
              dz: worker_info["velo"]?.["velo_dz"],
            },
            in_precision_parking: worker_info["in_precision_parking"],
            brake_released: worker_info["brake_released"],
            paused: worker_info["paused"],
            emergency_alarm: worker_info["emergency_alarm"],
            manual_mode: worker_info["manual_mode"],
            emergency_button_pressed: worker_info["emergency_button_pressed"],
            map: worker_info["map"],
            icon: GOCART_ICON_PATH,
            path_plan: pathPlan,
          };
        }

        if (robotInfo != null) {
          robotInfo["icon"] = GOCART_ICON_PATH;
          robotInfo["error_info"] = errorInfo;
        }
        // console.log("robotInfo: ", robotInfo);
        setRobotErrorInfo(errorInfo);
        setRobotStatus(robotInfo);
      } else if (msg_type == "result") {
        console.log("Result: ", data);
        gocart_message_parser(data);
      } else {
        console.log("unknown message format!! data: ", data);
      }
    }
  }, [channelMessage]);

  const gocart_message_parser = (message) => {
    const msg_body = message["msg_body"];
    if (msg_body["name"] == "virtual_joystick_start") {
      if (msg_body["result"] == "success") {
        const token = msg_body?.data_contents?.virtual_joystick_token;
        setJoystickToken(token);
        // robot_controller.send_json(gocart_command.joystick_health_check(self.gocart_js_token))
        // gocart_teleop_setup()
      }
    } else if (msg_body["name"] == "virtual_joystick_finish") {
      if (msg_body?.result == "success") {
        setJoystickToken(null);
      }
    }
  };

  useEffect(() => {
    if (!robotErrorInfo) playAlarm(false);
    else playAlarm(true);

    // console.log("Error Info: ", robotErrorInfo);
  }, [robotErrorInfo]);

  const map_OnRobotCommandCreated = (robot, cmd) => {
    console.log("map_OnRobotCommandCreated()");
    console.log("Send command to robot: ", robot, cmd);

    console.log("Command to robot", cmd);
    if (channelSend == null) {
      alert("data channel is null!");
      return;
    }

    let cmd_message = JSON.stringify(cmd);

    const channelLabel = "chat";

    channelSend(channelLabel, cmd_message);
    console.log("command has been sent:", cmd_message);
  };

  const playAlarm = (play) => {
    const audio = audioAlarm.current;
    if (play) audio.play();
    else audio.pause();
  };

  const [leftColumnWidth, setLeftColumnWidth] = useState(12);
  const [isResizing, setIsResizing] = useState(false);
  const [mouseX, setMouseX] = useState(null);

  const handleMouseDown = (event) => {
    setIsResizing(true);
    setMouseX(event.clientX);
    console.log("Left column width: ", leftColumnWidth);
  };

  const handleMouseMove = (event) => {
    if (!isResizing) return;

    const deltaX = event.clientX - mouseX;

    // console.log("containerWidth: ", containerWidth);
    console.log("deltaX: ", deltaX);

    const newLeftColumnWidth = leftColumnWidth + deltaX / 100;

    // const containerWidth = event.currentTarget.offsetWidth;
    // const mouseX = event.pageX;

    // console.log("containerWidth: ", containerWidth);
    // console.log("mouseX: ", mouseX);

    // const newLeftColumnWidth = (mouseX / containerWidth) * 100;
    console.log(
      "change leftColumn: ",
      leftColumnWidth,
      " => ",
      newLeftColumnWidth
    );
    setLeftColumnWidth(newLeftColumnWidth);
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const robotInfo = {
    robot_id: id,
    icon: robotStatus?.icon || GOCART_ICON_PATH,
    name: id,
  };
  const connectionsStatus = {
    ServerConnectionStatus: signal_ready ? "connected" : "disconnected",
    PeerConnectionStatus: rtcConnectionState,
    RobotOnlineStatus: robotOnlineStatus,
  };

  return (
    <Layout>
      <Content>
        <div>
          {contextHolder}
          <Row>
            <Col span={24}>
              <RobotStatusViewer
                RobotStatus={robotStatus}
                RobotInfo={robotInfo}
                ConnectionStatus={connectionsStatus}
                MissionInfo={missionStatus}
              ></RobotStatusViewer>
            </Col>
          </Row>
          <Row>
            <Col span={22}>
              {/* <h1>
                Go-Cart: {id} -- {robotOnlineStatus ? "(Online)" : "(Offline)"}
              </h1> */}

              <Space>
                {/* <Checkbox>Show command panel </Checkbox> */}
                Show control:
                <Switch
                  onChange={(checked) => {
                    setRobotControlEnabled(checked);
                  }}
                  // checkedChildren={<UnlockOutlined />}
                  unCheckedChildren={<LockOutlined />}
                  checkedChildren={<CheckOutlined />}
                  // unCheckedChildren={<CloseOutlined />}
                  defaultChecked
                  checked={isRobotControlEnabled}
                />
              </Space>
              <audio
                ref={audioAlarm}
                id="alarm-sound"
                src={PATH_TO_WARNING_AUDIO}
                loop
              ></audio>
            </Col>
          </Row>
          {/* <RobotConnectionStatusUI
            RobotId={id}
            ServerConnectionStatus={signal_ready ? "connected" : "disconnected"}
            PeerConnectionStatus={rtcConnectionState}
            RobotOnlineStatus={robotOnlineStatus}
            ShowAlert={true}
          ></RobotConnectionStatusUI>
          <GoCartRobotStatus RobotStatus={robotStatus}></GoCartRobotStatus>
          {!missionStatus && <h4>No mission</h4>}
          {missionStatus && (
            <h4>
              Mission: {missionStatus.MissionId}. Status: {missionStatus.Status}
              . Progress: {missionStatus.CurrentJobIndex}/
              {missionStatus.Jobs.length}
            </h4>
          )} */}
          <Row
          // gutter={16}
          // className="two-column-layout"
          >
            <Col xs={23} sm={23} md={11} lg={11} xl={11} span={11}>
              <div
                id="media"
                className={robotErrorInfo ? "blinking-div" : ""}
                style={{ maxWidth: "1800px" }}
              >
                <VideoTrackViewerNew
                  mediaTracks={mediaTracks}
                  ShowDelayTime={true}
                  CameraStyle={{ height: "100%", maxHeight: "65vh" }}
                ></VideoTrackViewerNew>
              </div>
            </Col>

            <Col
              // xs={23} sm={23} md={11} lg={11} xl={11}
              flex="auto"
              span={11}
              offset={1}
            >
              <div id="div_map">
                <GoCartMapViewer
                  robotStatus={robotStatus}
                  MapId={mapId}
                  onRobotCommandCreated={map_OnRobotCommandCreated}
                ></GoCartMapViewer>
              </div>
            </Col>
            {/* <div
              className="resize-handle"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
            /> */}
          </Row>
        </div>
      </Content>
      <Sider
        width={350}
        theme="light"
        // trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        // zeroWidthTriggerStyle={zeroWidthTriggerStyle}
        collapsible
        collapsed={!isRobotControlEnabled}
        // onCollapse={(value) => setCollapsed(value)}
        breakpoint="md"
        collapsedWidth="0"
      >
        <GoCartCommandBuilderContainer2
          JoystickEnabled={useJoystick}
          JoystickToken={gocartJoystickToken}
          OnCommandCreated={handleOnCommandCreated}
        ></GoCartCommandBuilderContainer2>
      </Sider>
    </Layout>
  );
};

export default GoCartTeleControlView;
