import React, { Component, useContext, useEffect } from "react";
import useFetchState from "../utils/useFetchState";
import { getFetchURL } from "../common/setting";
import { Space, Table, Tag } from "antd";

const SpotLogViewer = () => {
  // const json_server_url =
  const {
    data: log_records,
    error,
    isPending,
    setData,
  } = useFetchState(getFetchURL("/logs-spot"));

  const get_columns = () => {
    if (log_records == null || log_records.length == 0) return [];
    const keys = Object.keys(log_records[0]);
    let columns = [];

    keys.forEach((column_name) => {
      columns.push({
        title: column_name,
        dataIndex: column_name,
        key: column_name,
        render: (text) => text,
      });
    });
    return columns;
  };

  //   const columns = [
  //     {
  //       title: "Name",
  //       dataIndex: "name",
  //       key: "name",
  //       render: (text) => <a>{text}</a>,
  //     },
  //     {
  //       title: "Age",
  //       dataIndex: "age",
  //       key: "age",
  //     },
  //     {
  //       title: "Address",
  //       dataIndex: "address",
  //       key: "address",
  //     },
  //   ];

  return (
    <div>
      <h2>Spot Robot Log</h2>
      {error && <div> {error}</div>}
      {isPending && <div>Loading ...</div>}

      {log_records && (
        <div className="table-responsive">
          <Table columns={get_columns()} dataSource={log_records} />
        </div>
      )}
      <div className="table-responsive"></div>
    </div>
  );
};

export default SpotLogViewer;
