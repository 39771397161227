import axios from "axios";
import { getFetchURL } from "../common/setting";

// export const deleteMissionService = async (missionId) => {
//   const url = getFetchURL("/robot-missions/" + missionId);
//   return await axios.post(url, { mission_ids: [missionId] });
// };

export const deleteMissionService = async (missionId) => {
  const url = getFetchURL("/missions-delete");
  return await axios.post(url, { mission_ids: [missionId] });
};

export const update_mission_data_to_server = async (mission) => {
  const url = getFetchURL("/mission-logs-update/" + mission.mission_id);

  const response = await fetch(url, {
    body: JSON.stringify(mission),
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
  });
  if (!response.ok) throw Error("Failed to update mission");
  return await response.json();
};

export const add_new_mission_log_data = async (mission) => {
  const url = getFetchURL("/mission-logs-add");

  const response = await fetch(url, {
    body: JSON.stringify(mission),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  if (!response.ok) throw Error("Failed to add mission");
  return await response.json();
};

export const getUserRobotMissionListService = async (
  token,
  pageSize = null,
  pageIndex = null
) => {
  const params = { pageSize: pageSize, pageIndex: pageIndex };
  const url = getFetchURL("/api/user/robot-missions");
  return await axios.get(url, {
    headers: { authorization: token },
    params: params,
  });
};

export const validateMissionDescription = (missionDesc) => {
  if (missionDesc == null) return false;
  if (
    missionDesc?.mission_type == null ||
    missionDesc?.mission_name == null ||
    missionDesc?.mission_jobs == null ||
    missionDesc?.description == null
  )
    return false;

  if (missionDesc.mission_jobs.length == 0) return false;
  for (let i = 0; i < missionDesc.mission_jobs.length; i++) {
    const job = missionDesc.mission_jobs[i];
    if (job == null) return false;
    if (job.job_description == null) return false;
  }
  return true;
};
