export const createCommand_moveJoyStick = (x, y, theta) => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        axis: [x, y, theta],
        speed: "",
      },
    },
  };
};

export const createCommand_joint_joystick = (yaw, roll, pitch, height) => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        joint: [yaw, roll, pitch, height],
        speed: "",
      },
    },
  };
};

export const createCommand_Stand = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "stand",
        speed: "",
      },
    },
  };
};

export const createCommand_Sit = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "sit",
        speed: "",
      },
    },
  };
};

export const createCommand_WalkForward = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "forward",
        speed: "",
      },
    },
  };
};

export const createCommand_WalkBackward = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "backward",
        speed: "",
      },
    },
  };
};

export const createCommand_StrafeLeft = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "sleft",
        speed: "",
      },
    },
  };
};

export const createCommand_StrafeRight = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "sright",
        speed: "",
      },
    },
  };
};

export const createCommand_Stop = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "stop",
        speed: "",
      },
    },
  };
};

export const createCommand_TurnRight = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "right",
        speed: "",
      },
    },
  };
};

export const createCommand_TurnLeft = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "left",
        speed: "",
      },
    },
  };
};

export const createCommand_ForwardLeft = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "forwardleft",
        speed: "",
      },
    },
  };
};

export const createCommand_ForwardRight = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "forwardright",
        speed: "",
      },
    },
  };
};

export const createCommand_BackwardLeft = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "backwardleft",
        speed: "",
      },
    },
  };
};

export const createCommand_BackwardRight = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: "backwardright",
        speed: "",
      },
    },
  };
};

export const createCommand_Connect = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "connect",
      params: {
        direction: "",
        speed: "",
      },
    },
  };
};

export const createCommand_PowerOn = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "power_on",
      params: {
        direction: "",
        speed: "",
      },
    },
  };
};

export const createCommand_EStop = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "e-stop",
      params: {
        direction: "",
        speed: "",
      },
    },
  };
};

export const createCommand_Walk = () => {
  return {
    cmd: "command",
    data: {
      cmd_type: "walk",
      params: {
        direction: "",
        speed: "",
      },
    },
  };
};

export const createCommand_MissionStart = () => {
  return {
    cmd: "mission",
    data: {
      cmd_type: "start",
      params: {
        direction: "auto",
        speed: "",
      },
    },
  };
};

export const createCommand_MissionPause = () => {
  return {
    cmd: "mission",
    data: {
      cmd_type: "pause",
      params: {
        direction: "manual",
        speed: "",
      },
    },
  };
};

export const createCommand_MissionRestart = () => {
  return {
    cmd: "mission",
    data: {
      cmd_type: "restart",
      params: {
        direction: "auto",
        speed: "",
      },
    },
  };
};

export const createCommand_MissionAsign = (missionName) => {
  return {
    cmd: "mission",
    data: {
      cmd_type: "assign",
      params: {
        name: missionName,
      },
    },
  };
};

export const createCommand_MissionCancel = () => {
  return {
    cmd: "mission",
    data: {
      cmd_type: "cancel",
      params: {
        name: null,
      },
    },
  };
};

export const createCommand_MissionFinish = () => {
  return {
    cmd: "mission",
    data: {
      cmd_type: "finish",
      params: {
        name: null,
      },
    },
  };
};

export const createCommand_PlayAudio = (audio_file = null, audio_id = null) => {
  return {
    cmd: "command",
    data: {
      cmd_type: "play_audio",
      params: {
        audio_file: audio_file,
        audio_id: audio_id,
      },
    },
  };
};
