import { Col, Divider, Row, Select, Space } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { Joystick } from "react-joystick-component";
import "./JoystickController.css";

const VIRTUAL_GAMEPAD_ID = "Virtual Joystick";

const GamepadController = (props) => {
  const [gamepadState, setGamepadState] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const querryJoystickShowDebug = queryParams.get("JoystickShowDebug");
  const MIN_AXES_VALUE = 0.05;

  const showDebug =
    props.debug ||
    (querryJoystickShowDebug != null &&
      querryJoystickShowDebug.toLowerCase() === "true");
  const showVirtualJoystick = props.showVirtualJoystick;
  const [gamepadID, setGamepadId] = useState(props.gamepadId);

  const onGamepadStateUpdated = props.onGamepadStateUpdated || ((evt) => {});
  const onGamepadJoystickMoveStart =
    props.onGamepadJoystickMoveStart || ((evt) => {});
  const onGamepadJoystickMoveEnd =
    props.onGamepadJoystickMoveEnd || ((evt) => {});
  const onGamepadJoystickMove = props.onGamepadJoystickMove || ((evt) => {});

  const [availableGamePads, setAvailableGamePads] = useState([
    VIRTUAL_GAMEPAD_ID,
  ]);

  const [isGamepadAvailable, setGamepadAvailable] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const [virtualGamepadState, setVirtualGamepadState] = useState({
    axes: [0, 0, 0, 0],
    buttons: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });

  const isUsingVirtualGamePad = () => {
    return gamepadID == null || gamepadID == VIRTUAL_GAMEPAD_ID;
  };

  const button_cmd_a = 0;
  const button_cmd_b = 1;
  const button_cmd_x = 2;
  const button_cmd_y = 3;

  const button_dir_forward = 12;
  const button_dir_backward = 13;
  const button_dir_left = 14;
  const button_dir_right = 15;

  const button_cmd__r1 = 5;
  const button_cmd__r2 = 7;
  const button_cmd__l1 = 4;
  const button_cmd__l2 = 6;

  useEffect(() => {
    if (!props.disabled && isReady) {
      onGamepadStateUpdated(virtualGamepadState);
    }
  }, [virtualGamepadState]);

  useEffect(() => {
    const handleGamepadConnected = (event) => {
      console.log("Gamepad connected:", event.gamepad);
      const gamepad = event.gamepad;
      setAvailableGamePads((prevState) => {
        return [...prevState, gamepad.id];
      });
    };

    const handleGamepadDisconnected = (event) => {
      console.log("Gamepad disconnected: ", event.gamepad);
      const gamepad = event.gamepad;
      setAvailableGamePads((prevState) => {
        const newState = prevState.filter((item) => item !== gamepad.id);
        return newState;
      });
    };

    window.addEventListener("gamepadconnected", handleGamepadConnected);
    window.addEventListener("gamepaddisconnected", handleGamepadDisconnected);

    return () => {
      window.removeEventListener("gamepadconnected", handleGamepadConnected);
      window.removeEventListener(
        "gamepaddisconnected",
        handleGamepadDisconnected
      );
    };
  }, []);

  useEffect(() => {
    let animationFrameId = null;
    let isActivate = false;
    let isJoystickStarted = false;

    const updateGamepadState = () => {
      const gamepads = navigator.getGamepads();

      if (gamepads == null) {
        return;
      }
      let selectedGamepad = gamepads.findIndex(
        (item) => item && item.id == gamepadID
      );

      if (gamepads && gamepads[selectedGamepad]) {
        setGamepadAvailable(true);
        const currentGamepadState = gamepads[selectedGamepad];
        const joyValue = {
          axes: currentGamepadState.axes.map(
            (val) => Math.round((val + Number.EPSILON) * 100) / 100
          ),
          buttons: currentGamepadState.buttons.map((button) => button.value),
        };

        const isCurrentJoystickStarted = currentGamepadState.axes.some(
          (axis) => Math.abs(axis) >= MIN_AXES_VALUE
        );

        const isCurrentlyActive =
          isCurrentJoystickStarted ||
          currentGamepadState.buttons.some((button) => button.pressed);

        if (!isJoystickStarted && isCurrentJoystickStarted) {
          onGamepadJoystickMoveStart(joyValue);
        }

        if (isActivate || isCurrentlyActive) {
          setIsReady(true); //TODO: check trigger key pressed
          setGamepadState(joyValue);
          setVirtualGamepadState(joyValue);
          if (isJoystickStarted || isCurrentJoystickStarted) {
            onGamepadJoystickMove(joyValue);
          }
        }

        if (isJoystickStarted && !isCurrentJoystickStarted) {
          onGamepadJoystickMoveEnd(joyValue);
        }

        isActivate = isCurrentlyActive;
        isJoystickStarted = isCurrentJoystickStarted;
      } else {
        setGamepadAvailable(false);
      }
    };

    let previousCall = 0;

    const gameLoop = () => {
      const currentTime = new Date().getTime();
      const waitingTime = currentTime - previousCall;
      if (waitingTime >= props.throttle) {
        previousCall = currentTime;
        updateGamepadState();
      }
      animationFrameId = requestAnimationFrame(gameLoop);
    };

    if (isUsingVirtualGamePad()) {
      setIsReady(true);
    } else {
      setIsReady(false);
      // Start the game loop
      gameLoop();
    }

    return () => {
      if (animationFrameId != null) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [gamepadID]);

  const handleJoystickMove = (stick, joystickIndex) => {
    setVirtualGamepadState((prev) => {
      const axesNew = [...prev.axes];
      axesNew[joystickIndex * 2] =
        Math.round((stick.x + Number.EPSILON) * 100) / 100;
      axesNew[joystickIndex * 2 + 1] =
        -Math.round((stick.y + Number.EPSILON) * 100) / 100;
      return { ...prev, axes: axesNew };
    });

    const axesNew = [...virtualGamepadState.axes];
    axesNew[joystickIndex * 2] =
      Math.round((stick.x + Number.EPSILON) * 100) / 100;
    axesNew[joystickIndex * 2 + 1] =
      -Math.round((stick.y + Number.EPSILON) * 100) / 100;
    const gamepadValue = { ...virtualGamepadState, axes: axesNew };

    onGamepadJoystickMove(gamepadValue);
  };

  // const handleLeftJoystickMove = (stick) => {
  //   setVirtualGamepadState((prev) => {
  //     const axesNew = [...prev.axes];
  //     axesNew[0] = Math.round((stick.x + Number.EPSILON) * 100) / 100;
  //     axesNew[1] = -Math.round((stick.y + Number.EPSILON) * 100) / 100;
  //     return { ...prev, axes: axesNew };
  //   });
  // };

  // const handleRightJoystickMove = (stick) => {
  //   setVirtualGamepadState((prev) => {
  //     const axesNew = [...prev.axes];
  //     axesNew[2] = Math.round((stick.x + Number.EPSILON) * 100) / 100;
  //     axesNew[3] = -Math.round((stick.y + Number.EPSILON) * 100) / 100;

  //     return { ...prev, axes: axesNew };
  //   });
  // };

  const handleJoystickStop = (stick, joystickIndex) => {
    setVirtualGamepadState((prev) => {
      const axesNew = [...prev.axes];
      axesNew[joystickIndex * 2] = 0;
      axesNew[joystickIndex * 2 + 1] = 0;
      return { ...prev, axes: axesNew };
    });

    onGamepadJoystickMoveEnd(virtualGamepadState);
  };

  const handleJoystickStart = (stick, joystickIndex) => {
    // const axesNew = [...virtualGamepadState.axes];
    // axesNew[joystickIndex * 2] =
    //   Math.round((stick.x + Number.EPSILON) * 100) / 100;
    // axesNew[joystickIndex * 2 + 1] =
    //   -Math.round((stick.y + Number.EPSILON) * 100) / 100;
    // const gamepadStateValue = { ...virtualGamepadState, axes: axesNew };

    onGamepadJoystickMoveStart(virtualGamepadState);
  };

  const handleButtonPress = (evt, buttonIndex) => {
    console.log("Button ", buttonIndex, " pressed!");

    const handleRelease = (e) => {
      console.log("Button ", buttonIndex, " released!");
      setVirtualGamepadState((prev) => {
        const buttonNew = [...prev.buttons];
        buttonNew[buttonIndex] = 0;
        return { ...prev, buttons: buttonNew };
      });

      evt.target.removeEventListener("mouseup", handleRelease);
      evt.target.removeEventListener("touchend", handleRelease);
      evt.target.removeEventListener("mouseleave", handleRelease);
    };

    evt.target.addEventListener("mouseup", handleRelease);
    evt.target.addEventListener("touchend", handleRelease);
    evt.target.addEventListener("mouseleave", handleRelease);

    setVirtualGamepadState((prev) => {
      const buttonNew = [...prev.buttons];
      buttonNew[buttonIndex] = 1;
      return { ...prev, buttons: buttonNew };
    });
  };

  const availableGamePadSelectItems = availableGamePads.map((item) => {
    return { value: item, label: item };
  });

  const handleChange = (value) => {
    console.log(`selected value ${value}`);
    // const index = availableGamePadSelectItems.findIndex(
    //   (item) => item.value == value
    // );
    // console.log(`selected index ${index}`);
    setGamepadId(value);
  };
  return (
    <div>
      {showVirtualJoystick && (
        <Row gutter={[16, 32]}>
          <Col>
            Select Joystick:{" "}
            <Select
              defaultValue={availableGamePads[0]}
              style={{ width: 200 }}
              onChange={handleChange}
              value={availableGamePads[gamepadID]}
              options={availableGamePadSelectItems}
            />
          </Col>
        </Row>
      )}
      {showVirtualJoystick && (
        <div className="gamepad">
          <button
            className="l2"
            onTouchStart={(evt) => handleButtonPress(evt, button_cmd__l2)}
            onMouseDown={(evt) => handleButtonPress(evt, button_cmd__l2)}
          >
            L2
          </button>
          <button
            className="l1"
            onTouchStart={(evt) => handleButtonPress(evt, button_cmd__l1)}
            // onTouchEnd={() => handleButtonRelease(button_cmd__l1)}
            onMouseDown={(evt) => handleButtonPress(evt, button_cmd__l1)}
            // onMouseUp={() => handleButtonRelease(button_cmd__l1)}
            // onMouseLeave={() => handleButtonRelease(button_cmd__l1)}
          >
            L1
          </button>

          <button
            className="r2"
            onTouchStart={(evt) => handleButtonPress(evt, button_cmd__r2)}
            // onTouchEnd={() => handleButtonRelease(button_cmd__r2)}
            onMouseDown={(evt) => handleButtonPress(evt, button_cmd__r2)}
            // onMouseUp={() => handleButtonRelease(button_cmd__r2)}
            // onMouseLeave={() => handleButtonRelease(button_cmd__r2)}
          >
            R2
          </button>
          <button
            className="r1"
            onTouchStart={(evt) => handleButtonPress(evt, button_cmd__r1)}
            // onTouchEnd={() => handleButtonRelease(button_cmd__r1)}
            onMouseDown={(evt) => handleButtonPress(evt, button_cmd__r1)}
            // onMouseUp={() => handleButtonRelease(button_cmd__r1)}
            // onMouseLeave={() => handleButtonRelease(button_cmd__r1)}
          >
            R1
          </button>
          <div class="dpad">
            <button
              className="up"
              onTouchStart={(evt) => handleButtonPress(evt, button_dir_forward)}
              // onTouchEnd={() => handleButtonRelease(button_dir_forward)}
              onMouseDown={(evt) => handleButtonPress(evt, button_dir_forward)}
              // onMouseUp={() => handleButtonRelease(button_dir_forward)}
              // onMouseLeave={() => handleButtonRelease(button_dir_forward)}
            >
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2 L2 15 L22 15 Z" />
              </svg>
            </button>
            <button
              className="left"
              onTouchStart={(evt) => handleButtonPress(evt, button_dir_left)}
              // onTouchEnd={() => handleButtonRelease(button_dir_left)}
              onMouseDown={(evt) => handleButtonPress(evt, button_dir_left)}
              // onMouseUp={() => handleButtonRelease(button_dir_left)}
              // onMouseLeave={() => handleButtonRelease(button_dir_left)}
            >
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 12 L15 2 L15 22 Z" />
              </svg>
            </button>
            <button
              className="right"
              onTouchStart={(evt) => handleButtonPress(evt, button_dir_right)}
              // onTouchEnd={() => handleButtonRelease(button_dir_right)}
              onMouseDown={(evt) => handleButtonPress(evt, button_dir_right)}
              // onMouseUp={() => handleButtonRelease(button_dir_right)}
              // onMouseLeave={() => handleButtonRelease(button_dir_right)}
            >
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 12 L9 2 L9 22 Z" />
              </svg>
            </button>
            <button
              className="down"
              onTouchStart={(evt) =>
                handleButtonPress(evt, button_dir_backward)
              }
              // onTouchEnd={() => handleButtonRelease(button_dir_backward)}
              onMouseDown={(evt) => handleButtonPress(evt, button_dir_backward)}
              // onMouseUp={() => handleButtonRelease(button_dir_backward)}
              // onMouseLeave={() => handleButtonRelease(button_dir_backward)}
            >
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22 L2 9 L22 9 Z" />
              </svg>
            </button>
          </div>
          <div class="abxy">
            <button
              className="y"
              onTouchStart={(evt) => handleButtonPress(evt, button_cmd_y)}
              // onTouchEnd={() => handleButtonRelease(button_cmd_y)}
              onMouseDown={(evt) => handleButtonPress(evt, button_cmd_y)}
              // onMouseUp={() => handleButtonRelease(button_cmd_y)}
              // onMouseLeave={() => handleButtonRelease(button_cmd_y)}
            >
              Y
            </button>
            <button
              className="x"
              onTouchStart={(evt) => handleButtonPress(evt, button_cmd_x)}
              // onTouchEnd={() => handleButtonRelease(button_cmd_x)}
              onMouseDown={(evt) => handleButtonPress(evt, button_cmd_x)}
              // onMouseUp={() => handleButtonRelease(button_cmd_x)}
              // onMouseLeave={() => handleButtonRelease(button_cmd_x)}
            >
              X
            </button>
            <button
              className="b"
              onTouchStart={(evt) => handleButtonPress(evt, button_cmd_b)}
              // onTouchEnd={() => handleButtonRelease(button_cmd_b)}
              onMouseDown={(evt) => handleButtonPress(evt, button_cmd_b)}
              // onMouseUp={() => handleButtonRelease(button_cmd_b)}
              // onMouseLeave={() => handleButtonRelease(button_cmd_b)}
            >
              B
            </button>
            <button
              className="a"
              onTouchStart={(evt) => handleButtonPress(evt, button_cmd_a)}
              // onTouchEnd={() => handleButtonRelease(button_cmd_a)}
              onMouseDown={(evt) => handleButtonPress(evt, button_cmd_a)}
              // onMouseUp={() => handleButtonRelease(button_cmd_a)}
              // onMouseLeave={() => handleButtonRelease(button_cmd_a)}
            >
              A
            </button>
          </div>
          <div className="joystick left-stick">
            <Joystick
              throttle={props.throttle}
              disabled={props.disabled}
              size={70}
              // baseImage="/images/icons/joystick_left_base.png"
              baseColor="Yellow"
              // stickImage="/images/icons/joystick_left_base.png"
              // stickSize="45"
              start={(stick) => handleJoystickStart(stick, 0)}
              move={(stick) => handleJoystickMove(stick, 0)}
              stop={(stick) => handleJoystickStop(stick, 0)}
              pos={{
                x: virtualGamepadState.axes[0],
                y: -virtualGamepadState.axes[1],
              }}
            ></Joystick>
          </div>
          <div className="joystick right-stick">
            <Joystick
              throttle={props.throttle}
              disabled={props.disabled}
              size={70}
              // baseImage="/images/icons/joystick_direction.png"
              baseColor="gray"
              // stickSize="40"
              start={(stick) => handleJoystickStart(stick, 1)}
              move={(stick) => handleJoystickMove(stick, 1)}
              stop={(stick) => handleJoystickStop(stick, 1)}
              pos={{
                x: virtualGamepadState.axes[2],
                y: -virtualGamepadState.axes[3],
              }}
            ></Joystick>
          </div>
        </div>
      )}
      {showDebug && (
        <div>
          {gamepadState && (
            <div>
              <h4>Controller Index: {gamepadID} </h4>
              {isGamepadAvailable && (
                <div>
                  <p>Buttons: {gamepadState.buttons.join(", ")}</p>
                  <p>Axes: {gamepadState.axes.join(", ")}</p>
                </div>
              )}
              {!isGamepadAvailable && <p>Controller not found</p>}
            </div>
          )}
          {showVirtualJoystick && (
            <div>
              <h4>Virtual controller</h4>
              <p>Buttons: {virtualGamepadState.buttons.join(", ")}</p>
              <p>Virtual Axes: {virtualGamepadState.axes.join(", ")}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

GamepadController.defaultProps = {
  debug: false,
  showVirtualJoystick: true,
  gamepadId: VIRTUAL_GAMEPAD_ID,
  disabled: false,
  throttle: 20,
};

export default GamepadController;
