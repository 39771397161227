import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  MAX_NUMBER_OF_ATTEMP_TO_CHECK_FOR_DISCONNECTED,
  WAITING_TIME_BEFORE_AUTO_RECONNECT,
  WAITING_TIME_TO_CHECK_FOR_DISCONNECTED,
  getRobotsListFetchURL,
} from "../common/setting";
import { useServerConnection } from "./ServerConnectionContext";
import useFetchState from "../utils/useFetchState";
import { PeerStats } from "../webrtc/peer_stats";
import { getDefaultPeerConnectionConfig } from "../common/peer_conn_config";
import { useMediaManager } from "../media_managers/useMediaManager";

export const EVENT_STATS_UPDATED = "stats-updated";
export const EVENT_CHANNEL_MESSAGE_RECEIVED = "channel-message";

const RobotPeerConnectionContext = createContext();

export const RobotPeerConnectionProvider = (props) => {
  const children = props.children;
  const [robot_id, setRobotId] = useState(null);
  const [statsIntervalTime, setStatsIntervalTime] = useState(
    props.StatsInterval
  );
  const disableChannelMessageState = props.disableChannelMessageState;

  const [statsEnabled, setStatsEnabled] = useState(props.StatsEnabled);

  const {
    mediaError,
    mediaAllowed,
    getMediaStream: getLocalMediaStream,
    // muteAudioTrack,
    // unmuteAudio,
    stopMediaStream: closeLocalStream,
  } = useMediaManager({
    audio: props.isSendingAudio,
    video: props.isSendingVideo,
  });

  const [connectionState, setConnectionState] = useState("...");
  const [mediaTracks, setMediaTracks] = useState(null);
  const [error, setError] = useState(null);
  const [iceState, setIceState] = useState(null);
  const [isRemoteDescriptionSet, setIsRemoteDescriptionSet] = useState(false);

  const [isReceiveVideo, setIsReceiveVideo] = useState(props.isReceiveVideo);
  const [isReceiveAudio, setIsReceiveAudio] = useState(props.isReceiveAudio);
  const [isSendingAudio, setIsSendingAudio] = useState(props.isSendingAudio);
  const [isSendingVideo, setIsSendingVideo] = useState(props.isSendingVideo);
  const [dataChannelsConfig, setDataChannelsConfig] = useState(
    props.dataChannelsConfig
  );

  const [allowLocalAudio, setAllowLocalAudio] = useState(props.allowLocalAudio);
  const [allowLocalVideo, setAllowLocalVideo] = useState(props.allowLocalVideo);
  const [allowRemoteAudio, setAllowRemoteAudio] = useState(
    props.allowRemoteAudio
  );
  const [allowRemoteVideo, setAllowRemoteVideo] = useState(
    props.allowRemoteVideo
  );

  const trickleICE = props.trickleICE;

  const [channelMessage, setChannelMessage] = useState(null);

  const peerConnection = useRef(null);
  const peerDataChannel = useRef(null);
  const mediaTracksRef = useRef(null);
  const [robotOnlineStatus, setRobotOnlineStatus] = useState(null);

  const remotePeerConnectionStats = useRef(null);

  const refEventListenners = useRef({});
  const eventListenners = refEventListenners.current;

  /**
   * Add event listener to be notified. This is generally for plugins
   * @param {*} listener
   */
  const addWebRTCEventListener = (eventName, listener) => {
    let listenners;
    if (!eventListenners.hasOwnProperty(eventName)) {
      listenners = [];
      eventListenners[eventName] = listenners;
    } else {
      listenners = eventListenners[eventName];
    }
    listenners.push(listener);
    console.log(
      "addWebRTCEventListener event: ",
      eventName,
      " count: ",
      listenners.length
    );
    return () => {
      removeWebRTCEventListener(eventName, listener);
    };
  };

  const removeWebRTCEventListener = (eventName, listener) => {
    if (eventListenners.hasOwnProperty(eventName)) {
      const listenners = eventListenners[eventName];
      let index = listenners.findIndex((element) => element == listener);
      if (index != -1) {
        listenners.splice(index, 1);
      }
      if (eventListenners[eventName].length == 0) {
        delete eventListenners[eventName];
      }
      console.log(
        "removeWebRTCEventListener event: ",
        eventName,
        " count: ",
        listenners.length
      );
    }
  };

  const countEventListeners = (eventName) => {
    if (!eventListenners.hasOwnProperty(eventName)) return 0;

    return eventListenners[eventName].length;
  };

  const notifyEventListeners = (eventName, eventObj) => {
    if (eventName in eventListenners) {
      const listenters = eventListenners[eventName];
      listenters.forEach((listener) => {
        listener(eventObj);
      });
    }
  };

  const addStatsUpdatedEventListener = (listener) => {
    addWebRTCEventListener(EVENT_STATS_UPDATED, listener);
  };

  const removeStatsUpdatedEventListener = (listener) => {
    removeWebRTCEventListener(EVENT_STATS_UPDATED, listener);
  };

  const addChannelMessageReceivedEventListener = (listener) => {
    return addWebRTCEventListener(EVENT_CHANNEL_MESSAGE_RECEIVED, listener);
  };

  const removeChannelMessageReceivedEventListener = (listener) => {
    removeWebRTCEventListener(EVENT_CHANNEL_MESSAGE_RECEIVED, listener);
  };

  // const [connSetting, setConnSetting] = useState({
  //   dataChannels: ["chat"],
  //   receiveVideo: isReceiveVideo,
  //   receiveAudio: isReceiveAudio,
  //   sendVideo: isSendingVideo,
  //   sendAudio: isSendingAudio,
  //   getLocalStream: getLocalStream,
  // });

  const {
    isOpen: signal_ready,
    // message: signal_message,
    isConnecting,
    sendMessage: signal_send,
    subscribe,
    error: serverConnError,
  } = useServerConnection();

  const {
    data: robotInfo,
    error: robotInfoError,
    isPending: isRobotInfoPending,
    setData: setRobotInfo,
  } = useFetchState(getRobotsListFetchURL() + "/" + robot_id);

  const offerOptions = {
    offerToReceiveAudio: isReceiveAudio,
    offerToReceiveVideo: isReceiveVideo,
  };

  const config = getDefaultPeerConnectionConfig();

  const muteAudioTrack = () => {
    if (peerConnection.current != null) {
      const senders = peerConnection.current.getSenders();

      senders.forEach((sender) => {
        console.log("Sender: ", sender);
        const track = sender.track;
        console.log("track: ", track);
        if (track != null && track != undefined && track.kind == "audio") {
          // track.stop();
          track.enabled = false;
          console.log("Audio track muted");
        }
      });
    }
  };

  const unmuteAudio = () => {
    if (peerConnection.current != null) {
      const senders = peerConnection.current.getSenders();

      senders.forEach((sender) => {
        console.log("Sender: ", sender);
        const track = sender.track;
        console.log("track: ", track);
        if (track != null && track != undefined && track.kind == "audio") {
          // track.start();
          track.enabled = true;
          console.log("Audio track unmuted");
        }
      });
    }
  };

  const replaceLocalStreamTrack = (pc, stream) => {
    if (stream == null) {
      console.log("restartLocalStreamTrack: stream is null");
      return;
    }

    const senders = pc.getSenders();

    senders.forEach((sender) => {
      console.log("Sender: ", sender);
      const track = sender.track;
      console.log("track: ", track);
      if (track) {
        const newTrack = stream.getTracks().find((t) => t.kind === track.kind);

        // Stop the track
        if (newTrack != track) {
          track.stop();
        }
        // Remove the track from the sender
        sender.replaceTrack(newTrack);
      }
    });
  };

  const addLocalStreamTrack = (pc, stream) => {
    if (stream == null) {
      console.log("addLocalStreamTrack: stream is null");
      return;
    }
    stream.getTracks().forEach((track) => {
      pc.addTrack(track, stream);
      console.log("pc add track: " + track.label);
    });
    console.log("Adding Local Stream to peer connection");
  };

  const initDataChannel = (pc, dc, message_handler) => {
    dc.onerror = function (error) {
      console.log("dc.onerror() Error:", error);
    };

    dc.onclose = function () {
      console.log("dc.onclose() name: ", dc.label);
    };

    dc.onopen = (evt) => {
      console.log("dc.onopen() name: ", dc.label, "event:", evt);
    };

    dc.onmessage = function (evt) {
      const channel_msg = { channel: dc.label, data: evt.data };
      window.console.debug("message from robot: ", evt.data);
      message_handler(channel_msg);
    };
    return dc;
  };

  const dataChannelMessageReceived = ({ channel, data }) => {
    const channel_msg = { channel: channel, data: data };
    if (!disableChannelMessageState) setChannelMessage(channel_msg);
    notifyEventListeners(EVENT_CHANNEL_MESSAGE_RECEIVED, channel_msg);
  };

  const trackEventHandler = (evt) => {
    console.log("onTrack:", evt);
    if (mediaTracksRef.current == null) {
      mediaTracksRef.current = new Set();
    }
    mediaTracksRef.current.add(evt);
    console.log("onTrack: state media Tracks:", mediaTracksRef.current);
    setMediaTracks(Array.from(mediaTracksRef.current));
  };

  const onIceCandidate = (pc, e) => {
    console.debug(
      `ICE candidate:\n${e.candidate ? e.candidate.candidate : "(null)"}`
    );
    if (trickleICE) {
      //TODO: send the new IceCandidate to the remote peer
      let iceCandidateMessage = {
        type: "candidate",
        candidate: e.candidate,
        RobotId: robot_id,
      };
      let cmd_message = JSON.stringify({
        cmd: "signal",
        data: iceCandidateMessage,
      });

      console.debug("Sending IceCandidate signal");
      signal_send(cmd_message);
    }
  };

  const onAddIceCandidateSuccess = (iceCandidate) => {
    console.debug("addIceCandidate success: ", iceCandidate);
  };

  const onAddIceCandidateError = (iceCandidate, err) => {
    console.log(
      `Failed to add ICE Candidate: ${err.toString()} \nIceCandidate: ${iceCandidate}`
    );
  };

  const addRemoteIceCandidate = (iceCandidate) => {
    console.log("addRemoteIceCandidate(): ", iceCandidate);
    const pc = peerConnection.current;
    pc.addIceCandidate(iceCandidate).then(
      () => onAddIceCandidateSuccess(iceCandidate),
      (err) => onAddIceCandidateError(iceCandidate, err)
    );
  };

  const onCreateSessionDescriptionError = (error) => {
    console.log(`Failed to create session description: ${error.toString()}`);
  };

  const onIceConnectionStateChange = (ev) => {
    let connection = ev.target;
    console.log(
      "[iceconnectionstatechange]: connection.iceConnectionState = " +
        connection.iceConnectionState
    );
    setIceState(connection.iceConnectionState);
    if (connection.iceConnectionState === "failed") {
      /* possibly reconfigure the connection in some way here */
      /* then request ICE restart */
      console.log("RestartICE");
      connection.restartIce();
    } else if (connection.iceConnectionState === "closed") {
      console.log("iceConnectionState closed. Close connection");
      // connection.close();
    }
  };

  const closePeerConnection = () => {
    if (peerDataChannel.current != null) {
      for (let channelName in peerDataChannel.current) {
        const dc = peerDataChannel.current[channelName];
        dc.close();
        dc.onopen = null;
        dc.onclose = null;
        dc.onmessage = null;
      }
      peerDataChannel.current = null;
    }

    let pc = peerConnection.current;
    if (pc != null) {
      peerConnection.current = null;
      if (pc.dataChannel != null) {
        pc.dataChannel.close();
      }
      if (pc.signalingState != "closed") {
        pc.close();
      }
    }
    closeLocalStream();
    //this is for the stats
    disableStats();
  };

  const getStats = () => {
    // console.log("peerstatsgetstats = ", remotePeerConnectionStats.current);
    return new Promise((resolve, reject) => {
      if (peerConnection.current == null) return;
      peerConnection.current
        .getStats(null)
        .then((stats) => {
          var bytesReceived = -1;
          var videoPacketsLost = -1;
          var audioPacketsLost = -1;
          var fractionLost = -1;
          var currentTime = -1;
          var bytesSent = -1;
          var videoPacketsSent = -1;
          var audioPacketsSent = -1;
          var audioLevel = -1;
          var qlr = "";
          var framesEncoded = -1;
          var width = -1;
          var height = -1;
          var fps = -1;
          var frameWidth = -1;
          var frameHeight = -1;
          var videoRoundTripTime = -1;
          var videoJitter = -1;

          var audioRoundTripTime = -1;
          var audioJitter = -1;

          var framesDecoded = -1;
          var framesDropped = -1;
          var framesReceived = -1;

          var audioJitterAverageDelay = -1;
          var videoJitterAverageDelay = -1;
          var availableOutgoingBitrate = Infinity;

          stats.forEach((value) => {
            //Logger.debug(value);
            if (
              value.type == "inbound-rtp" &&
              typeof value.kind != "undefined"
            ) {
              bytesReceived += value.bytesReceived;
              if (typeof value.packetsLost != "undefined") {
                if (value.kind == "audio") {
                  audioPacketsLost = value.packetsLost;
                } else if (value.kind == "video") {
                  videoPacketsLost = value.packetsLost;
                }
              }
              fractionLost += value.fractionLost;
              currentTime = value.timestamp;

              if (typeof value.jitter != "undefined") {
                if (value.kind == "video") {
                  videoJitter = value.jitter;
                } else if (value.kind == "audio") {
                  audioJitter = value.jitter;
                }
              }
            } else if (value.type == "outbound-rtp") {
              //TODO: SPLIT AUDIO AND VIDEO BITRATES
              if (value.kind == "audio") {
                audioPacketsSent = value.packetsSent;
              } else if (value.kind == "video") {
                videoPacketsSent = value.packetsSent;
              }
              bytesSent += value.bytesSent;
              currentTime = value.timestamp;
              qlr = value.qualityLimitationReason;
              if (value.framesEncoded != null) {
                //audio tracks are undefined here
                framesEncoded += value.framesEncoded;
              }
            } else if (
              value.type == "track" &&
              typeof value.kind != "undefined" &&
              value.kind == "audio"
            ) {
              if (typeof value.audioLevel != "undefined") {
                audioLevel = value.audioLevel;
              }

              if (
                typeof value.jitterBufferDelay != "undefined" &&
                typeof value.jitterBufferEmittedCount != "undefined"
              ) {
                audioJitterAverageDelay =
                  value.jitterBufferDelay / value.jitterBufferEmittedCount;
              }
            } else if (
              value.type == "track" &&
              typeof value.kind != "undefined" &&
              value.kind == "video"
            ) {
              if (typeof value.frameWidth != "undefined") {
                frameWidth = value.frameWidth;
              }
              if (typeof value.frameHeight != "undefined") {
                frameHeight = value.frameHeight;
              }

              if (typeof value.framesDecoded != "undefined") {
                framesDecoded = value.framesDecoded;
              }

              if (typeof value.framesDropped != "undefined") {
                framesDropped = value.framesDropped;
              }

              if (typeof value.framesReceived != "undefined") {
                framesReceived = value.framesReceived;
              }

              if (
                typeof value.jitterBufferDelay != "undefined" &&
                typeof value.jitterBufferEmittedCount != "undefined"
              ) {
                videoJitterAverageDelay =
                  value.jitterBufferDelay / value.jitterBufferEmittedCount;
              }
            } else if (
              value.type == "remote-inbound-rtp" &&
              typeof value.kind != "undefined"
            ) {
              if (typeof value.roundTripTime != "undefined") {
                if (value.kind == "video") {
                  videoRoundTripTime = value.roundTripTime;
                } else if (value.kind == "audio") {
                  audioRoundTripTime = value.roundTripTime;
                }
              }
            } else if (value.type == "media-source") {
              if (value.kind == "video") {
                //returns video source dimensions, not necessarily dimensions being encoded by browser
                width = value.width;
                height = value.height;
                fps = value.framesPerSecond;
              }
            } else if (
              value.type == "candidate-pair" &&
              value.state == "succeeded" &&
              value.availableOutgoingBitrate != undefined
            ) {
              availableOutgoingBitrate = value.availableOutgoingBitrate / 1000;
            }
          });

          let peerStats = remotePeerConnectionStats.current;
          peerStats.totalBytesReceived = bytesReceived;
          peerStats.videoPacketsLost = videoPacketsLost;
          peerStats.audioPacketsLost = audioPacketsLost;
          peerStats.fractionLost = fractionLost;
          peerStats.currentTime = currentTime;
          peerStats.totalBytesSent = bytesSent;
          peerStats.totalVideoPacketsSent = videoPacketsSent;
          peerStats.totalAudioPacketsSent = audioPacketsSent;
          peerStats.audioLevel = audioLevel;
          peerStats.qualityLimitationReason = qlr;
          peerStats.totalFramesEncoded = framesEncoded;
          peerStats.resWidth = width;
          peerStats.resHeight = height;
          peerStats.srcFps = fps;
          peerStats.frameWidth = frameWidth;
          peerStats.frameHeight = frameHeight;
          peerStats.videoRoundTripTime = videoRoundTripTime;
          peerStats.videoJitter = videoJitter;
          peerStats.audioRoundTripTime = audioRoundTripTime;
          peerStats.audioJitter = audioJitter;
          peerStats.framesDecoded = framesDecoded;
          peerStats.framesDropped = framesDropped;
          peerStats.framesReceived = framesReceived;

          peerStats.videoJitterAverageDelay = videoJitterAverageDelay;
          peerStats.audioJitterAverageDelay = audioJitterAverageDelay;
          peerStats.availableOutgoingBitrate = availableOutgoingBitrate;

          notifyEventListeners(EVENT_STATS_UPDATED, peerStats);
          // console.log("peerStats: ", peerStats);
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
        });
    });
  };

  const enableStats = () => {
    console.log("enabling stats");
    if (remotePeerConnectionStats.current == null) {
      remotePeerConnectionStats.current = new PeerStats(robot_id);
      remotePeerConnectionStats.current.timerId = setInterval(() => {
        getStats();
      }, statsIntervalTime);
    }
  };

  /**
   * Called to stop the periodic timer which is set by @enableStats
   *
   */
  const disableStats = () => {
    console.log("disable stats");
    if (remotePeerConnectionStats.current != null) {
      clearInterval(remotePeerConnectionStats.current.timerId);
      remotePeerConnectionStats.current = null;
    }
  };

  const stop = () => {
    closePeerConnection();
    if (signal_ready) {
      notifyConnection2RobotStateChanged("closed");
    }
  };

  const notifyConnection2RobotStateChanged = (state) => {
    if (!signal_ready) return;
    const evt_msg = {
      cmd: "event",
      data: {
        event_type: "User2RobotConnectionStateChanged",
        event_data: { robot_id: robot_id, state: state },
      },
    };
    console.log("notifyConnection2RobotStateChanged: ", state);
    if (signal_send == null) {
      console.log("signal_send is null");
      return;
    }
    signal_send(JSON.stringify(evt_msg));
  };

  const onConnectionStateChange = (evt) => {
    const pc = evt.srcElement;
    // console.log("connectionstatechange event: ", evt);
    console.log(
      "connectionstatechange: pc.connectionState = " + pc.connectionState
    );
    setConnectionState(pc.connectionState);
    notifyConnection2RobotStateChanged(pc.connectionState);
    //   switch (pc.connectionState) {
    //     case "new":
    //     case "checking":
    //       setConnectionState("Connecting…");
    //       break;
    //     case "connected":
    //       setConnectionState("Connected");
    //       break;
    //     case "disconnected":
    //       setConnectionState("Disconnecting…");
    //       break;
    //     case "closed":
    //       setConnectionState("Closed");
    //       break;
    //     case "failed":
    //       setConnectionState("Failed");
    //       break;
    //     default:
    //       setConnectionState("Unknown");
    //       break;
    //   }

    if (pc.connectionState === "failed") {
      handlePeerConnectionFailed(pc, evt);
    }
  };

  const initPeerConnection = () => {
    if (peerConnection.current != null) {
      const pc = peerConnection.current;
      closePeerConnection();
      // if (pc.connectionState == "closed" || pc.iceConnectionState == "closed") {
      //   pc.close();
      // } else {
      //   console.log(
      //     "peer connection existed, connectionState=",
      //     pc.connectionState
      //   );
      //   return peerConnection.current;
      // }
    }
    console.log("init a new peer connection");
    mediaTracksRef.current = null;
    const pc = new RTCPeerConnection(config);
    setIsRemoteDescriptionSet(false);
    const dcChannels = {};
    for (const channelName in dataChannelsConfig) {
      const parameters = dataChannelsConfig[channelName];

      const dc = pc.createDataChannel(channelName, parameters);
      initDataChannel(pc, dc, dataChannelMessageReceived);
      dcChannels[channelName] = dc;
    }

    peerConnection.current = pc;
    peerDataChannel.current = dcChannels;

    pc.onicecandidate = (e) => onIceCandidate(pc, e);

    pc.addEventListener("track", trackEventHandler);

    pc.onnegotiationneeded = async () => {
      try {
        console.log("SLD due to negotiationneeded");
        if (pc.signalingState != "stable") {
          console.log("negotiationneeded always fires in stable state");
        }

        // if (makingOffer) {
        //   console.log("negotiationneeded not already in progress");
        // }

        // makingOffer = true;
        // await pc.setLocalDescription();
        // signal_send({ description: pc.localDescription });
      } catch (e) {
        console.log("error: ", e);
      } finally {
        console.log("end onnegotiationneeded");
        // makingOffer = false;
      }
    };

    pc.addEventListener("connectionstatechange", onConnectionStateChange);

    pc.addEventListener("iceconnectionstatechange", onIceConnectionStateChange);

    pc.addEventListener("icegatheringstatechange", (ev) => {
      let connection = ev.target;
      console.log(
        "[icegatheringstatechange]: connection.iceGatheringState = " +
          connection.iceGatheringState
      );
      // switch(connection.iceGatheringState) {
      //   case "gathering":
      //     /* collection of candidates has begun */
      //     break;
      //   case "complete":
      //     /* collection of candidates is finished */
      //     break;
      // }
    });

    if (statsEnabled) enableStats();

    return pc;
  };

  // const preparePeerConnectionFoReconnect = () => {
  //   let pc = peerConnection.current;

  //   // initDataChannels(pc);

  //   if (getLocalStream != null) {
  //     replaceLocalStreamTrack(pc, getLocalStream());
  //   }
  //   // else {
  //   //   pc.addTransceiver("video", { direction: "recvonly" });
  //   //   pc.addTransceiver("audio", { direction: "recvonly" });
  //   // }

  //   // pc.addEventListener("icegatheringstatechange", (ev) => {
  //   //   let connection = ev.target;
  //   //   log_debug(
  //   //     "[icegatheringstatechange]: connection.iceGatheringState = " +
  //   //       connection.iceGatheringState
  //   //   );
  //   //   // switch(connection.iceGatheringState) {
  //   //   //   case "gathering":
  //   //   //     /* collection of candidates has begun */
  //   //   //     break;
  //   //   //   case "complete":
  //   //   //     /* collection of candidates is finished */
  //   //   //     break;
  //   //   // }
  //   // });
  //   return pc;
  // };

  const handlePeerConnectionFailed = (pc, event) => {
    if (!robotOnlineStatus) {
      return;
    }
    setTimeout(() => {
      handleReconnection(4);
    }, WAITING_TIME_BEFORE_AUTO_RECONNECT);
  };

  const connect = () => {
    console.log("create new RTCPeerConnection ");

    const pc = initPeerConnection();
    const mediaConstraint = {
      video: isSendingVideo,
      audio: isSendingAudio,
    };

    getLocalMediaStream(mediaConstraint)
      .then((stream) => {
        addLocalStreamTrack(pc, stream);

        pc.createOffer(offerOptions).then(
          (desc) => onCreateOfferSuccess(desc, pc),
          onCreateSessionDescriptionError
        );
      })
      .catch((err) => {
        console.log("Error while get media: ", err);
        pc.createOffer(offerOptions).then(
          (desc) => onCreateOfferSuccess(desc, pc),
          onCreateSessionDescriptionError
        );
      });
  };

  const onCreateOfferSuccess = (desc, pc) => {
    console.log("createOffer succeed");
    pc.setLocalDescription(desc)
      .then(function () {
        console.log("[negotiate] Begin ICE gathering");
        return new Promise(function (resolve) {
          if (pc.iceGatheringState === "complete") {
            console.log("[negotiate] ICE gathering completed");
            resolve();
          } else if (trickleICE) {
            console.log("[negotiate] trickle ICE is enabled");
            resolve();
          } else {
            function checkState() {
              console.log(
                "[negotiate] ICE gathering State: " + pc.iceGatheringState
              );
              if (pc.iceGatheringState === "complete") {
                pc.removeEventListener("icegatheringstatechange", checkState);
                resolve();
              }
            }
            pc.addEventListener("icegatheringstatechange", checkState);
          }
        });
      })
      .then(function () {
        let offer = pc.localDescription;
        // console.log(`LocalDescription: \n${offer.sdp}`);
        console.log("LocalDescription: ", offer.sdp);

        //TODO: move to signaling handler class
        let offerMessage = {
          RobotId: robot_id,
          sdp: offer.sdp,
          type: offer.type,
        };
        let cmd_message = JSON.stringify({
          cmd: "connect2robot",
          data: offerMessage,
        });

        console.log("Sending offer via websocket");
        console.log(cmd_message);
        signal_send(cmd_message);
        console.log("[negotiate] Offer message is sent!");
        // setConnectionState("Dialing...");
      })

      .catch(function (err) {
        console.log("[negotiate] Error in websocket connecting: " + err);
        setError(err);
      });
  };

  const handleReconnection = (recheckCount) => {
    if (!robotOnlineStatus || !signal_ready) {
      return;
    }

    let pc = peerConnection.current;
    if (
      pc !== null &&
      pc.connectionState === "connected" &&
      pc.iceConnectionState !== "disconnected" &&
      pc.iceConnectionState !== "failed"
    ) {
      //TODO: check again after afew second later?
      console.log("Current peer connection is OK");
      console.log("recheckCount: " + recheckCount);
      if (recheckCount > 0) {
        setTimeout(
          () => handleReconnection(recheckCount - 1),
          WAITING_TIME_TO_CHECK_FOR_DISCONNECTED
        );
      }
      return pc;
    }

    if (pc == null) {
      console.log("peerConnection is null");
    } else {
      console.log("begin checking for reconnection");
      console.log("pc.connectionState: " + pc.connectionState);
      console.log("pc.iceConnectionState: " + pc.iceConnectionState);
    }

    if (pc != null && pc.connectionState !== "closed") {
      pc.close();
    }

    connect();
    // pc = initPeerConnection();

    // pc.createOffer(offerOptions).then(
    //   (desc) => onCreateOfferSuccess(desc, pc),
    //   onCreateSessionDescriptionError
    // );
  };

  const robotConnectedHandler = (evt) => {
    console.log("DEBUG: robot connected event: ", evt);
    setRobotOnlineStatus(true);
  };

  const robotDisconnectedHandler = (evt) => {
    console.log("DEBUG: robot disconnected event: ", evt);
    setRobotOnlineStatus(false);
  };

  const handeSignalSDP = (sdp) => {
    const pc = peerConnection.current;
    pc.setRemoteDescription(sdp).then(
      () => onSetRemoteSuccess(pc),
      onSetSessionDescriptionError
    );
  };

  const handleSignalCandidate = (signal_data) => {
    console.log("signal data: ", signal_data);
    const { RobotId, IceCandidate } = signal_data;
    addRemoteIceCandidate(IceCandidate);
  };

  useEffect(() => {
    if (robot_id == null || robot_id == undefined) return;

    const unsubscribe_connected = subscribe(
      "robot_event/connected/" + robot_id,
      robotConnectedHandler
    );
    const unsubscribe_disconnected = subscribe(
      "robot_event/disconnected/" + robot_id,
      robotDisconnectedHandler
    );

    const unsubscribe_signal_sdp = subscribe("signal/sdp", handeSignalSDP);
    const unsubscribe_signal_candidate = subscribe(
      robot_id + "/signal",
      handleSignalCandidate
    );

    return () => {
      if (unsubscribe_connected) {
        unsubscribe_connected();
      }
      if (unsubscribe_disconnected) {
        unsubscribe_disconnected();
      }
      if (unsubscribe_signal_sdp) {
        unsubscribe_signal_sdp();
      }
      if (unsubscribe_signal_candidate) {
        unsubscribe_signal_candidate();
      }
    };
  }, [robot_id]);

  useEffect(() => {
    if (statsEnabled) {
      enableStats();
    } else {
      disableStats();
    }
  }, [statsEnabled]);

  useEffect(() => {
    console.log("Robot info: ", robotInfo);
    if (robotInfo && robotInfo?.status) {
      const status = robotInfo.status != "offline";
      setRobotOnlineStatus(status);
    }
  }, [robotInfo]);

  const onSetRemoteSuccess = (pc) => {
    console.log("setRemoteDescription complete: ", pc);
    setIsRemoteDescriptionSet(true);
  };

  const onSetSessionDescriptionError = (err) => {
    console.log(`Failed to set session description: ${err.toString()}`);
  };

  useEffect(() => {
    if (!signal_ready) return;
    if (!robotOnlineStatus) return;

    if (peerConnection.current == null) {
      connect();
    } else {
      handleReconnection(MAX_NUMBER_OF_ATTEMP_TO_CHECK_FOR_DISCONNECTED);
    }

    return () => {
      // closePeerConnection();
    };
  }, [signal_ready, robotOnlineStatus]);

  useEffect(() => {
    return () => {
      console.log("Clean up useWebRTC");
      disableStats();

      let pc = peerConnection.current;
      if (pc !== null && pc.connectionState !== "closed") {
        closePeerConnection();
        pc.close();
        console.log("Peer connection closed!");
        notifyConnection2RobotStateChanged("closed");
      }
    };
  }, []);

  // let channelSends = null;
  // if (dataChannels.current != null) {
  //   channelSends = {};
  //   for (const dc of dataChannels.current) {
  //     const dcSend = dc?.send.bind(dc);
  //     channelSends[dc.label] = dcSend;
  //   }
  // }

  const channelSend = (channelLabel, data) => {
    if (peerDataChannel.current == null) {
      console.log("channelSend: dataChannels.current is null!");
      return;
    }
    const dataChannels = peerDataChannel.current;

    const dc = dataChannels[channelLabel];

    if (dc.readyState != "open") {
      console.log(
        "data channel '" +
          channelLabel +
          "' is not open yet! readyState: " +
          dc.readyState
      );
      alert(
        "data channel '" +
          channelLabel +
          "' is not open yet! readyState: " +
          dc.readyState
      );
      return;
    }
    // console.log("data channel sending: ", data);
    dc.send(data);
    // console.log("data channel send completed");
  };

  const toggleRemoteVideo = (enabled) => {
    setIsReceiveVideo(enabled);
    let media_config = {
      type: "media_config",
      video: { enabled: enabled },
      RobotId: robot_id,
    };
    let cmd_message = {
      cmd: "signal",
      data: media_config,
    };

    console.log("Sending media config signal", cmd_message);
    signal_send(JSON.stringify(cmd_message));
  };

  const toggleRemoteAudio = (enabled) => {
    setIsReceiveAudio(enabled);
    let media_config = {
      type: "media_config",
      audio: { enabled: enabled },
      RobotId: robot_id,
    };
    let cmd_message = {
      cmd: "signal",
      data: media_config,
    };

    console.log("Sending media config signal", cmd_message);
    signal_send(JSON.stringify(cmd_message));
  };

  const toggleLocalAudio = (enabled) => {
    setIsSendingAudio(enabled);
    if (enabled) {
      unmuteAudio();
    } else {
      muteAudioTrack();
    }
  };

  const ret = {
    allowLocalAudio,
    allowLocalVideo,
    allowRemoteAudio,
    allowRemoteVideo,
    mediaAllowed,
    mediaError,
    robotId: robot_id,
    setRobotId,
    connectionState,
    mediaTracks,
    channelMessage,
    channelSend,
    connect,
    close: closePeerConnection,
    isReceiveVideo,
    setIsReceiveVideo: toggleRemoteVideo,
    isReceiveAudio,
    setIsReceiveAudio: toggleRemoteAudio,
    isSendingAudio,
    setIsSendingAudio: toggleLocalAudio,
    isSendingVideo,
    setIsSendingVideo,
    error,
    robotOnlineStatus,
    enableStats,
    disableStats,
    statsEnabled,
    setStatsEnabled,
    setStatsIntervalTime,
    addWebRTCEventListener,
    removeWebRTCEventListener,
    addStatsUpdatedEventListener,
    removeStatsUpdatedEventListener,
    addChannelMessageReceivedEventListener,
  };
  return (
    <RobotPeerConnectionContext.Provider value={ret}>
      {children}
    </RobotPeerConnectionContext.Provider>
  );
};

RobotPeerConnectionProvider.defaultProps = {
  allowLocalAudio: false,
  allowLocalVideo: false,
  allowRemoteAudio: false,
  allowRemoteVideo: true,

  isReceiveVideo: true,
  isReceiveAudio: false,
  isSendingVideo: false,
  isSendingAudio: false,
  StatsInterval: 1000,
  StatsEnabled: false,
  trickleICE: false,
  disableChannelMessageState: false,
  dataChannelsConfig: {
    chat: {
      ordered: true,
      // reliable: true,
      // protocol: "udp",
      // maxRetransmits: 0,
      maxPacketLifeTime: 100,
      // negotiated: true,
      // id: 0,
    },
  },
};

export const useRobotPeerConnection = () =>
  useContext(RobotPeerConnectionContext);
