export const userDataReducer = (state, action) => {
  switch (action.type) {
    case "SET_ROBOTLIST": {
      return { ...state, robotList: [...action.payload] };
    }

    case "SET_ROBOT_CONNECTED": {
      const robot_id = action.payload.id;
      // console.log("SET_ROBOT_CONNECTED: ", robot_id);

      const updatedRobotList = state.robotList.map((rb) => {
        if (rb.id == robot_id) {
          // console.log("SET_ROBOT_CONNECTED online: ", robot_id);
          return { ...rb, ...action.payload };
        } else {
          return rb;
        }
      });
      let rs = {
        ...state,
        robotList: updatedRobotList,
      };
      // console.log("SET_ROBOT_CONNECTED return: ", rs);
      return rs;
    }

    case "SET_ROBOT_DISCONNECTED": {
      const robot_id = action.payload.id;
      // console.log("SET_ROBOT_DISCONNECTED: ", robot_id);

      const updatedRobotList = state.robotList.map((rb) => {
        if (rb.id == robot_id) {
          // console.log("SET_ROBOT_DISCONNECTED offline: ", robot_id);
          return { ...rb, ...action.payload, status: "offline" };
        } else {
          return rb;
        }
      });

      let rs = {
        ...state,
        robotList: updatedRobotList,
      };
      // console.log("SET_ROBOT_DISCONNECTED return: ", rs);
      return rs;
    }

    default:
      return state;
  }
};

export const initialUserData = {
  robotList: [],
};
