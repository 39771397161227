import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import { getFetchURL } from "../common/setting";
import axios from "axios";
import { useAuth } from "./AuthProvider";

export const TOPIC_SIGNAL = "signal/sdp";

const loginService = async (username, password) => {
  const loginURL = getFetchURL("/auth/login");
  return await axios.post(loginURL, { username: username, password });
};

const ServerConnectionContext = createContext();

export const ServerConnectionProvider = ({ children, wsUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const { auth } = useAuth();

  const [subscriptions, setSubscriptions] = useState({});

  const ws = useRef(null);
  const reconnectTimeout = useRef(null);
  const [reconnectCountDown, setReconnectCountDown] = useState(null);

  const sendMessage = async (msg) => {
    if (!isOpen || ws.current == null) {
      throw new Error("Can't send message. Connection is not open");
    }
    const socket = ws.current;
    if (socket.readyState != WebSocket.OPEN) {
      throw new Error("Can't send message. Websocket state is NOT OPEN");
    }
    socket.send(msg);
  };

  const authenticate = () => {
    const socket = ws.current;
    if (socket.readyState != WebSocket.OPEN) return;
    const msg = { cmd: "authenticate", data: { token: auth.token } };
    socket.send(JSON.stringify(msg));
  };

  const connect = () => {
    console.log("create a new WebSocket");
    const socket = new WebSocket(wsUrl);
    setIsConnecting(true);

    socket.onopen = () => {
      console.log("ws connection open: " + wsUrl);
      setIsOpen(true);
      setError(null);
      setIsConnecting(false);
      authenticate();
    };
    ws.onerror = (err) => {
      console.log("WebsocketProvider useEffect: ws connection failed!", err);
      setIsOpen(false);
      setError(err);
      setIsConnecting(false);
    };
    socket.onclose = (event) => {
      console.log("ws connection onclose: " + wsUrl);
      console.log("WebsocketContext useEffect: close_code: ", event.code);
      setIsOpen(false);
      setError(null);
      setIsConnecting(false);
      if (ws.current != null) {
        reconnect(2);
      }
    };
    socket.onmessage = (event) => {
      console.debug("ws onmessage: ", event);
      setMessage(event.data);
      handleMessageReceived(event.data);
    };

    ws.current = socket;
  };

  const reconnect = (countdown = 0) => {
    console.log(
      "Reconnect to websocket server after " + countdown + " seconds."
    );
    if (reconnectTimeout.current) {
      clearTimeout(reconnectTimeout.current);
    }
    setReconnectCountDown(countdown);
    if (countdown > 0) {
      reconnectTimeout.current = setTimeout(
        () => reconnect(countdown - 1),
        1000
      );
      return;
    }
    connect();
  };

  const closeConnection = () => {
    setIsOpen(false);
    setIsConnecting(false);
    const socket = ws.current;
    if (socket != null && socket.readyState == WebSocket.OPEN) {
      console.debug("closeConnection: close socket");
      socket.close();
      ws.current = null;
    }
    if (reconnectTimeout.current) {
      console.debug("closeConnection: clear reconnection timer");
      clearTimeout(reconnectTimeout.current);
    }
  };

  const subscribe = (topic, callback) => {
    if (!subscriptions[topic]) {
      subscriptions[topic] = [];
    }

    subscriptions[topic].push(callback);

    return () => {
      if (subscriptions[topic]) {
        subscriptions[topic] = subscriptions[topic].filter(
          (subscriber) => subscriber !== callback
        );

        if (subscriptions[topic].length === 0) {
          delete subscriptions[topic];
        }
      }
    };
  };

  const publish = (topic, message) => {
    if (subscriptions[topic]) {
      subscriptions[topic].forEach((subscriber) => subscriber(message));
      // await Promise.all(
      //   subscriptions[topic].map(async (subscriber) => {
      //     try {
      //       await subscriber(message);
      //     } catch (error) {
      //       // Handle any errors that occur during message processing
      //       console.error(`Error handling message for topic ${topic}:`, error);
      //     }
      //   })
      // );
    }
  };

  const handleMessageReceived = (message) => {
    try {
      let answer = JSON.parse(message);

      let msg_type = null;
      if ("type" in answer) {
        msg_type = answer["type"];
      } else if ("msg_type" in answer) {
        msg_type = answer["msg_type"];
      }

      if (msg_type === "answer") {
        // publish_event_msg = {event_src: null, data: {description: answer}}
        // const eventData = { RobotId: null, Description: answer };
        publish("signal/sdp", answer);
      } else if (msg_type === "robot_event") {
        const data = answer["data"];
        if (data["event_type"] === "connected") {
          let event_data = data["event_data"];
          let rid = event_data["id"];
          // TODO: publish robot connected event
          publish("robot_event/connected", event_data);
          publish("robot_event/connected/" + rid, event_data);
        } else if (data["event_type"] === "disconnected") {
          let event_data = data["event_data"];
          let rid = event_data["id"];
          // TODO: publish robot disconnected event
          publish("robot_event/disconnected", event_data);
          publish("robot_event/disconnected/" + rid, event_data);
        }
      } else if (msg_type === "signal") {
        const data = answer["data"];
        const rid = answer["from"];

        const eventData = data;
        publish(rid + "/signal", eventData);
      } else {
        console.log("unknown answer type: ", answer);
      }
    } catch (e) {
      console.log("Error: " + e + "\nMessage data: " + message);
    }
  };

  useEffect(() => {
    if (!auth.isAuth) {
      console.log("useWS useEffect: not authenticated");
      closeConnection();
      return;
    }
    if (
      ws.current == null ||
      ws.current.readyState == WebSocket.CLOSING ||
      ws.current.readyState == WebSocket.CLOSED
    ) {
      connect();
    } else {
      console.debug("useWS effect: use previous websocket");
      authenticate();
    }

    return () => {
      const socket = ws.current;
      console.debug("useWS useEffect exit: readyState =", socket.readyState);
      setIsOpen(false);
      setIsConnecting(false);
      if (socket != null && socket.readyState == WebSocket.OPEN) {
        socket.close();
        console.debug("useWS useEffect exit: close socket");
        ws.current = null;
      }
      if (reconnectTimeout.current) {
        console.debug("useWS useEffect exit: clear reconnection timer");
        clearTimeout(reconnectTimeout.current);
      }
    };
  }, [wsUrl, auth]);

  // useEffect(() => {
  //   authenticate();
  // }, [auth]);

  const ret = {
    isOpen,
    isConnecting,
    message,
    sendMessage,
    subscribe,
    error,
    reconnectCountDown,
  };

  return (
    <ServerConnectionContext.Provider value={ret}>
      {children}
    </ServerConnectionContext.Provider>
  );
};

export const useServerConnection = () => useContext(ServerConnectionContext);
