import React, { Component, useRef, useEffect } from "react";
import {
  Badge,
  Button,
  Col,
  Collapse,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  DatePicker,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useState } from "react";
import { deleteMissionService } from "../services/mission-services";
import { useRobotMissionsData } from "../contexts/RobotMissionsDataProvider";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { downloadCsvFile, jsonToCsv } from "../common/utilities";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const { RangePicker } = DatePicker;

const { Panel } = Collapse;

function downloadJsonFile(objectData, filename) {
  var jsonData = JSON.stringify(objectData, null, 2);
  var blob = new Blob([jsonData], { type: "application/json" });
  var url = URL.createObjectURL(blob);

  var a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  URL.revokeObjectURL(url);
}

const getMissionEventsLogJsonObject = (mission) => {
  // alert("Download Mission File " + missionItem.mission_id);
  console.log("mission logs: ", mission);
  const mission_jobs = mission.mission_jobs.map((job) => {
    return {
      job_id: job.job_id,
      job_type: job.job_description.job_type,
      job_name: job.job_description.job_name,
      job_desc: job.job_description.job_desc,
      start_time: job.start_time,
      end_time: job.end_time,
      status: job.status,
      events_log: job.events_log,
    };
  });

  const mission_log = { ...mission, mission_jobs: mission_jobs };

  return mission_log;
};

const getMissionJsonObject = (mission) => {
  // alert("Download Mission File " + missionItem.mission_id);
  console.log("mission: ", mission);
  const mission_Jobs = mission.mission_jobs.map((job) => {
    return {
      job_id: job.job_id,
      job_description: job.job_description,
    };
  });
  const mission_desc = {
    mission_id: mission.mission_id,
    mission_type: mission.mission_type,
    mission_name: mission.mission_name,
    description: mission.description,
    mission_jobs: mission_Jobs,
  };
  return mission_desc;
};

const downloadMissionFile = (mission) => {
  const obj = getMissionJsonObject(mission);
  downloadJsonFile(obj, obj["mission_id"] + ".mission");
};

const downloadMissionLogFile = (mission) => {
  const logObj = getMissionEventsLogJsonObject(mission);
  downloadJsonFile(logObj, mission.mission_id + "_EventsLog.json");
};

const MissionsDashboardUser = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.JobId === editingKey;
  const [missionsPagination, setMissionsPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [missionFilteredValue, setMissionFilteredValue] = useState({});

  const [currentDataSource, setCurrentDataSource] = useState(null);

  const [sortedInfo, setSortedInfo] = useState({
    column: null,
    order: "descend",
    field: null,
    columnKey: "id",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const displayNotification = (type, msg) => {
    messageApi.open({
      type: type,
      content: msg,
      style: {
        marginTop: "5vh",
      },
    });
  };

  // const edit = (record) => {
  //   form.setFieldsValue({
  //     Status: "",
  //     Result: "",
  //     Events: "",
  //     ...record,
  //   });
  //   setEditingKey(record.JobId);
  // };

  const cancelEditing = (currentPage, pagesize) => {
    setEditingKey("");
  };

  // const save = async (key) => {
  //   try {
  //     console.log("editing key: ", key);
  //     const row = await form.validateFields();

  //     console.log("Edited row: ", row);

  //     const newData = [...missions];
  //     const index = newData.findIndex((item) => key === item.JobId);
  //     if (index > -1) {
  //       const job = newData[index];
  //       console.log("Editing job: ", job);

  //       const updated_item = {
  //         ...job,
  //         ...row,
  //       };
  //       update_job_to_server(updated_item)
  //         .then((updated_Job) => {
  //           if (updated_Job == null) {
  //             // alert("Job update failed!\nJobId: " + updated_item.JobId);
  //             displayNotification(
  //               "error",
  //               "Job update failed!\nJobId: " + updated_item.JobId
  //             );
  //           } else {
  //             newData.splice(index, 1, updated_item);
  //             setMissionsData(newData);
  //             setEditingKey("");
  //             // alert("Job update succeed!\nJobID: " + updated_Job.JobId);
  //             displayNotification(
  //               "success",
  //               "Job update succeed!\nJobID: " + updated_Job.JobId
  //             );
  //           }
  //         })
  //         .catch((err) => {
  //           if (err.name === "AbortError") {
  //           } else {
  //             alert("Error: " + err.message);
  //           }
  //         });
  //     } else {
  //       // newData.push(row);
  //       // setJobsData(newData);

  //       setEditingKey("");
  //     }
  //   } catch (errInfo) {
  //     console.log("Validate Failed:", errInfo);
  //   }
  // };

  // const update_job_to_server = async (job) => {};

  // const {
  //   data: presets,
  //   error: isPresetsLoadingError,
  //   isPending: isPresetsLoading,
  //   setData: setPresetData,
  // } = useFetchState(getFetchURL("/robot-job-presets"));

  // const {
  //   data: missions,
  //   error,
  //   isPending,
  //   setData: setMissionsData,
  // } = useFetchState(getFetchURL("/robot-missions"));

  const {
    robotMissionsDataState,
    error,
    dataLoading: isPending,
  } = useRobotMissionsData();

  const missions = robotMissionsDataState.missionsList;

  // const [missions, setMissionsData] = useState(get_missions_data());
  // const error = null;
  // const isPending = false;

  // console.log("Missions: ", missions);

  const [filtersMissionStatusItems, setFiltersMissionStatusItems] = useState(
    []
  );
  const [filtersMissionNamesItems, setFiltersMissionNamesItems] = useState([]);

  const [filtersRobotItems, setFiltersRobotItems] = useState([]);

  const deleteMission = async (mission) => {
    console.log("Delete mission: ", mission.mission_id);
    try {
      const response = await deleteMissionService(mission.mission_id);

      if (response.status === 200) {
        const deletedMissions = response.data;
        console.log("Delete mission succeed: ", deletedMissions);
        displayNotification("success", "Deleted missions:\n" + deletedMissions);
      } else {
        console.log("Delete mission error: ", response.data);
        displayNotification(
          "error",
          "Deleted missions:\n" + response.data.message
        );
      }
    } catch (err) {
      console.log("Delete mission error: ", err);
      displayNotification("error", "Deleted error:\n" + err);
    }
  };

  const itemsMissionActions = (missionItem) => {
    return [
      {
        key: "1",
        label: "Download Mission File",
        onClick: () => {
          downloadMissionFile(missionItem);
        },
        url: "/mission-file/" + missionItem.mission_id,
      },
      {
        key: "2",
        label: "Download Mission Log",
        onClick: () => {
          downloadMissionLogFile(missionItem);
        },
        url: "/mission-log/" + missionItem.mission_id,
      },
      // {
      //   key: "3",
      //   label: "Delete Mission",
      //   onClick: () => {
      //     deleteMission(missionItem);
      //   },
      //   // url: "/mission-log/" + missionItem.mission_id,
      // },
    ];
  };

  useEffect(() => {
    if (missions == null) return;

    console.log("Jobs size: ", missions.length);

    let missionStatusSet = new Set();

    let robotsSet = new Set();
    let missionNamesSet = new Set();

    for (const mission of missions) {
      robotsSet.add(mission.robot_id);
      missionStatusSet.add(mission.status);
      missionNamesSet.add(mission.mission_name);
    }

    const missionStatusFilters = [];
    for (const status of missionStatusSet) {
      missionStatusFilters.push({ text: status, value: status });
    }

    setFiltersMissionStatusItems(missionStatusFilters);

    const missionNamesFilters = [];
    for (const missionName of missionNamesSet) {
      missionNamesFilters.push({ text: missionName, value: missionName });
    }

    setFiltersMissionNamesItems(missionNamesFilters);

    // console.log("set size: ", set);

    const robotFilters = [];
    for (const robotId of robotsSet) {
      robotFilters.push({ text: robotId, value: robotId });
    }

    // console.log("Robot filters list: ", robotFilters);

    setFiltersRobotItems(robotFilters);
  }, [missions]);

  const getJobEventRowKey = (job_event) => {};

  const expandedRowRender = (recordMission) => {
    const jobsColumns = [
      {
        title: "Job Id",
        dataIndex: "job_id",
        key: "job_id",
      },
      {
        title: "Name",
        dataIndex: "job_description",
        key: "job_name",
        render: (jd) => jd?.job_name ?? "",
      },
      {
        title: "Description",
        dataIndex: "job_description",
        key: "job_desc",
        render: (jd) => jd?.job_desc,
      },
      {
        title: "Start time",
        dataIndex: "start_time",
        key: "start_time",
      },
      {
        title: "End time",
        dataIndex: "end_time",
        key: "end_time",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        // render: (st) => <Badge status={dictTaskStatus[st]} text={st} />,
      },
    ];
    const eventsColumns = [
      {
        title: "Timestamp",
        dataIndex: "timestamp",
        key: "timestamp",
      },
      {
        title: "Event name",
        dataIndex: "event_type",
        key: "event_type",
      },
      {
        title: "Event source",
        dataIndex: "event_source",
        key: "event_source",
      },
      {
        title: "Data",
        dataIndex: "data",
        key: "data",
        render: (data) => JSON.stringify(data),
      },
    ];
    // console.log("expandedRowRender: ", record.JobId, record.DeliveryDetail);
    return (
      <Collapse defaultActiveKey={[0]}>
        <Panel header="Jobs" key="0">
          <Table
            // title={(record) => "Jobs"}
            columns={jobsColumns}
            dataSource={recordMission["mission_jobs"]}
            expandable={{
              expandedRowRender: jobExpandedRowRender,
              // expandedRowRender,
              // defaultExpandedRowKeys: ["id"],
              expandRowByClick: true,
              rowExpandable: jobRowExpandable,
            }}
            pagination={false}
            rowKey="job_id"
            size="small"
          />
        </Panel>
        <Panel header="Mission Events Log" key="1">
          <Table
            // title={(record) => "Events Log"}
            columns={eventsColumns}
            dataSource={recordMission["events_log"]}
            pagination={false}
            rowKey="timestamp"
            size="small"
          />
        </Panel>
      </Collapse>
    );
  };

  const isMissionRowExpandable = (record) => {
    return record.mission_jobs != undefined && record.mission_jobs.length > 0;
  };

  const jobExpandedRowRender = (jobRecord) => {
    const eventsColumns = [
      // {
      //   title: "#",
      //   // dataIndex: "id",
      //   key: "id",
      //   render: (id, jobRecord, index) => {
      //     return index + 1;
      //   },
      //   // sortDirections: ["ascend", "descend"],
      //   // defaultSortOrder: "descend",
      //   // sorter: (a, b, sortOrder) => a > b,
      // },
      {
        title: "Timestamp",
        dataIndex: "timestamp",
        key: "timestamp",
      },
      {
        title: "Event name",
        dataIndex: "event_type",
        key: "event_type",
      },
      {
        title: "Event source",
        dataIndex: "event_source",
        key: "event_source",
      },
      {
        title: "Data",
        dataIndex: "data",
        key: "data",
        render: (data) => JSON.stringify(data),
      },
    ];
    // console.log("expandedRowRender: ", record.JobId, record.DeliveryDetail);
    return (
      <Collapse defaultActiveKey={[0]}>
        <Panel header="Job Events Log" key="0">
          <Table
            // title={(record) => "Events Log"}
            columns={eventsColumns}
            dataSource={jobRecord["events_log"]}
            pagination={false}
            rowKey="timestamp"
            size="small"
          />
        </Panel>
      </Collapse>
    );
  };

  const jobRowExpandable = (record) => {
    return record.events_log != undefined && record.events_log.length > 0;
  };

  const exportToCSV = () => {
    const csvData = jsonToCsv(currentDataSource);
    downloadCsvFile(csvData, "missions_log.csv");
  };

  const handleReset = (clearFilters, confirm, closeDropdown = true) => {
    clearFilters();
    confirm({
      closeDropdown: closeDropdown,
    });
  };

  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const formatWithMilliseconds = "YYYY-MM-DDTHH:mm:ss.SSSSSS";

  const isDateTimeInRange = (value, from, to) => {
    if (value === null || value == "undefined") return false;

    let timeValue = dayjs(value, dateFormat, true);
    if (!timeValue.isValid()) {
      timeValue = dayjs(value, formatWithMilliseconds, true);
      if (!timeValue.isValid()) {
        return false;
      }
      timeValue = timeValue.tz("Asia/Seoul");
    }

    if (
      from !== null &&
      from !== "undefined" &&
      from.isValid() &&
      timeValue.isBefore(from)
    ) {
      return false;
    }

    if (
      to !== null &&
      to !== "undefined" &&
      to.isValid() &&
      timeValue.isAfter(to)
    ) {
      return false;
    }
    return true;
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          placeholder={["From begin", "To now"]}
          needConfirm
          // size="small"
          // style={{
          //   // marginBottom: 8,
          //   display: "block",
          // }}
          showTime={{
            defaultValue: [
              dayjs("00:00:00", "HH:mm:ss"),
              dayjs("23:59:59", "HH:mm:ss"),
            ],
          }}
          allowEmpty={[true, true]}
          format={dateFormat}
          value={selectedKeys[0]}
          onChange={(dates, dateStrings) => {
            console.log("time changed date: ", dates);
            console.log("time changed str: ", dateStrings);
            const filterKey = [dates];
            setSelectedKeys(filterKey);
          }}
          onOk={(dates, dateStrings) => {
            confirm({
              closeDropdown: true,
            });
          }}
        />

        <Space>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) =>
      isDateTimeInRange(record[dataIndex], value[0], value[1]),
  });

  const missionColumns = [
    {
      title: "Index",
      dataIndex: "create_time",
      key: "id",
      render: (id, record, index) => {
        let sortedColumn = null;
        if (Array.isArray(sortedInfo)) {
          for (let i = 0; i < sortedInfo.length; i++)
            if (
              sortedInfo[i].columnKey === "id" ||
              sortedInfo[i].columnKey === "create_time"
            ) {
              sortedColumn = sortedInfo[i].order;
              break;
            }
        } else {
          sortedColumn =
            sortedInfo.columnKey === "id" ||
            sortedInfo.columnKey === "create_time"
              ? sortedInfo.order
              : null;
        }

        const rowCount = currentDataSource?.length || missions?.length;
        if (sortedColumn === "descend") {
          return (
            rowCount -
            ((missionsPagination.current - 1) * missionsPagination.pageSize +
              index)
          );
        } else {
          return (
            (missionsPagination.current - 1) * missionsPagination.pageSize +
            index +
            1
          );
        }
      },
      sortDirections: ["descend"],
      defaultSortOrder: "descend",
      sorter: (a, b, sortOrder) => {
        return a["create_time"] > b["create_time"];
      },
    },
    {
      title: "Mission ID",
      dataIndex: "mission_id",
      key: "mission_id",
      // render: (JobId) => <Link to={"/delivery/" + JobId}>{JobId}</Link>,
      // sorter: (a, b) => a > b,
    },
    {
      title: "Mission name",
      dataIndex: "mission_name",
      key: "mission_name",
      filters: filtersMissionNamesItems,
      onFilter: (value, record) => record.mission_name == value,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "User",
    //   dataIndex: "user_id",
    //   key: "user_id",
    // },
    {
      title: "Robot ID",
      dataIndex: "robot_id",
      key: "robot_id",
      filters: filtersRobotItems,
      onFilter: (value, record) => record.robot_id.includes(value),
    },
    {
      title: "Job counts",
      dataIndex: "mission_jobs",
      key: "jobs",
      render: (jobs) => jobs.length,
    },
    {
      title: "CreateTime",
      dataIndex: "create_time",
      key: "create_time",
      sortDirections: ["descend"],
      defaultSortOrder: "descend",
      sorter: (a, b, sortOrder) => {
        return a["create_time"] > b["create_time"];
      },
      ...getColumnSearchProps("create_time"),
    },
    {
      title: "Start time",
      dataIndex: "start_time",
      key: "start_time",
    },
    {
      title: "End time",
      dataIndex: "end_time",
      key: "end_time",
    },
    {
      title: "Final status",
      dataIndex: "status",
      key: "status",
      // editable: true,
      filters: filtersMissionStatusItems,
      onFilter: (value, record) => record.status == value,
    },
    // {
    //   title: "Result",
    //   dataIndex: "Result",
    //   key: "Result",
    //   editable: true,
    //   filters: filters_job_result,
    //   onFilter: (value, record) => record.Result == value,
    // },
    // {
    //   title: "operation",
    //   dataIndex: "operation",
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Typography.Link
    //           onClick={() => save(record.JobId)}
    //           style={{
    //             marginRight: 8,
    //           }}
    //         >
    //           Save
    //         </Typography.Link>
    //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //           <a>Cancel</a>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <Typography.Link
    //         disabled={editingKey !== ""}
    //         onClick={() => edit(record)}
    //       >
    //         Edit
    //       </Typography.Link>
    //     );
    //   },
    // },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      render: (op, record, index) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: itemsMissionActions(record),
            }}
          >
            <a>
              Download <DownOutlined />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const mergedColumns = missionColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "Result" ? "JobResultDropdown" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const tableMissions_onChange = (pagination, filters, sorter, extra) => {
    console.log(
      "tableMissions_onChange. params",
      pagination,
      filters,
      sorter,
      extra
    );
    setMissionsPagination(pagination);
    setSortedInfo(sorter);
    setMissionFilteredValue(filters);
    setCurrentDataSource(extra.currentDataSource);
  };

  return (
    <div>
      {contextHolder}
      <h2>Robots Missions</h2>
      {error && <div> {error}</div>}
      {isPending && <div>Loading ...</div>}
      {missions && (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Space>
            Filtering by Create time:{" "}
            <RangePicker
              disabled
              placeholder={["From begin", "To now"]}
              // size="small"
              // style={{
              //   // marginBottom: 8,
              //   display: "block",
              // }}
              showTime={{
                defaultValue: [
                  dayjs("00:00:00", "HH:mm:ss"),
                  dayjs("23:59:59", "HH:mm:ss"),
                ],
              }}
              allowEmpty={[true, true]}
              format={dateFormat}
              value={
                missionFilteredValue.create_time == null ||
                missionFilteredValue.create_time === "undefined"
                  ? [null, null]
                  : missionFilteredValue.create_time[0]
              }
              onChange={(dates, dateStrings) => {
                console.log("filter time changed date: ", dates);
                console.log("filter time changed str: ", dateStrings);
                const filterKey = [dates];
                setMissionFilteredValue({
                  ...missionFilteredValue,
                  create_time: filterKey,
                });
              }}
              // onOk={(dates, dateStrings) => {
              //   console.log("onOk date: ", dates);
              //   confirm({
              //     closeDropdown: false,
              //   });
              // }}
            />
            {/* <Space>
              <Button
                // onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Reset
              </Button>
              <Button
                type="primary"
                size="small"
                // onClick={() => {
                //   confirm({
                //     closeDropdown: true,
                //   });
                // }}
              >
                Filter
              </Button>
            </Space> */}
            <Button
              type="primary"
              block
              size="small"
              onClick={() => exportToCSV()}
            >
              Export To CSV
            </Button>
          </Space>
        </div>
      )}
      {missions && (
        <div className="table-responsive">
          {/* <DeliveryJobsList Jobs={jobs}></DeliveryJobsList> */}
          <Form form={form} component={false}>
            <Table
              // components={{
              //   body: {
              //     cell: EditableCell,
              //   },
              // }}
              loading={isPending}
              columns={mergedColumns}
              expandable={{
                expandedRowRender,
                // defaultExpandedRowKeys: ["id"],
                expandRowByClick: false,
                rowExpandable: isMissionRowExpandable,
              }}
              dataSource={missions}
              size="small"
              rowKey="mission_id"
              onChange={tableMissions_onChange}
              filteredValue={missionFilteredValue}
              pagination={{
                onChange: cancelEditing,
              }}
            />
          </Form>
        </div>
      )}
      <div className="table-responsive"></div>
    </div>
  );
};

export default MissionsDashboardUser;
