const LEVEL_DEBUG = 3;
const LEVEL_INFO = 2;
const LEVEL_ERROR = 1;
const LEVEL_OFF = 0;

const LOG_LEVEL = LEVEL_DEBUG;

const logWithTimestamp = (level, msg, ...optionalParams) => {
  const date = new Date();
  const options = {
    timeZone: "Asia/Seoul",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    // additional custom format options
    // hourCycle: 'h11', // for 12-hour format with "AM" or "PM"
    // hourCycle: 'h23', // for 24-hour format without leading zeros
    hourCycle: "h24", // for 24-hour format with leading zeros
    // timeZoneName: 'short', // to display the time zone abbreviation
  };
  const timeString =
    date.toLocaleTimeString("en-US", options) + ":" + date.getMilliseconds();

  console.log("[" + level + "] " + timeString + " - " + msg, optionalParams);
};

const write_log = (level, msg, ...optionalParams) => {
  logWithTimestamp(level, msg, ...optionalParams);
  // console.log("[" + level + "] " + msg, optionalParams);
};
export const log_debug = (msg, ...optionalParams) => {
  if (LOG_LEVEL < LEVEL_DEBUG) return;
  write_log("DEBUG", msg, optionalParams);
};

export const log_info = (msg, ...optionalParams) => {
  if (LOG_LEVEL < LEVEL_INFO) return;
  write_log("INFO", msg, optionalParams);
};
