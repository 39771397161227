import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { PATH_TO_WARNING_AUDIO } from "../../common/setting";
import { syntheticVideoStream } from "../../utils/graph";
import { useRef } from "react";
import { Col, message, Layout, Row, Space, Switch, Collapse } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";

import { LockOutlined, CheckOutlined } from "@ant-design/icons";
import RobotStatusViewer from "../../components/status-ctx/robot-status";
import TwistKeyboardMoveCommander from "../components/TwistKeyboardMoveCommander";
import GamepadController from "../../components/joystick/JoystickController";
import { useRobotPeerConnection } from "../../contexts/RobotConnectionContext";
import { useServerConnection } from "../../contexts/ServerConnectionContext";
import VideoTrackViewerNew from "../../components/video-viewer/VideoTrackViewerNew";

const { Panel } = Collapse;

const Demobot1TeleControlView = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const { id } = useParams();

  const {
    isOpen: signal_ready,
    isConnecting,
    message: signal_message,
    sendMessage: signal_send,
    subscribe,
    error: signal_Error,
    reconnectCountDown,
  } = useServerConnection();

  const localStreamRef = useRef(null);

  const audioAlarm = useRef(null);

  const {
    setRobotId,
    connectionState: rtcConnectionState,
    mediaTracks,
    channelMessage,
    channelSend,
    connect,
    // close: closePeerConnection,
    error,
    robotOnlineStatus,
    addChannelMessageReceivedEventListener,
  } = useRobotPeerConnection();

  const [robotStatus, setRobotStatus] = useState(null);
  const [robotErrorInfo, setRobotErrorInfo] = useState(null);
  const [missionStatus, setMissionStatus] = useState(null);

  const send_command_msg_to_robot = (cmd) => {
    let msg_cmd = JSON.stringify(cmd);

    const channelLabel = "chat";

    channelSend(channelLabel, msg_cmd);
    console.log("command has been sent:", msg_cmd);
  };

  useEffect(() => {
    setRobotId(id);
    const unlisten =
      addChannelMessageReceivedEventListener(handleChannelMessage);
    return () => {
      unlisten();
    };
  }, [id]);

  const handleChannelMessage = (channelMessage) => {
    console.debug("received message from robot: ", channelMessage);
    if (channelMessage.channel == "chat") {
      const data = JSON.parse(channelMessage.data);
      if ("topic" in data) {
        const topic = data.topic;
        const topic_data = data.value;
        if (topic == "/tracer_status") {
          const errorInfo =
            topic_data?.fault_code != undefined &&
            topic_data.fault_code != "undefined" &&
            topic_data.fault_code != 0
              ? { error_code: topic_data.fault_code }
              : null;
          const robotInfo = {
            error_info: errorInfo,
            battery: {
              battery_level: topic_data.battery_percentage,
              now_charging: null,
            },
            manual_mode: topic_data.control_mode,
            velo: {
              velo_dx: topic_data.linear_velocity,
              velo_dy: topic_data.angular_velocity,
              velo_dz: null,
            },
            connection_info: { count: null, user_ids: [] },
          };

          setRobotErrorInfo(errorInfo);
          setRobotStatus(robotInfo);
        }
      } else {
        const msg_type = data["msg_type"];

        if (msg_type == "robot_state") {
          const msg_body = data["msg_body"];
          let robotInfo = msg_body["common_info"];
          const errorInfo = msg_body["error_info"];
          const missionInfo = msg_body["mission_info"];
          robotInfo["error_info"] = errorInfo;
          const connectionInfo = msg_body["connection_info"];
          if (connectionInfo != null) {
            robotInfo["connection_info"] = msg_body["connection_info"];
          }
          // console.log("robotInfo: ", robotInfo);
          // TODO: check map

          setRobotErrorInfo(errorInfo);
          setRobotStatus(robotInfo);
          setMissionStatus(missionInfo);
        }
      }
    }
  };

  const onJitterGraphInitCompleted = () => {
    if (localStreamRef.current) {
      console.log("Stop localStream");
      localStreamRef.current.getTracks().forEach(function (track) {
        track.stop();
      });
      localStreamRef.current = null;
    }
  };

  const playAlarm = (play) => {
    const audio = audioAlarm.current;
    if (play) audio.play();
    else audio.pause();
  };

  useEffect(() => {
    if (!robotErrorInfo) playAlarm(false);
    else playAlarm(true);

    // console.log("Error Info: ", robotErrorInfo);
  }, [robotErrorInfo]);

  const [isRobotControlEnabled, setRobotControlEnabled] = useState(true);

  const handleOnCommandCreated = (command, quite = false) => {
    console.log("Created command: ", command);
    if (channelSend == null) {
      alert("data channel is null!");
      return;
    }

    const topic_msg = { topic: "cmd_vel", value: command };
    let cmd_message = JSON.stringify(topic_msg);

    const channelLabel = "chat";

    channelSend(channelLabel, cmd_message);

    console.log("Quite: ", quite);
    if (!quite) {
      messageApi.open({
        type: "info",
        content: "Command has been sent to robot!",
        style: {
          marginTop: "10vh",
        },
      });
    }

    console.log("command has been sent:", cmd_message);
  };

  const handleOnJoystickCommandCreated = (joyMsg, quite = false) => {
    console.log("sending joy to robot: ", joyMsg);
    if (channelSend == null || rtcConnectionState != "connected") {
      messageApi.open({
        type: "error",
        content: "Connection is not ready!",
        style: {
          marginTop: "10vh",
        },
      });
      return;
    }

    const topic_msg = { topic: "/joy", value: joyMsg };
    let cmd_message = JSON.stringify(topic_msg);

    const channelLabel = "chat";

    channelSend(channelLabel, cmd_message);

    console.log("Quite: ", quite);
    if (!quite) {
      messageApi.open({
        type: "info",
        content: "Command has been sent to robot!",
        style: {
          marginTop: "10vh",
        },
      });
    }

    console.log("command send:", cmd_message);
  };

  const robotInfo = { robot_id: id, icon: "/images/tracer.png", name: id };
  const connectionsStatus = {
    ServerConnectionStatus: signal_ready ? "connected" : "disconnected",
    PeerConnectionStatus: rtcConnectionState,
    RobotOnlineStatus: robotOnlineStatus,
  };

  return (
    <Layout>
      <Content>
        <div style={{ height: "100%", maxHeight: "100vh" }}>
          {contextHolder}
          <Row>
            <Col span={24}>
              <RobotStatusViewer
                RobotStatus={robotStatus}
                RobotInfo={robotInfo}
                ConnectionStatus={connectionsStatus}
                MissionInfo={missionStatus}
              ></RobotStatusViewer>
            </Col>
          </Row>

          <Row>
            <Col span={22}>
              <Space>
                {/* <Checkbox>Show command panel </Checkbox> */}
                Show control:
                <Switch
                  onChange={(checked) => {
                    setRobotControlEnabled(checked);
                  }}
                  // checkedChildren={<UnlockOutlined />}
                  unCheckedChildren={<LockOutlined />}
                  checkedChildren={<CheckOutlined />}
                  // unCheckedChildren={<CloseOutlined />}
                  defaultChecked
                  checked={isRobotControlEnabled}
                />
              </Space>
              <audio
                ref={audioAlarm}
                id="alarm-sound"
                src={PATH_TO_WARNING_AUDIO}
                loop
              ></audio>
            </Col>
          </Row>
          <Row
          // gutter={16}
          >
            <Col span={24}>
              <div id="media" className={robotErrorInfo ? "blinking-div" : ""}>
                <VideoTrackViewerNew
                  mediaTracks={mediaTracks}
                  ShowDelayTime={true}
                  CameraStyle={{ height: "100%", maxHeight: "65vh" }}
                ></VideoTrackViewerNew>
              </div>
              {/* <div>
              <div>
                <SpotMoveCommander
                  onMoveCommand={handleMoveCommand}
                ></SpotMoveCommander>
              </div>
            </div> */}
            </Col>
          </Row>
        </div>
      </Content>
      <Sider
        width={450}
        theme="light"
        // trigger={
        //   !isRobotControlEnabled ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
        // }
        // zeroWidthTriggerStyle={zeroWidthTriggerStyle}
        collapsible
        collapsed={!isRobotControlEnabled}
        onCollapse={(value) => setRobotControlEnabled(!value)}
        breakpoint="md"
        collapsedWidth="0"
      >
        <Collapse defaultActiveKey={0}>
          <Panel header="Move control" key="0">
            <TwistKeyboardMoveCommander
              EnableKeyboard={true}
              //   visible={isRobotControlEnabled}
              //   onMoveCommand={handleMoveCommand}
              OnCommandCreated={handleOnCommandCreated}
            ></TwistKeyboardMoveCommander>

            <GamepadController
              // disabled={rtcConnectionState != "connected"}
              onGamepadStateUpdated={(joyMsg) => {
                handleOnJoystickCommandCreated(joyMsg, true);
              }}
              // debug={true}
              showVirtualJoystick={true}
            ></GamepadController>
          </Panel>
        </Collapse>
      </Sider>
    </Layout>
  );
};

export default Demobot1TeleControlView;
