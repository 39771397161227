import { Progress, Space, Tooltip } from "antd";
import { Fragment } from "react";
import { red, green } from "@ant-design/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThunderboltOutlined } from "@ant-design/icons";

import {
  faBatteryFull,
  faBatteryEmpty,
  faPlug,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
} from "@fortawesome/free-solid-svg-icons";

const BatteryIcon = (props) => {
  const level = props.Level;
  const charging = props.Charging;

  const getBatteryIcon = (batteryLevel, isCharging) => {
    if (batteryLevel < 25) return faBatteryQuarter;
    else if (batteryLevel < 75) return faBatteryHalf;
    else if (batteryLevel < 90) return faBatteryThreeQuarters;
    else return faBatteryFull;
  };

  const getColor = (batteryLevel, isCharging) => {
    if (batteryLevel < 25) return "red";
    else if (batteryLevel < 50) return "#EFAF13";
    else return "green";
  };

  return (
    <Space style={{ color: getColor(level, charging) }}>
      <FontAwesomeIcon icon={getBatteryIcon(level, charging)} />
      {/* <Progress percent={level} size={[300, 20]} /> */}(
      {level == null ? (
        <Tooltip title="Unknown battery level">... </Tooltip>
      ) : (
        level + "%"
      )}
      {charging && (
        <FontAwesomeIcon icon={true ? faPlug : faBatteryEmpty} />
        // <ThunderboltOutlined
        // style={{ color: "red" }}
        // />
      )}
      )
    </Space>
  );
};

export default BatteryIcon;
