export const robotMissionsDataReducer = (state, action) => {
  switch (action.type) {
    case "SET_MISSIONLIST": {
      return { ...state, missionsList: [...action.payload] };
    }

    case "SET_ROBOT_CONNECTED": {
      const robot_id = action.payload.id;
      const index = state.robotList.findIndex((rb) => {
        return rb.id == robot_id;
      });
      let updatedRobotList;
      if (index == -1) {
        updatedRobotList = [...state.robotList, action.payload];
      } else {
        updatedRobotList = [...state.robotList];
        updatedRobotList[index] = action.payload;
      }
      return {
        ...state,
        robotList: updatedRobotList,
      };
    }

    case "SET_ROBOT_DISCONNECTED": {
      const robot_id = action.payload.id;
      const index = state.robotList.findIndex((rb) => {
        return rb.id == robot_id;
      });
      if (index != -1) {
        return {
          ...state,
          robotList: state.robotList.splice(index, 1),
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export const initialMissionsData = {
  missionsList: [],
};
