import React, { Component, useContext, useEffect } from "react";
import useFetchState from "../utils/useFetchState";
import {
  AutoComplete,
  Badge,
  Button,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { getFetchURL } from "../common/setting";
import {
  DownOutlined,
  CloseCircleTwoTone,
  DiffTwoTone,
  DeleteTwoTone,
  EditTwoTone,
} from "@ant-design/icons";
import { useState } from "react";
import {
  add_new_mission_log_data,
  deleteMissionService,
  update_mission_data_to_server,
} from "../services/mission-services";
import moment from "moment";
import EditableTable from "../components/common/EditableTable";
// import { get_missions_data } from "./db_missions_temp";

const { Panel } = Collapse;

const validateDatetimeString = (rule, value, callback) => {
  if (!value) {
    callback();
  } else if (!moment(value, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
    callback('Invalid datetime format. Please use "YYYY-MM-DD HH:mm:ss"');
  } else {
    callback();
  }
};

function downloadJsonFile(objectData, filename) {
  var jsonData = JSON.stringify(objectData, null, 2);
  var blob = new Blob([jsonData], { type: "application/json" });
  var url = URL.createObjectURL(blob);

  var a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  URL.revokeObjectURL(url);
}

const getMissionEventsLogJsonObject = (mission) => {
  // alert("Download Mission File " + missionItem.mission_id);
  console.log("mission logs: ", mission);
  const mission_jobs = mission.mission_jobs.map((job) => {
    return {
      job_id: job.job_id,
      job_type: job.job_description.job_type,
      job_name: job.job_description.job_name,
      job_desc: job.job_description.job_desc,
      start_time: job.start_time,
      end_time: job.end_time,
      status: job.status,
      events_log: job.events_log,
    };
  });

  const mission_log = { ...mission, mission_jobs: mission_jobs };

  return mission_log;
};

const getMissionJsonObject = (mission) => {
  // alert("Download Mission File " + missionItem.mission_id);
  console.log("mission: ", mission);
  const mission_Jobs = mission.mission_jobs.map((job) => {
    return {
      job_id: job.job_id,
      job_description: job.job_description,
    };
  });
  const mission_desc = {
    mission_id: mission.mission_id,
    mission_type: mission.mission_type,
    mission_name: mission.mission_name,
    description: mission.description,
    mission_jobs: mission_Jobs,
  };
  return mission_desc;
};

const downloadMissionFile = (mission) => {
  const obj = getMissionJsonObject(mission);
  downloadJsonFile(obj, obj["mission_id"] + ".mission");
};

const downloadMissionLogFile = (mission) => {
  const logObj = getMissionEventsLogJsonObject(mission);
  downloadJsonFile(logObj, mission.mission_id + "_EventsLog.json");
};

// const filters_job_status = [
//   {
//     text: "Submitted",
//     value: "Submitted",
//   },
//   {
//     text: "Rejected",
//     value: "Rejected",
//   },
//   {
//     text: "Waiting",
//     value: "Waiting",
//   },
//   {
//     text: "Started",
//     value: "Started",
//   },
//   {
//     text: "Finished",
//     value: "Finished",
//   },
// ];

const filters_job_result = [
  {
    text: "finished",
    value: "finished",
  },
  {
    text: "cancelled",
    value: "cancelled",
  },
  {
    text: "started",
    value: "started",
  },
  {
    text: "pending",
    value: "pending",
  },
  // {
  //   text: "Error",
  //   value: "Error",
  // },
];

const job_result_items = filters_job_result.map((item) => {
  return { label: item.text, value: item.value };
});

// const job_status_items = filters_job_status.map((item) => {
//   return { label: item.text, value: item.value };
// });

const dictTaskStatus = {
  success: "success",
  failure: "error",
  finished: "success",
  pending: "processing",
  started: "processing",
};
const robotIds = [
  { robot_id: "gocart180_1", robot_type: "gocart" },
  { robot_id: "gocart180_2", robot_type: "gocart" },
  { robot_id: "gocart180_3", robot_type: "gocart" },
  { robot_id: "gocart180_4", robot_type: "gocart" },
  { robot_id: "syscon_1", robot_type: "syscon" },
  { robot_id: "syscon_2", robot_type: "syscon" },
  { robot_id: "syscon_3", robot_type: "syscon" },
  { robot_id: "coffee_1", robot_type: "mint_coffee" },
  { robot_id: "food_1", robot_type: "food_robot" },
  { robot_id: "wearable_1", robot_type: "wearable_upper" },
  { robot_id: "wearable_2", robot_type: "wearable_upper" },
  { robot_id: "wearable_3", robot_type: "wearable_upper" },
  { robot_id: "wearable_4", robot_type: "wearable_lower" },
  { robot_id: "wearable_5", robot_type: "wearable_lower" },
  { robot_id: "wearable_6", robot_type: "wearable_lower" },
  { robot_id: "res_1", robot_type: "any_robot" },
  { robot_id: "res_2", robot_type: "any_robot" },
  { robot_id: "res_3", robot_type: "any_robot" },
  { robot_id: "res_4", robot_type: "any_robot" },
  { robot_id: "res_5", robot_type: "any_robot" },

  { robot_id: "Spot_01", robot_type: "spot" },
];
const robotIdOptions = robotIds.map((item) => {
  return {
    label: item.robot_id,
    value: item.robot_id,
    robot_type: item.robot_type,
  };
});

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  onValueChange,
  ...restProps
}) => {
  const inputNode =
    dataIndex === "robot_id" ? (
      <AutoComplete
        options={robotIdOptions}
        placeholder="Robot"
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      ></AutoComplete>
    ) : dataIndex === "status" ? (
      <AutoComplete
        options={job_result_items}
        placeholder="Robot"
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onChange={(val) => {
          if (onValueChange != null) {
            onValueChange({ dataIndex: dataIndex, value: val });
          }
        }}
      />
    ) : (
      <Input
        onChange={(val) => {
          if (onValueChange != null) {
            onValueChange({ dataIndex: dataIndex, value: val.target.value });
          }
        }}
      />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={
            dataIndex == "create_time"
              ? [
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                  {
                    validator: validateDatetimeString,
                  },
                ]
              : dataIndex == "start_time" || dataIndex == "end_time"
              ? [
                  {
                    required: false,
                  },
                  {
                    validator: validateDatetimeString,
                  },
                ]
              : [
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                ]
          }
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const MissionsDashboardAdmin = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.mission_id === editingKey;
  const [editingMission, setEditingMission] = useState(null);
  const [isCloning, setIsCloning] = useState(false);
  const [missionsPagination, setMissionsPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [currentDataSource, setCurrentDataSource] = useState(null);

  const [sortedInfo, setSortedInfo] = useState({
    column: null,
    order: "descend",
    field: null,
    columnKey: "id",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const displayNotification = (type, msg) => {
    messageApi.open({
      type: type,
      content: msg,
      style: {
        marginTop: "5vh",
      },
    });
  };

  const edit = (record) => {
    form.setFieldsValue({
      // Status: "",
      // Result: "",
      // Events: "",
      ...record,
    });
    setEditingKey(record.mission_id);
    setEditingMission(record);
  };

  const cloneMission = (record) => {
    const clonedRecord = { ...record };
    clonedRecord.mission_id = clonedRecord.mission_id + "(1)";
    const newData = [...missions];
    const index = newData.findIndex(
      (item) => record.mission_id === item.mission_id
    );
    newData.splice(index, 0, clonedRecord);
    setMissionsData(newData);

    form.setFieldsValue({
      // Status: "",
      // Result: "",
      // Events: "",
      ...clonedRecord,
    });
    setEditingKey(clonedRecord.mission_id);
    setEditingMission(clonedRecord);
    setIsCloning(true);
  };

  const newMission = (record) => {
    const newBlankMission = {
      ...record,
      mission_id: record.mission_id + "(1)",
      robot_id: "",
      mission_type: "",
      mission_name: "",
      description: "",
      create_time: "",
      start_time: "",
      end_time: "",
      status: "",
      mission_jobs: [],
      events_log: [],
    };

    const newData = [...missions];
    const index = newData.findIndex(
      (item) => record.mission_id === item.mission_id
    );
    newData.splice(index, 0, newBlankMission);
    setMissionsData(newData);

    form.setFieldsValue({
      // Status: "",
      // Result: "",
      // Events: "",
      ...newBlankMission,
    });
    setEditingKey(newBlankMission.mission_id);
    setEditingMission(newBlankMission);
    setIsCloning(true);
  };

  const cancelEditing = (currentPage, pagesize) => {
    if (isCloning) {
      const newData = missions.filter((item) => editingKey !== item.mission_id);
      setMissionsData(newData);
    }
    setEditingKey("");
    setEditingMission(null);
    setIsCloning(false);
  };

  const cancel = () => {
    if (isCloning) {
      // Delete editing from missions
      const newData = missions.filter((item) => editingKey !== item.mission_id);
      setMissionsData(newData);
    }
    setEditingKey("");
    setEditingMission(null);
    setIsCloning(false);
  };

  const updateMissionLogsTime = async (mission, create_time_str) => {
    const updated_mission = { ...mission };
    const created_time_old = moment(updated_mission.create_time);
    const created_time_new = moment(create_time_str);
    const diff = created_time_new.diff(created_time_old, "seconds");
    if (updated_mission.start_time != null) {
      const new_start_time = moment(updated_mission.start_time)
        .add(diff, "second")
        .format("YYYY-MM-DD hh:mm:ss");
      updated_mission.start_time = new_start_time;
    }
    if (updated_mission.end_time != null) {
      const end_time = moment(updated_mission.end_time)
        .add(diff, "second")
        .format("YYYY-MM-DD hh:mm:ss");
      updated_mission.end_time = end_time;
    }
    for (let i = 0; i < updated_mission.mission_jobs.length; i++) {
      const job = updated_mission.mission_jobs[i];
      if (job.start_time != null) {
        job.start_time = moment(job.start_time)
          .add(diff, "second")
          .format("YYYY-MM-DD hh:mm:ss");
      }
      if (job.end_time != null) {
        job.end_time = moment(job.end_time)
          .add(diff, "second")
          .format("YYYY-MM-DD hh:mm:ss");
      }

      for (
        let iJob = 0;
        job.events_log != null && iJob < job.events_log.length;
        iJob++
      ) {
        const jobEvent = job.events_log[iJob];
        jobEvent.timestamp = moment(jobEvent.timestamp)
          .add(diff, "second")
          .format("YYYY-MM-DD hh:mm:ss");

        if (diff != 0) {
          delete jobEvent.data.screenshot;
          delete jobEvent.data.meta_log_file;
          delete jobEvent.data.image;
          delete jobEvent.data.mission_log;
        }
      }
    }
    // update mission events
    for (
      let i = 0;
      updated_mission.events_log != null &&
      i < updated_mission.events_log.length;
      i++
    ) {
      const mEvent = updated_mission.events_log[i];
      mEvent.timestamp = moment(mEvent.timestamp)
        .add(diff, "second")
        .format("YYYY-MM-DD hh:mm:ss");

      if (diff != 0) {
        delete mEvent.data.screenshot;
        delete mEvent.data.meta_log_file;
        delete mEvent.data.image;
        delete mEvent.data.mission_log;
      }
    }
    return updated_mission;
  };

  const updateMissionRobotId = async (mission, robot_id) => {
    const updated_mission = { ...mission, robot_id: robot_id };
    for (let i = 0; i < updated_mission.mission_jobs.length; i++) {
      const job = updated_mission.mission_jobs[i];
      for (
        let iJob = 0;
        job.events_log != null && iJob < job.events_log.length;
        iJob++
      ) {
        const jobEvent = job.events_log[iJob];

        const source_name = jobEvent.event_source.toLowerCase();
        if (
          source_name.startsWith("spot") ||
          source_name.startsWith("gocart") ||
          source_name.startsWith("robot")
        ) {
          jobEvent.event_source = robot_id;
        }
      }
    }
    // update mission events
    for (
      let i = 0;
      updated_mission.events_log != null &&
      i < updated_mission.events_log.length;
      i++
    ) {
      const mEvent = updated_mission.events_log[i];

      const source_name = mEvent.event_source.toLowerCase();
      if (
        source_name.startsWith("spot") ||
        source_name.startsWith("gocart") ||
        source_name.startsWith("robot")
      ) {
        mEvent.event_source = updated_mission.robot_id;
      }
    }

    return updated_mission;
  };

  const generateMissionData = async () => {
    const row = await form.validateFields();

    const str_created_time_old = editingMission.create_time;
    const str_created_time_new = row.create_time;
    const created_time_old = moment(str_created_time_old);
    const created_time_new = moment(str_created_time_new);

    const diff = created_time_new.diff(created_time_old, "seconds");

    const updated_mission = {
      ...editingMission,
      ...row,
    };

    if (diff != 0 && updated_mission.start_time != null) {
      const new_start_time = moment(updated_mission.start_time)
        .add(diff, "second")
        .format("YYYY-MM-DD hh:mm:ss");
      updated_mission.start_time = new_start_time;
    }
    if (diff != 0 && updated_mission.end_time != null) {
      const end_time = moment(updated_mission.end_time)
        .add(diff, "second")
        .format("YYYY-MM-DD hh:mm:ss");
      updated_mission.end_time = end_time;
    }

    for (let i = 0; i < updated_mission.mission_jobs.length; i++) {
      const job = updated_mission.mission_jobs[i];
      if (diff != 0 && job.start_time != null) {
        job.start_time = moment(job.start_time)
          .add(diff, "second")
          .format("YYYY-MM-DD hh:mm:ss");
      }
      if (diff != 0 && job.end_time != null) {
        job.end_time = moment(job.end_time)
          .add(diff, "second")
          .format("YYYY-MM-DD hh:mm:ss");
      }

      for (
        let iJob = 0;
        job.events_log != null && iJob < job.events_log.length;
        iJob++
      ) {
        const jobEvent = job.events_log[iJob];
        jobEvent.timestamp = moment(jobEvent.timestamp)
          .add(diff, "second")
          .format("YYYY-MM-DD hh:mm:ss");

        if (diff != 0) {
          delete jobEvent.data.screenshot;
          delete jobEvent.data.meta_log_file;
          delete jobEvent.data.image;
          delete jobEvent.data.mission_log;
        }

        const source_name = jobEvent.event_source.toLowerCase();
        if (
          source_name.startsWith("spot") ||
          source_name.startsWith("gocart") ||
          source_name.startsWith("robot")
        ) {
          jobEvent.event_source = updated_mission.robot_id;
        }
      }
    }
    // update mission events
    for (
      let i = 0;
      updated_mission.events_log != null &&
      i < updated_mission.events_log.length;
      i++
    ) {
      const mEvent = updated_mission.events_log[i];
      mEvent.timestamp = moment(mEvent.timestamp)
        .add(diff, "second")
        .format("YYYY-MM-DD hh:mm:ss");

      if (diff != 0) {
        delete mEvent.data.screenshot;
        delete mEvent.data.meta_log_file;
        delete mEvent.data.image;
        delete mEvent.data.mission_log;
      }
      const source_name = mEvent.event_source.toLowerCase();
      if (
        source_name.startsWith("spot") ||
        source_name.startsWith("gocart") ||
        source_name.startsWith("robot")
      ) {
        mEvent.event_source = updated_mission.robot_id;
      }
    }

    setEditingMission(updated_mission);
  };

  const saveCloningMission = async (key) => {
    try {
      console.log("editing key: ", key);
      const row = await form.validateFields();

      console.log("Edited row: ", row);

      const newData = [...missions];
      const index = newData.findIndex((item) => editingKey === item.mission_id);
      const mission = editingMission;
      console.log("Editing mission: ", mission);

      const newMission = {
        ...mission,
        ...row,
      };
      add_new_mission_log_data(newMission)
        .then((added_mission) => {
          if (added_mission == null) {
            // alert("Job update failed!\nmission_id: " + updated_item.mission_id);
            displayNotification(
              "error",
              "Mission adding failed!\nmission_id: " + newMission.mission_id
            );
          } else {
            newData.splice(index, 1, newMission);
            setMissionsData(newData);
            setEditingKey("");
            setEditingMission(null);
            setIsCloning(false);
            // alert("Job update succeed!\nmission_id: " + updated_Job.mission_id);
            displayNotification(
              "success",
              "Mission add succeed!\nmission_id: " + added_mission.mission_id
            );
          }
        })
        .catch((err) => {
          if (err.name === "AbortError") {
          } else {
            alert("Error: " + err.message);
          }
        });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const saveEditMission = async (key) => {
    try {
      console.log("editing key: ", key);
      const row = await form.validateFields();

      console.log("Edited row: ", row);

      const newData = [...missions];
      const index = newData.findIndex((item) => key === item.mission_id);
      if (index > -1) {
        const mission = newData[index];
        console.log("Editing mission: ", mission);

        const updated_item = {
          ...editingMission,
          ...row,
        };
        update_mission_data_to_server(updated_item)
          .then((updated_Job) => {
            if (updated_Job == null) {
              // alert("Job update failed!\nmission_id: " + updated_item.mission_id);
              displayNotification(
                "error",
                "Mission update failed!\nmission_id: " + updated_item.mission_id
              );
            } else {
              newData.splice(index, 1, updated_item);
              setMissionsData(newData);
              setEditingKey("");
              // alert("Job update succeed!\nmission_id: " + updated_Job.mission_id);
              displayNotification(
                "success",
                "Mission update succeed!\nmission_id: " + updated_Job.mission_id
              );
            }
          })
          .catch((err) => {
            if (err.name === "AbortError") {
            } else {
              alert("Error: " + err.message);
            }
          });
      } else {
        // newData.push(row);
        // setJobsData(newData);

        setEditingKey("");
        setEditingMission(null);
        setIsCloning(false);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  // const {
  //   data: presets,
  //   error: isPresetsLoadingError,
  //   isPending: isPresetsLoading,
  //   setData: setPresetData,
  // } = useFetchState(getFetchURL("/robot-job-presets"));

  const {
    data: missions,
    error,
    isPending,
    setData: setMissionsData,
  } = useFetchState(getFetchURL("/robot-missions"));

  // const [missions, setMissionsData] = useState(get_missions_data());
  // const error = null;
  // const isPending = false;

  // console.log("Missions: ", missions);

  const [filtersMissionStatusItems, setFiltersMissionStatusItems] = useState(
    []
  );
  const [filtersMissionNamesItems, setFiltersMissionNamesItems] = useState([]);

  const [filtersRobotItems, setFiltersRobotItems] = useState([]);

  const deleteMission = async (mission) => {
    console.log("Delete mission: ", mission.mission_id);
    try {
      const response = await deleteMissionService(mission.mission_id);

      if (response.status === 200) {
        const deletedMissions = response.data;
        console.log("Delete mission succeed: ", deletedMissions);
        displayNotification("success", "Deleted missions:\n" + deletedMissions);
      } else {
        console.log("Delete mission error: ", response.data);
        displayNotification(
          "error",
          "Deleted missions:\n" + response.data.message
        );
      }
    } catch (err) {
      console.log("Delete mission error: ", err);
      displayNotification("error", "Deleted error:\n" + err);
    }
  };

  const itemsMissionActions = (missionItem) => {
    return [
      {
        key: "1",
        label: "Download Mission File",
        onClick: () => {
          downloadMissionFile(missionItem);
        },
        url: "/mission-file/" + missionItem.mission_id,
      },
      {
        key: "2",
        label: "Download Mission Log",
        onClick: () => {
          downloadMissionLogFile(missionItem);
        },
        url: "/mission-log/" + missionItem.mission_id,
      },
      // {
      //   key: "3",
      //   label: "Delete Mission",
      //   onClick: () => {
      //     deleteMission(missionItem);
      //   },
      //   // url: "/mission-log/" + missionItem.mission_id,
      // },
    ];
  };

  useEffect(() => {
    if (missions == null) return;

    console.log("Jobs size: ", missions.length);

    let missionStatusSet = new Set();

    let robotsSet = new Set();
    let missionNamesSet = new Set();

    for (const mission of missions) {
      robotsSet.add(mission.robot_id);
      missionStatusSet.add(mission.status);
      missionNamesSet.add(mission.mission_name);
    }

    const missionStatusFilters = [];
    for (const status of missionStatusSet) {
      missionStatusFilters.push({ text: status, value: status });
    }

    setFiltersMissionStatusItems(missionStatusFilters);

    const missionNamesFilters = [];
    for (const missionName of missionNamesSet) {
      missionNamesFilters.push({ text: missionName, value: missionName });
    }

    setFiltersMissionNamesItems(missionNamesFilters);

    // console.log("set size: ", set);

    const robotFilters = [];
    for (const robotId of robotsSet) {
      robotFilters.push({ text: robotId, value: robotId });
    }

    // console.log("Robot filters list: ", robotFilters);

    setFiltersRobotItems(robotFilters);
  }, [missions]);

  const handleMissionJobDeleted = (mission_id, oldRecord) => {
    console.log("handleMissionEventLogDeleted: ", mission_id, oldRecord);

    const index = editingMission.mission_jobs.findIndex((item) => {
      return oldRecord.job_id === item.job_id;
    });
    const newMissionJobs = editingMission.mission_jobs.filter(
      (_, i) => i !== index
    );

    const newEdittingMission = {
      ...editingMission,
      mission_jobs: newMissionJobs,
    };
    setEditingMission(newEdittingMission);
  };

  const handleMissionJobAddRecord = (mission_id) => {
    console.log("create new Job for mission: ", mission_id);
    const newJobRecord = {
      job_id: editingMission.mission_jobs?.length ?? 0,
      job_description: {
        job_type: "Move",
        job_name: "Move",
        job_desc: "Move",
        job_params: {},
      },
      start_time: "2023-06-01 14:00:00",
      end_time: "2023-06-01 14:00:00",
      status: "finished",
      events_log: [],
    };
    const newMissionJobs = [
      ...(editingMission?.mission_jobs ?? []),
      newJobRecord,
    ];

    const newEdittingMission = {
      ...editingMission,
      mission_jobs: newMissionJobs,
    };
    setEditingMission(newEdittingMission);
  };

  const handleMissionJobRecordEdited = (mission_id, oldRecord, newRecord) => {
    console.log(
      "handle Mission Job Record Edited: ",
      mission_id,
      oldRecord,
      newRecord
    );
    const newMissionJobs = [...editingMission.mission_jobs];
    const index = newMissionJobs.findIndex((item) => {
      return oldRecord.job_id === item.job_id;
    });
    newMissionJobs.splice(index, 1, newRecord);

    const newEdittingMission = {
      ...editingMission,
      newRecord: newMissionJobs,
    };
    setEditingMission(newEdittingMission);
  };

  const handleMissionEventLogDeleted = (mission_id, oldRecord) => {
    console.log("handleMissionEventLogDeleted: ", mission_id, oldRecord);

    const index = editingMission.events_log.findIndex((item) => {
      return (
        oldRecord.timestamp === item.timestamp && oldRecord.data === item.data
      );
    });
    const newEventsLogs = editingMission.events_log.filter(
      (_, i) => i !== index
    );

    const newEdittingMission = { ...editingMission, events_log: newEventsLogs };
    setEditingMission(newEdittingMission);
  };

  const handleMissionEventLogAddRecord = (mission_id) => {
    console.log("create new event log for mission: ", mission_id);
    const newEventLogRecord = {
      timestamp: "2023-06-01 14:10:00",
      event_type: "event",
      event_source: "user",
      data: {},
    };
    const newEventsLogs = [
      ...(editingMission?.events_log ?? []),
      newEventLogRecord,
    ];

    const newEdittingMission = { ...editingMission, events_log: newEventsLogs };
    setEditingMission(newEdittingMission);
  };

  const handleMissionEventLogRecordEdited = (
    mission_id,
    oldRecord,
    newRecord
  ) => {
    console.log(
      "handle Mission EventLog Record Edited: ",
      mission_id,
      oldRecord,
      newRecord
    );
    const newEventsLogs = [...editingMission.events_log];
    const index = newEventsLogs.findIndex((item) => {
      return (
        oldRecord.timestamp === item.timestamp && oldRecord.data === item.data
      );
    });
    newEventsLogs.splice(index, 1, newRecord);

    const newEdittingMission = { ...editingMission, events_log: newEventsLogs };
    setEditingMission(newEdittingMission);
  };

  const getJobEventRowKey = (job_event) => {};

  const expandedMissionRowRender = (recordMission) => {
    const jobsColumns = [
      {
        title: "Job Id",
        dataIndex: "job_id",
        key: "job_id",
      },
      {
        title: "Name",
        dataIndex: "job_description",
        key: "job_name",
        render: (jd) => jd?.job_name ?? "",
        bindingFieldName: "job_name",
        editable: true,
      },
      {
        title: "Description",
        dataIndex: "job_description",
        key: "job_desc",
        render: (jd) => jd?.job_desc,
        bindingFieldName: "job_desc",
        editable: true,
      },
      {
        title: "Start time",
        dataIndex: "start_time",
        key: "start_time",
        editable: true,
      },
      {
        title: "End time",
        dataIndex: "end_time",
        key: "end_time",
        editable: true,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        editable: true,
        // render: (st) => <Badge status={dictTaskStatus[st]} text={st} />,
      },
    ];
    const eventsColumns = [
      {
        title: "Timestamp",
        dataIndex: "timestamp",
        key: "timestamp",
        editable: true,
      },
      {
        title: "Event name",
        dataIndex: "event_type",
        key: "event_type",
        editable: true,
      },
      {
        title: "Event source",
        dataIndex: "event_source",
        key: "event_source",
        editable: true,
      },
      {
        title: "Data",
        dataIndex: "data",
        key: "data",
        editable: true,
        render: (data) => JSON.stringify(data),
      },
    ];
    // console.log("expandedRowRender: ", record.mission_id, record.DeliveryDetail);
    return (
      <Collapse defaultActiveKey={[0]}>
        <Panel header="Jobs" key="0">
          {!isEditing(recordMission) ? (
            <Table
              // title={(record) => "Jobs"}
              columns={jobsColumns}
              dataSource={recordMission["mission_jobs"]}
              expandable={{
                expandedRowRender: expandedJobRowRender,
                // expandedRowRender,
                // defaultExpandedRowKeys: ["id"],
                expandRowByClick: false,
                rowExpandable: jobRowExpandable,
              }}
              pagination={false}
              rowKey="job_id"
              size="small"
            />
          ) : (
            <EditableTable
              dataColumns={jobsColumns}
              dataSource={editingMission["mission_jobs"]}
              expandable={{
                expandedRowRender: expandedJobRowRender,
                // expandedRowRender,
                // defaultExpandedRowKeys: ["id"],
                expandRowByClick: false,
                rowExpandable: jobRowExpandable,
              }}
              pagination={false}
              rowKey="job_id"
              size="small"
              handleDelete={(evtRecord) => {
                handleMissionJobDeleted(recordMission.mission_id, evtRecord);
              }}
              handleAdd={() => {
                handleMissionJobAddRecord(recordMission.mission_id);
              }}
              handleSave={(orgRecord, newRecord) => {
                handleMissionJobRecordEdited(
                  recordMission.mission_id,
                  orgRecord,
                  newRecord
                );
              }}
            />
          )}
        </Panel>
        <Panel header="Mission Events Log" key="1">
          {!isEditing(recordMission) ? (
            <Table
              // title={(record) => "Events Log"}
              columns={eventsColumns}
              dataSource={recordMission["events_log"]}
              pagination={false}
              rowKey="timestamp"
              size="small"
            />
          ) : (
            <EditableTable
              dataColumns={eventsColumns}
              dataSource={editingMission["events_log"]}
              handleDelete={(evtRecord) => {
                handleMissionEventLogDeleted(
                  recordMission.mission_id,
                  evtRecord
                );
              }}
              handleAdd={() => {
                handleMissionEventLogAddRecord(recordMission.mission_id);
              }}
              handleSave={(orgRecord, newRecord) => {
                handleMissionEventLogRecordEdited(
                  recordMission.mission_id,
                  orgRecord,
                  newRecord
                );
              }}
            ></EditableTable>
          )}
        </Panel>
      </Collapse>
    );
  };

  const isMissionRowExpandable = (record) => {
    return (
      (record.mission_jobs != undefined && record.mission_jobs.length > 0) ||
      isEditing(record)
    );
  };

  const expandedJobRowRender = (jobRecord) => {
    const eventsColumns = [
      // {
      //   title: "#",
      //   // dataIndex: "id",
      //   key: "id",
      //   render: (id, jobRecord, index) => {
      //     return index + 1;
      //   },
      //   // sortDirections: ["ascend", "descend"],
      //   // defaultSortOrder: "descend",
      //   // sorter: (a, b, sortOrder) => a > b,
      // },
      {
        title: "Timestamp",
        dataIndex: "timestamp",
        key: "timestamp",
      },
      {
        title: "Event name",
        dataIndex: "event_type",
        key: "event_type",
      },
      {
        title: "Event source",
        dataIndex: "event_source",
        key: "event_source",
      },
      {
        title: "Data",
        dataIndex: "data",
        key: "data",
        render: (data) => JSON.stringify(data),
      },
      {
        title: "action",
        dataIndex: "data",
        key: "data",
        render: (data) => JSON.stringify(data),
      },
    ];
    // console.log("expandedRowRender: ", record.mission_id, record.DeliveryDetail);
    return (
      <Collapse defaultActiveKey={[0]}>
        <Panel header="Job Events Log" key="0">
          <Table
            // title={(record) => "Events Log"}
            columns={eventsColumns}
            dataSource={jobRecord["events_log"]}
            pagination={false}
            rowKey="timestamp"
            size="small"
          />
        </Panel>
      </Collapse>
    );
  };

  const jobRowExpandable = (record) => {
    return record.events_log != undefined && record.events_log.length > 0;
  };

  const onMissionValueChange = async ({ dataIndex, value }) => {
    console.log("Change: ", { dataIndex, value });
    if (dataIndex == "create_time") {
      try {
        const create_time = moment(value, true);
        if (!create_time.isValid()) return;
      } catch (err) {
        return;
      }
      const create_time = moment(value);
      const randomNumber = Math.floor(Math.random() * 999) + 1;
      const miliSecond = randomNumber.toString().padStart(3, "0");

      if (isCloning) {
        const mission_id =
          "M_" + create_time.format("YYYYMMDD_hhmmss_") + miliSecond;
        form.setFieldValue("mission_id", mission_id);
      }
      // const updated_mission = updateMissionLogsTime(editingMission, value);
      // updated_mission.mission_id = mission_id;
      // // form.setFieldsValue({ ...updated_mission });
      //
      // setEditingMission({ ...updated_mission });
    } else if (dataIndex == "robot_id") {
      // const updated_mission = updateMissionRobotId(editingMission, value);
      // setEditingMission(updated_mission);
    }
  };

  const missionColumns = [
    {
      title: "Index",
      dataIndex: "create_time",
      key: "id",
      render: (id, record, index) => {
        let sortedColumn = null;
        if (Array.isArray(sortedInfo)) {
          for (let i = 0; i < sortedInfo.length; i++)
            if (
              sortedInfo[i].columnKey === "id" ||
              sortedInfo[i].columnKey === "create_time"
            ) {
              sortedColumn = sortedInfo[i].order;
              break;
            }
        } else {
          sortedColumn =
            sortedInfo.columnKey === "id" ||
            sortedInfo.columnKey === "create_time"
              ? sortedInfo.order
              : null;
        }

        const rowCount = currentDataSource?.length || missions?.length;
        if (sortedColumn === "descend") {
          return (
            rowCount -
            ((missionsPagination.current - 1) * missionsPagination.pageSize +
              index)
          );
        } else {
          return (
            (missionsPagination.current - 1) * missionsPagination.pageSize +
            index +
            1
          );
        }
      },
      sortDirections: ["descend"],
      defaultSortOrder: "descend",
      sorter: (a, b, sortOrder) => {
        return a["create_time"] > b["create_time"];
      },
    },
    {
      title: "Mission ID",
      dataIndex: "mission_id",
      key: "mission_id",
      // render: (mission_id) => <Link to={"/delivery/" + mission_id}>{mission_id}</Link>,
      // sorter: (a, b) => a > b,
      editable: true,
    },
    {
      title: "Mission name",
      dataIndex: "mission_name",
      key: "mission_name",
      filters: filtersMissionNamesItems,
      onFilter: (value, record) => record.mission_name == value,
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: true,
    },
    // {
    //   title: "User",
    //   dataIndex: "user_id",
    //   key: "user_id",
    // },
    {
      title: "Robot ID",
      dataIndex: "robot_id",
      key: "robot_id",
      filters: filtersRobotItems,
      onFilter: (value, record) => record.robot_id.includes(value),
      editable: true,
    },
    {
      title: "Job counts",
      dataIndex: "mission_jobs",
      key: "jobs",
      render: (jobs) => jobs.length,
    },
    {
      title: "CreateTime",
      dataIndex: "create_time",
      key: "create_time",
      sortDirections: ["descend"],
      defaultSortOrder: "descend",
      sorter: (a, b, sortOrder) => {
        return a["create_time"] > b["create_time"];
      },
      editable: true,
    },
    {
      title: "Start time",
      dataIndex: "start_time",
      key: "start_time",
      editable: true,
    },
    {
      title: "End time",
      dataIndex: "end_time",
      key: "end_time",
      editable: true,
    },
    {
      title: "Final status",
      dataIndex: "status",
      key: "status",
      editable: true,
      filters: filtersMissionStatusItems,
      onFilter: (value, record) => record.status == value,
    },
    // {
    //   title: "Result",
    //   dataIndex: "Result",
    //   key: "Result",
    //   editable: true,
    //   filters: filters_job_result,
    //   onFilter: (value, record) => record.Result == value,
    // },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Typography.Link
              onClick={() =>
                isCloning
                  ? saveCloningMission(record.mission_id)
                  : saveEditMission(record.mission_id)
              }
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>

            {/* <Typography.Link
              onClick={() => generateMissionData()}
              style={{
                marginRight: 8,
              }}
            >
              Generate
            </Typography.Link> */}

            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <Tooltip title={"Edit mission"}>
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                <EditTwoTone />
              </Typography.Link>
            </Tooltip>
            {/* <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => newMission(record)}
            >
              New
            </Typography.Link> */}
            <Tooltip title={"Copy to new mission"}>
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => cloneMission(record)}
              >
                <DiffTwoTone />
              </Typography.Link>
            </Tooltip>
            {/* <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => deleteMission(record)}
            >
              Del
            </Typography.Link> */}
            <Tooltip title={"Delete mission"}>
              <Popconfirm
                disabled={editingKey !== ""}
                title={"Sure to delete mission '" + record.mission_id + "'?"}
                onConfirm={() => deleteMission(record)}
              >
                <DeleteTwoTone />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      render: (op, record, index) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: itemsMissionActions(record),
            }}
          >
            <a>
              Download <DownOutlined />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const missionMergedColumns = missionColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "create_time" ||
          col.dataIndex === "start_time" ||
          col.dataIndex === "end_time"
            ? "DateTime"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        onValueChange: onMissionValueChange,
      }),
    };
  });

  const tableMissions_onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    setMissionsPagination(pagination);
    setSortedInfo(sorter);
    setCurrentDataSource(extra.currentDataSource);
  };

  return (
    <div>
      {contextHolder}
      <h2>Robots Missions</h2>
      {error && <div> {error}</div>}
      {isPending && <div>Loading ...</div>}

      {missions && (
        <div className="table-responsive">
          {/* <DeliveryJobsList Jobs={jobs}></DeliveryJobsList> */}
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={missionMergedColumns}
              expandable={{
                expandedRowRender: expandedMissionRowRender,
                // defaultExpandedRowKeys: ["id"],
                expandRowByClick: false,
                rowExpandable: isMissionRowExpandable,
              }}
              dataSource={missions}
              size="small"
              rowKey="mission_id"
              onChange={tableMissions_onChange}
              pagination={{
                onChange: cancelEditing,
              }}
            />
          </Form>
        </div>
      )}
      <div className="table-responsive"></div>
    </div>
  );
};

export default MissionsDashboardAdmin;
