import React, { createContext, useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useHistory } from "react-router-dom";
import { loginService } from "../services/auth-services/loginService";

const AuthContext = createContext();

const PREV_AUTHDATA_KEYNAME = "authData";
const AUTHDATA_KEYNAME = "authData2";

const PREV_TOKEN_KEYNAME = "token";
const TOKEN_KEYNAME = "token2";

export const AuthProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState("profile");

  const [loginCredential, setLoginCredential] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  const getAuthData = () => {
    try {
      // TODO: fix me. Trick to remove previous unvalid token
      localStorage.removeItem(PREV_TOKEN_KEYNAME);
      localStorage.removeItem(PREV_AUTHDATA_KEYNAME);

      const authDataString = localStorage.getItem(AUTHDATA_KEYNAME);
      const authDataJson = JSON.parse(authDataString);
      if (authDataJson) {
        return authDataJson;
      }
    } catch (error) {
      // Handle the error here, e.g., log it
      console.error("Error parsing user roles:", error);
    }
    return {};
  };
  const {
    token: encodedToken,
    firstName,
    lastName,
    username: username,
    roles: userRoles,
  } = getAuthData();

  const history = useHistory();
  const location = useLocation();

  const [auth, setAuth] = useState(
    encodedToken
      ? {
          token: encodedToken,
          isAuth: true,
          firstName,
          lastName,
          username: username,
          roles: userRoles,
        }
      : { token: "", isAuth: false }
  );

  const logoutHandler = async (e) => {
    localStorage.removeItem("isAuth");
    localStorage.removeItem(TOKEN_KEYNAME);
    localStorage.removeItem(AUTHDATA_KEYNAME);
    setAuth({ token: "", isAuth: false });
    history.replace(location.pathname);
  };

  const loginHandler = async (e, username, password) => {
    e.preventDefault();
    try {
      setLoginLoading(true);
      setError("");
      setLoginCredential({ username, password });
      const response = await loginService(username, password);

      if (response.status === 200) {
        setLoginLoading(false);
        const encodedToken = response.data.encodedToken;
        const firstName = response.data.foundUser.firstName;
        const lastName = response.data.foundUser.lastName;
        const username = response.data.foundUser.username;
        const roles = response.data.foundUser.roles;

        const authData = {
          token: encodedToken,
          isAuth: true,
          firstName,
          lastName,
          username: username,
          roles: roles,
        };
        setAuth(authData);

        localStorage.setItem(TOKEN_KEYNAME, encodedToken);
        localStorage.setItem(AUTHDATA_KEYNAME, JSON.stringify(authData));
        setLoginCredential({ username: "", password: "" });

        history.replace(location?.state?.from.pathname || "/");
      }
    } catch (err) {
      setLoginLoading(false);
      setError(err.response.data.error);
    } finally {
      setLoginLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        loginHandler,
        logoutHandler,
        error,
        setError,
        loginLoading,
        setLoginLoading,
        loginCredential,
        setLoginCredential,
        setCurrentPage,
        currentPage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
