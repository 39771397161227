import {
  Button,
  Col,
  message,
  Row,
  Select,
  Space,
  Input,
  Tooltip,
  Divider,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import {
  // createCommand_MissionAsign,
  createCommand_MissionCancel,
  createCommand_MissionFinish,
} from "../spot-command-builder";

import {
  PlusOutlined,
  CloseOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  useMissionBuilder,
  useMissionBuilerDispatch,
} from "../context/mission-builder-context";
import { validateMissionDescription } from "../../../services/mission-services";
import SpotMissionBuilder from "./spot-mission-builder-v2";
import { downloadJsonFile, readJsonFile } from "../../../common/utilities";

const SpotSortieCommandBuilderV3 = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const onMissionCancelCommandCreated = props.OnMissionCancelCommandCreated;
  const onMissionFinishCommandCreated = props.OnMissionFinishCommandCreated;
  const onMissionDescriptionCreatedOK = props.OnMissionDescriptionCreatedOK;

  const {
    missionBuildState,
    spotJobDescriptionList,
    dataLoading: spotJobDescriptionListLoading,
  } = useMissionBuilder();
  const { dispatch } = useMissionBuilerDispatch();

  const missionDescriptions = missionBuildState.missionsDescriptions;

  const onButtonCancelClicked = () => {
    if (onMissionCancelCommandCreated) {
      const cmd = createCommand_MissionCancel();
      onMissionCancelCommandCreated(cmd);
    }
  };

  const onButtonMissionFinishClicked = () => {
    if (onMissionFinishCommandCreated) {
      const cmd = createCommand_MissionFinish();
      onMissionFinishCommandCreated(cmd);
    }
  };

  const onButtonAssignMissionClicked = () => {
    if (missionDescriptions.length == 0) {
      messageApi.open({
        type: "error",
        content: "Please create mission description first!",
      });
      return;
    }

    for (let i = 0; i < missionDescriptions.length; i++) {
      const mission = missionDescriptions[i];
      if (!validateMissionDescription(mission)) {
        messageApi.open({
          type: "error",
          content: "Please fill all information for mission " + i,
        });
        return;
      }
    }

    if (onMissionDescriptionCreatedOK) {
      const copiedList = missionDescriptions.map((missionDesc) => {
        return getStandardMissionDescription(missionDesc);
      });

      onMissionDescriptionCreatedOK(copiedList);
    }
  };

  const onMissionDescriptionChanged = (index, missionDesc) => {
    const payload = { index: index, missionDesc: missionDesc };
    dispatch({
      type: "UPDATE_MISSION_AT",
      payload: payload,
    });
  };

  const loadMissionFromFile = (index, file) => {
    console.log("File: ", file);
    if (file) {
      readJsonFile(file)
        .then(function (missionDesc) {
          console.log("Loaded JSON:", missionDesc);
          if (!validateMissionDescription(missionDesc)) {
            alert("Mission file is invalid.");
            return;
          }

          onMissionDescriptionChanged(index, missionDesc);
        })
        .catch(function (error) {
          console.error("Error loading mission from file:", error);
          alert("Loading mission file error:" + error.name);
        });
    } else {
      console.error("No file selected.");
      alert("No file selected.");
    }
  };

  const getStandardMissionDescription = (missionDesc) => {
    const copiedMissionDesc = {
      mission_type: missionDesc.mission_type,
      mission_name: missionDesc.mission_name,
      description: missionDesc.description,
      mission_jobs: missionDesc.mission_jobs,
    };
    return copiedMissionDesc;
  };

  const exportMission = (missionDesc) => {
    if (!validateMissionDescription(missionDesc)) {
      alert("Please fill all mission information first!");
      return;
    }
    const missionExport = getStandardMissionDescription(missionDesc);
    downloadJsonFile(missionExport, "delivery.mission");
  };

  return (
    <>
      {contextHolder}
      {missionDescriptions &&
        missionDescriptions.map((missionDesc, index) => (
          <Row
            gutter={[8, 24]}
            key={missionDesc?.key === null ? index : missionDesc?.key}
            // justify="space-around"
            // align="left"
          >
            <Col style={{ textAlign: "left" }}>
              <Space size={"small"}>
                <b>Mission {index}: </b>
                <Tooltip title="Export mission to file">
                  <Button
                    icon={<DownloadOutlined />}
                    size={"default"}
                    block
                    onClick={() => exportMission(missionDesc)}
                  >
                    Export
                  </Button>
                </Tooltip>
                <Upload
                  beforeUpload={(file) => {
                    loadMissionFromFile(index, file);
                  }}
                  name="file"
                  accept=".mission,.json"
                  multiple={false}
                  maxCount={1}
                  showUploadList={false}
                >
                  <Tooltip title="Export mission from file">
                    <Button icon={<UploadOutlined />} block>
                      Import
                    </Button>
                  </Tooltip>
                </Upload>
              </Space>
              <SpotMissionBuilder
                spotJobDescriptionList={spotJobDescriptionList}
                spotJobDescriptionListLoading={spotJobDescriptionListLoading}
                missionDescription={missionDesc}
                OnMissionDescriptionChanged={(missonDesc) => {
                  onMissionDescriptionChanged(index, missonDesc);
                }}
              ></SpotMissionBuilder>
              <Space size="small">
                <Tooltip title="Add mission">
                  <Button
                    block
                    // icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      {
                        const payload = { index: index, missionDesc: null };
                        dispatch({
                          type: "ADD_NEW_MISSION_AT",
                          payload: payload,
                        });
                      }
                    }}
                  >
                    Add
                  </Button>
                </Tooltip>
                {missionDescriptions.length > 1 && (
                  <Tooltip title="Del mission">
                    <Button
                      block
                      type="primary"
                      onClick={() => {
                        {
                          const payload = {
                            index: index,
                            missionDesc: missionDesc,
                          };
                          dispatch({
                            type: "DELETE_MISSION_AT",
                            payload: payload,
                          });
                        }
                      }}
                    >
                      Del
                    </Button>
                  </Tooltip>
                )}
              </Space>
              <Divider />
            </Col>
          </Row>
        ))}
      <Row gutter={[28, 40]}>
        <Col>
          <Space size="middle">
            <Button
              type="button"
              className="btn-secondary"
              block
              onClick={onButtonCancelClicked}
            >
              Cancel Mission
            </Button>

            <Button type="primary" onClick={onButtonMissionFinishClicked} block>
              {" "}
              Finish Mission
            </Button>

            <Button type="primary" onClick={onButtonAssignMissionClicked} block>
              {" "}
              Assign Mission
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default SpotSortieCommandBuilderV3;
