import React from "react";
import { BsEyeSlash } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { useState } from "react";
import { useAuth } from "../../../contexts/AuthProvider";
import { Alert, Button, Form, Input } from "antd";
import { getFetchURL } from "../../../common/setting";
import axios from "axios";

const changePasswordService = async (username, curPassword, newPassword) => {
  const loginURL = getFetchURL("/auth/change-password");

  try {
    const response = await axios.post(loginURL, {
      username: username,
      password: curPassword,
      new_password: newPassword,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    const responseData = err?.response?.data;
    if (responseData != null) throw new Error(responseData.message);
    else throw new Error(err.message);
  }
};

export const UserProfileEditPage = () => {
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const { auth } = useAuth();
  const [message, setMessage] = useState("");

  const handleFormSubmit = ({ password, newPassword }) => {
    setProcessing(true);
    setError("");
    setMessage("");

    changePasswordService(auth.username, password, newPassword)
      .then((result) => {
        setMessage("Password changed success");
        setError("");
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <div className="login-container">
      <h2>Change password</h2>
      <Form
        layout="vertical"
        onFinish={handleFormSubmit}
        className="login-body"
      >
        {error && <Alert type="error" message={error} banner />}
        {message && <Alert type="success" message={message} banner />}

        {/* <div className="email-container">Username: {auth.username}</div> */}

        <div className="password-container">
          <Form.Item
            label="Current password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </div>
        <div className="password-container">
          <Form.Item
            label="New password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm new password"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The confirm password that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </div>

        <div className="login-btn-container">
          <input value="Change Password" type="submit" />
        </div>
      </Form>
    </div>
  );
};
