import React, { useEffect, useState, useRef } from "react";
import { TimelineDataSeries, TimelineGraphView } from "../../utils/graph";
import { makeDivResizable, makeDraggable } from "../../utils/draggable";
import { Col, Row, Slider, Space } from "antd";
import { useRobotPeerConnection } from "../../contexts/RobotConnectionContext";

const VideoTrackViewerNew = (props) => {
  // const mediaTracks = props.mediaTracks;
  const showDelayTime = props.ShowDelayTime;
  const onJitterGraphInitCompleted = props.OnJitterGraphInitCompleted;

  const {
    connectionState: rtcConnectionState,
    mediaTracks,
    enableStats,
    disableStats,
    addStatsUpdatedEventListener,
    removeStatsUpdatedEventListener,
  } = useRobotPeerConnection();

  const videoTag = useRef(null);
  const audioTag = useRef(null);
  const waitingToPlayAudioRef = useRef(false);

  const [videoTrack, setVideoTrack] = useState(null);
  // const [audioTrack, setAudioTrack] = useState(null);

  const [canvasWidth, setCanvasWidth] = useState(1000);

  const networkDelaySeries_maxWindowRef = useRef(new TimelineDataSeries(3600));
  const refMaxNetworkDelayGraph = useRef(null);

  let networkDelaySeries_maxWindow = networkDelaySeries_maxWindowRef.current;

  const statsUpdatedHandler = (obj) => {
    const maxNetworkDelayGraph = refMaxNetworkDelayGraph.current;
    if (maxNetworkDelayGraph == null) return;
    const videoJitter = obj.videoJitter * 1000;
    // console.log("stats-updated: ", eventName, "obj: ", videoJitter);
    if (obj.videoJitter == -1) return;
    networkDelaySeries_maxWindow.addPoint(Date.now(), videoJitter);
    maxNetworkDelayGraph.setDataSeries([networkDelaySeries_maxWindow]);
    maxNetworkDelayGraph.updateEndDate();
  };

  const waitingUserInteractionToPlayAudio = () => {
    console.debug("Waiting user interaction to unmute the audio");
    const handleUserInteraction = async () => {
      if (waitingToPlayAudioRef.current) {
        try {
          await audioTag.current.play();
          waitingToPlayAudioRef.current = false;
          console.debug("Audio playback succeeded after user interaction");
        } catch (error) {
          console.error("Audio playback failed again:", error);
        }
      }
      // Clean up event listeners
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
      window.removeEventListener("touchstart", handleUserInteraction);
    };

    // Add event listeners for user interaction
    window.addEventListener("click", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);
    window.addEventListener("touchstart", handleUserInteraction);
  };

  useEffect(() => {
    if (!showDelayTime) return;
    if (refMaxNetworkDelayGraph.current == null) {
      refMaxNetworkDelayGraph.current = new TimelineGraphView(
        "maxNetworkDelayGraph",
        "maxNetworkDelayCanvas"
      );
    }

    console.log("useEffect addWebRTCEventListener");
    addStatsUpdatedEventListener(statsUpdatedHandler);
    return () => {
      console.log("useEffect removeWebRTCEventListener");
      removeStatsUpdatedEventListener(statsUpdatedHandler);
      refMaxNetworkDelayGraph.current = null;
    };
  }, [showDelayTime]);

  useEffect(() => {
    if (!mediaTracks) return;

    console.log("VideoTrackViewer useEffect:", mediaTracks);
    let tracks = mediaTracks;
    if (!Array.isArray(tracks)) tracks = [tracks];
    for (let k in tracks) {
      const evt = tracks[k];
      if (evt.track.kind === "video") {
        console.log("VideoTrackViewer set video track");
        const remoteVideo = videoTag.current;
        // console.log("remoteVideo: ", remoteVideo);
        // // document.getElementById("video").srcObject = evt.streams[0];
        if (remoteVideo.srcObject != evt.streams[0]) {
          console.log("VideoTrackViewer set remoteVideo.srcObject");
          remoteVideo.srcObject = evt.streams[0];
          // setVideoTrack(evt.streams[0]);
          remoteVideo.play();
        }
      } else {
        const remoteAudio = audioTag.current;
        if (remoteAudio.srcObject != evt.streams[0]) {
          console.debug("audio track set remoteVideo.srcObject");
          remoteAudio.srcObject = evt.streams[0];
          remoteAudio
            .play()
            .then(() => {
              waitingToPlayAudioRef.current = false;
              console.debug("audio played");
            })
            .catch((error) => {
              console.log("Audio playback prevented:", error);
              waitingToPlayAudioRef.current = true;
              waitingUserInteractionToPlayAudio();
            });
        }
      }
    }
  }, [mediaTracks]);

  const divCameraViewerContainer = useRef(null);
  const divJitterContainer = useRef(null);
  useEffect(() => {
    makeDivResizable(divCameraViewerContainer.current);
    if (divJitterContainer.current) {
      makeDivResizable(divJitterContainer.current);
    }
  }, []);

  return (
    <div
      style={{
        // borderStyle: "dashed",
        // background: "green",
        width: "100%",
        height: "100%",
        // maxHeight: "90vh",
        // maxWidth: "1800px"
      }}
    >
      <Row justify="space-between" align="top">
        <Col span={24}>
          <div
            // id="media"
            // className="panel-resizable"
            ref={divCameraViewerContainer}
          >
            <audio
              id="audio"
              autoPlay={true}
              ref={audioTag}
              playsInline={true}
              // muted="muted"
            ></audio>
            <video
              style={{
                ...VideoTrackViewerNew.defaultProps.CameraStyle,
                ...props.CameraStyle,
              }}
              ref={videoTag}
              id="video"
              autoPlay={true}
              muted="muted"
              playsInline={true}
              // srcObject={videoTrack}
            ></video>
          </div>
        </Col>
      </Row>
      {showDelayTime && (
        <Row justify="space-between" align="top">
          <Col span={24}>
            <div className="graph-container" id="maxNetworkDelayGraph">
              <canvas
                id="maxNetworkDelayCanvas"
                style={{ width: "100%", maxHeight: "200px" }}
                width={canvasWidth}
                height={100}
                ref={divJitterContainer}
              ></canvas>
              <Slider
                defaultValue={canvasWidth}
                min={300}
                max={3600}
                value={canvasWidth}
                // tooltip={{
                //   open: true,
                // }}
                onChange={(val) => {
                  setCanvasWidth(val);
                }}
              />
              Network delay (milliseconds):
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

VideoTrackViewerNew.defaultProps = {
  CameraStyle: {
    borderStyle: "dashed",
    width: "100%",
    height: "100%",
    maxHeight: "90vh",
    // maxWidth: "1800px"
  },
};

export default VideoTrackViewerNew;
