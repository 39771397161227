import * as React from "react";
import { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import { Divider, Layout, Menu } from "antd";
import {
  DesktopOutlined,
  InfoCircleOutlined,
  MailOutlined,
  SettingOutlined,
  BookOutlined,
  CalendarOutlined,
  BarsOutlined,
  SettingTwoTone,
} from "@ant-design/icons";
import UserProfile from "./auth/user-profile";

const MenuBar = () => {
  const location = useLocation();

  const menuItems = [
    {
      key: "home",
      title: "Robot Dashboard",
      link: "/dashboard",
      icon: <DesktopOutlined />,
    },
    {
      key: "map-manager",
      title: "Map Manager",
      link: "/map-manager",
      icon: <SettingOutlined />,
    },
    // {
    //   key: "delivery-dashboard",
    //   title: "Delivery Jobs",
    //   link: "/delivery/dashboard",
    //   icon: <BarsOutlined />,
    // },
    {
      key: "missions",
      title: "Robot Missions",
      link: "/missions",
      icon: <BarsOutlined />,
    },

    // {
    //   key: "spot-log",
    //   title: "Spot Log",
    //   link: "/spot-log",
    //   icon: <BookOutlined />,
    // },
    {
      key: "robot-reservation",
      title: "Wearable Reservation",
      link: "/wearable-reservation",
      icon: <CalendarOutlined />,
    },
  ];

  const getSelectedKeys = () => {
    const currentPath = location.pathname;
    return menuItems
      .filter((item) => currentPath.includes(item.link))
      .map((item) => item.key);
  };

  return (
    <Layout>
      <div className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        // defaultSelectedKeys={["1"]}
        selectedKeys={getSelectedKeys()}
      >
        {menuItems.map((item) => (
          <Menu.Item key={item.key} icon={item.icon}>
            {item.link && <Link to={item.link}>{item.title}</Link>}
            {!item.link && item.title}
          </Menu.Item>
        ))}
      </Menu>
      {/* <Divider /> */}
      <UserProfile></UserProfile>
    </Layout>
  );
};

const MenuBar2 = () => {
  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
    >
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            {/* <Link
              className="nav-link active"
              aria-current="page"
              to="/dashboard"
            >
              <span data-feather="home" className="align-text-bottom"></span>
              Dashboard
            </Link> */}
          </li>
          <li className="nav-item">
            <Link
              className="nav-link active"
              aria-current="page"
              to="/dashboard2"
            >
              <span data-feather="file" className="align-text-bottom"></span>
              Robot Dashboard
            </Link>
          </li>
        </ul>
        {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
          <span>Menu</span>
          <a className="link-secondary" href="#" aria-label="Add a new report">
            <span
              data-feather="plus-circle"
              className="align-text-bottom"
            ></span>
          </a>
        </h6>
        <ul className="nav flex-column mb-2">
          <li className="nav-item">
            <a className="nav-link" href="/calibration">
              <span
                data-feather="file-text"
                className="align-text-bottom"
              ></span>
              Calibration
            </a>
          </li>
        </ul> */}

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
          <span>Delivery</span>
          <a className="link-secondary" href="#" aria-label="Add a new report">
            <span
              data-feather="plus-circle"
              className="align-text-bottom"
            ></span>
          </a>
        </h6>
        <ul className="nav flex-column mb-2">
          <li className="nav-item">
            <a className="nav-link active" href="/map-manager">
              <span
                data-feather="file-text"
                className="align-text-bottom"
              ></span>
              Maps manager
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link active" href="/delivery/dashboard">
              <span
                data-feather="file-text"
                className="align-text-bottom"
              ></span>
              Delivery Jobs
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link active" href="/spot-log">
              <span
                data-feather="file-text"
                className="align-text-bottom"
              ></span>
              Spot Log
            </a>
          </li>
          <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
            <span>Reservation</span>
            <a
              className="link-secondary"
              href="#"
              aria-label="Add a new report"
            >
              <span
                data-feather="plus-circle"
                className="align-text-bottom"
              ></span>
            </a>
          </h6>
          <li className="nav-item">
            <a className="nav-link active" href="/robot-reservation">
              <span
                data-feather="file-text"
                className="align-text-bottom"
              ></span>
              Robot Reservations
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MenuBar;
