import { Button, Checkbox, Collapse, Divider, Space, Tabs } from "antd";
import React from "react";
import SpotMoveCommander from "./spot-move-control";
import SpotSortieCommandBuilderV3 from "./spot-sortie-command-ui-v3";
import { MissionsBuildProvider } from "../context/mission-builder-context";
import SpotJoystickCommand from "./spot-joystick-command";

import { MessageOutlined } from "@ant-design/icons";
import { createCommand_PlayAudio } from "../spot-command-builder";

const { Panel } = Collapse;

const SpotControlPanel = (props) => {
  const onCommandCreated = props.OnCommandCreated;
  const onMissionDescriptionCreatedOK = props.OnMissionDescriptionCreatedOK;
  const onSortieDescriptionCreatedOK = props.OnSortieDescriptionCreatedOK;
  const isJoystickEnabled = props.JoystickEnabled;

  const handleMoveCommand = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd, true);
  };

  const handleJoystickCommand = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd, true);
  };

  const handleControlCommand = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd);
  };

  const handleMissionCommand = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd);
  };

  const handlerMissionDescriptionCreatedOK = (missionDescription) => {
    if (onMissionDescriptionCreatedOK)
      onMissionDescriptionCreatedOK(missionDescription);
  };

  const handleOnSortieDescriptionCreatedOK = (missionDescriptions) => {
    if (onSortieDescriptionCreatedOK)
      onSortieDescriptionCreatedOK(missionDescriptions);
  };

  const handlePlayAudioCommandButtonClicked = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd, true);
  };

  return (
    <div>
      <div
        style={{
          "justify-items": "center",
          "align-items": "center",
          padding: "10px",
        }}
      >
        <Tabs
          defaultActiveKey="1"
          type="card"
          size="middle"
          items={[
            {
              label: "Commands",
              key: 0,
              children: (
                <SpotMoveCommander
                  EnableKeyboard={Collapse}
                  OnMoveCommandCreated={handleMoveCommand}
                  OnControlCommandCreated={handleControlCommand}
                ></SpotMoveCommander>
              ),
            },
            {
              label: "Joystick",
              key: 1,
              disabled: !isJoystickEnabled,
              children: (
                <SpotJoystickCommand
                  OnCommandCreated={handleJoystickCommand}
                ></SpotJoystickCommand>
              ),
              // icon:
            },
          ]}
        />
      </div>

      <Collapse defaultActiveKey={[0, 20, "voice_msg"]}>
        {/* <Panel header="Move control" key="0">
          <SpotMoveCommander
            EnableKeyboard={Collapse}
            //   visible={isRobotControlEnabled}
            OnMoveCommandCreated={handleMoveCommand}
            OnControlCommandCreated={handleControlCommand}
          ></SpotMoveCommander>
        </Panel>

        {isJoystickEnabled && (
          <Panel header="Joystick" key="12">
            <SpotJoystickCommand
              OnCommandCreated={handleJoystickCommand}
            ></SpotJoystickCommand>
          </Panel>
        )} */}
        <Panel header="Voice messages" key="voice_msg">
          <div>
            <Space wrap size={[8, 12]}>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("greeting.mp3")
                  )
                }
              >
                안녕하세요
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("train_arriving.mp3")
                  )
                }
              >
                곧 월미바다 열차가 도착합니다
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("follow_me.mp3")
                  )
                }
              >
                저를 따라오세요
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("boarding_side_left.mp3")
                  )
                }
              >
                타는곳은 왼쪽 입니다
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("boarding_side_right.mp3")
                  )
                }
              >
                타는곳은 오른쪽 입니다
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("make_my_way.mp3")
                  )
                }
              >
                양보해주세요
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("goodbye.mp3")
                  )
                }
              >
                안녕히 가세요
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("thankyou.mp3")
                  )
                }
              >
                감사합니다
              </Button>
              {/* <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("follow_you.mp3")
                  )
                }
              >
                당신을 따르겠습니다
              </Button> */}
            </Space>
          </div>
        </Panel>
        <Panel header="Missions" key="6">
          <MissionsBuildProvider>
            <SpotSortieCommandBuilderV3
              OnMissionCancelCommandCreated={handleMissionCommand}
              OnMissionFinishCommandCreated={handleMissionCommand}
              OnMissionDescriptionCreatedOK={handleOnSortieDescriptionCreatedOK}
            ></SpotSortieCommandBuilderV3>
          </MissionsBuildProvider>
        </Panel>
      </Collapse>
    </div>
  );
};

export default SpotControlPanel;
