import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";

export const RequiresAuth = ({ children, requiredRoles }) => {
  const { auth, setError } = useAuth();

  const location = useLocation();

  const checkPermission = (auth, requiredRoles) => {
    if (!auth.isAuth) {
      setError("");
      return false;
    }
    if (requiredRoles == null || requiredRoles.length == 0) return true;
    if (auth.roles == null) {
      console.log("null roles");
    }

    for (let a in auth.roles) {
      for (let r in requiredRoles)
        if (auth.roles[a] == requiredRoles[r]) {
          return true;
        }
    }

    setError(
      "Access denied! Please login again using an authorized user roles: " +
        requiredRoles
    );
    return false;
  };

  return checkPermission(auth, requiredRoles) ? (
    children
  ) : (
    <Redirect to={{ pathname: "/login", state: { from: location } }} />
  );
};
