import { Button, FloatButton } from "antd";
import "./robots-card.css";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";

import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const RobotsCard = (props) => {
  const robots = props.robots;
  const shutdownRobotClick = props.shutdownRobotClick;

  const restartRobotClick = props.restartRobotClick;
  const settingRobotClick = props.settingRobotClick;

  const showDeveloperLink = props.ShowDeveloperLink;

  const [showOfflineRobots, setShowOfflineRobots] = React.useState(
    props?.ShowOfflineRobots
  );

  const history = useHistory();

  // console.log("Robots-list component render");
  // console.log("Robots count: ", robots.length);
  return (
    <div className="table-responsive">
      <h2>Dashboard</h2>

      {!showOfflineRobots && (
        <FloatButton
          icon={<EyeInvisibleOutlined />}
          onClick={() => setShowOfflineRobots(true)}
          tooltip={<div>Show offline robots</div>}
          type="primary"
        />
      )}

      {showOfflineRobots && (
        <FloatButton
          icon={<EyeOutlined />}
          onClick={() => setShowOfflineRobots(false)}
          tooltip={<div>Hide offline robots</div>}
          // type="primary"
        />
      )}

      <div className="album py-5 bg-light">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-md-4 g-4">
            {robots &&
              robots
                .sort((a, b) => {
                  return a.status < b.status
                    ? 1
                    : a.status == b.status
                    ? 0
                    : -1;
                })
                .map((robot, index) => {
                  if (robot.status == "offline" && !showOfflineRobots)
                    return null;
                  else
                    return (
                      <div key={robot.id} className="col">
                        <div className="card shadow-sm">
                          <svg
                            className="bd-placeholder-img card-img-top"
                            width="100%"
                            height="125"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            aria-label="Placeholder: Thumbnail"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                          >
                            <title>Placeholder</title>
                            <rect
                              width="100%"
                              height="100%"
                              fill={
                                robot.status == "offline"
                                  ? "#55595c"
                                  : "#558c5c"
                              }
                            ></rect>
                            {/* {robot.status != "offline" && ( */}
                            <image
                              xlinkHref={
                                robot.type == "gocart"
                                  ? "images/gocart.png"
                                  : robot.type == "spot"
                                  ? "images/spot.jpg"
                                  : robot.type == "syscon"
                                  ? "images/syscon.png"
                                  : robot.type == "tracer"
                                  ? "images/tracer.png"
                                  : robot.type == "demobot2"
                                  ? "images/demobot2.png"
                                  : "images/unknown_robot_type.png"
                              }
                              width="100%"
                              height="100%"
                              className={
                                robot.status == "offline"
                                  ? "disabled-image"
                                  : ""
                              }
                            />
                            {/* )} */}

                            {/* <text x="50%" y="50%" fill="#eceeef" dy=".3em">
                        Robot: {robot.id}
                      </text> */}
                          </svg>

                          <div className="card-body">
                            <h5 className="card-title">
                              <b>
                                {robot.id}{" "}
                                {robot.status == "offline" && "(offline)"}
                              </b>
                            </h5>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="btn-group">
                                {robot.status != "offline" && (
                                  <Link
                                    to={
                                      "/robots/" +
                                      robot.id +
                                      (robot.type
                                        ? "/" + robot.type
                                        : "/teleops")
                                    }
                                    className={
                                      "btn btn-sm btn-outline-secondary" +
                                      (robot.status == "offline"
                                        ? " disabled"
                                        : "")
                                    }
                                  >
                                    Connect
                                  </Link>
                                )}
                                <Link
                                  to={"/robots/setting/" + robot.id}
                                  className="btn btn-sm btn-outline-secondary"
                                >
                                  Setting
                                </Link>
                                {robot.status != "offline" && (
                                  <Link
                                    to={
                                      "/robot-camera/" +
                                      robot.id +
                                      "?show-status=true&local-mic=true&remote-mic=true&remote-cam=true"
                                    }
                                    className={
                                      "btn btn-sm btn-outline-secondary" +
                                      (robot.status == "offline"
                                        ? " disabled"
                                        : "")
                                    }
                                  >
                                    Camera
                                  </Link>
                                )}
                                {robot.status != "offline" && (
                                  <Link
                                    type="button"
                                    className={
                                      "btn btn-sm btn-outline-secondary" +
                                      (robot.status == "offline"
                                        ? " disabled"
                                        : "")
                                    }
                                    onClick={() => restartRobotClick(robot)}
                                  >
                                    Restart
                                  </Link>
                                )}
                                {robot.status != "offline" && (
                                  <Link
                                    type="button"
                                    className={
                                      "btn btn-sm btn-outline-secondary" +
                                      (robot.status == "offline"
                                        ? " disabled"
                                        : "")
                                    }
                                    onClick={() => shutdownRobotClick(robot)}
                                  >
                                    Shutdown
                                  </Link>
                                )}
                                {showDeveloperLink && (
                                  <Link
                                    to={"/conn-dev/" + robot.id}
                                    className={
                                      "btn btn-sm btn-outline-secondary" +
                                      (robot.status == "offline"
                                        ? " disabled"
                                        : "")
                                    }
                                  >
                                    DEV Connect
                                  </Link>
                                )}
                              </div>
                              {/* <small className="text-muted">9 mins</small> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RobotsCard;
