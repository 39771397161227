const create_gocart_raw_command = (gocart_cmd) => {
  const cmd = {
    cmd: "gocart-command",
    data: gocart_cmd,
  };
  return cmd;
};

export const createCommand_move2Pos = ({ x, y, theta }) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "move",
      params: {
        pose: {
          x: x,
          y: y,
          theta: theta,
        },
      },
    },
    msg_uuid: "move_1",
    msg_ver: "1.0",
  };

  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_move2Pos_without_theta = (x, y) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "move",
      params: {
        pose: {
          x: x,
          y: y,
        },
      },
    },
    msg_uuid: "move_1",
    msg_ver: "1.0",
  };

  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_relocate = (x, y, theta) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "relocation",
      params: {
        pose: {
          x: x,
          y: y,
          theta: theta,
        },
      },
    },
    msg_uuid: "relocation_1",
    msg_ver: "1.0",
  };

  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_relocate_without_theta = (x, y) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "relocation",
      params: {
        pose: {
          x: x,
          y: y,
          theta: 0,
        },
      },
    },
    msg_uuid: "relocation_1",
    msg_ver: "1.0",
  };

  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_move2Waypoint = (waypoint) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "create_job",
      params: {
        instructions: [
          {
            inst_type: "move",
            waypoint_id: waypoint.id,
          },
        ],
      },
    },
    msg_uuid: "create_job_1",
    msg_ver: "1.0",
  };

  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_dock = (dockStation) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "create_job",
      params: {
        instructions: [
          {
            inst_type: "charge",
            resource_id: dockStation.id,
          },
        ],
      },
    },
    msg_uuid: "create_job_1",
    msg_ver: "1.0",
  };
  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_set_position_by_map_pose = (
  map_id,
  range_radious,
  pose
) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "set_position",
      params: {
        by_map_pose: {
          map_id: map_id,
          range_radius: range_radious,
          pose: {
            x: pose.x,
            y: pose.y,
            theta: pose.theta,
          },
        },
      },
    },
    msg_uuid: "set_position_1",
    msg_ver: "1.0",
  };
  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_set_position_by_waypoint = (
  waypointName,
  rangeRadious
) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "set_position",
      params: {
        by_waypoint: {
          name: waypointName,
          range_radius: rangeRadious,
        },
      },
    },
    msg_uuid: "set_position_1",
    msg_ver: "1.0",
  };

  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_RunRecentFailedJob = () => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "run_recent_failed_job",
    },
    msg_uuid: "run_recent_failed_job_1",
    msg_ver: "1.0",
  };
  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_Move = (direction, speed = 2) => {
  return {
    cmd: "command",
    data: {
      cmd_type: "move",
      params: {
        direction: direction,
        speed: speed,
      },
    },
  };
};

export const createCommand_joystick_start = () => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "virtual_joystick_start",
      params: {
        force_restart: true,
      },
    },
    msg_uuid: "virtual_joystick_start_1",
  };

  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_joystick_finish = (token) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "virtual_joystick_finish",
      params: {
        virtual_joystick_token: token,
      },
    },
    msg_uuid: "virtual_joystick_finish_1",
  };
  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_joystick_health_check = (token) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "virtual_joystick_health_check",
      params: {
        virtual_joystick_token: token,
      },
    },
    msg_uuid: "virtual_joystick_health_check_1",
  };
  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_joystick_move_start = (token) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "virtual_joystick_event",
      params: {
        virtual_joystick_token: token,
        event: "start_dragging",
        axis_0: 0,
        axis_1: 0,
        max_speed: 1,
      },
    },
    msg_uuid: "virtual_joystick_event_1",
  };
  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_joystick_move = (
  token,
  axis_0,
  axis_1,
  max_speed
) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "virtual_joystick_event",
      params: {
        virtual_joystick_token: token,
        event: "now_dragging",
        axis_0: axis_0,
        axis_1: axis_1,
        max_speed: max_speed,
      },
    },
    msg_uuid: "virtual_joystick_event_1",
  };
  return create_gocart_raw_command(gocart_cmd);
};

export const createCommand_joystick_move_finish = (token) => {
  const gocart_cmd = {
    msg_type: "command",
    msg_body: {
      name: "virtual_joystick_event",
      params: {
        virtual_joystick_token: token,
        event: "finish_dragging",
        axis_0: 0,
        axis_1: 0,
        max_speed: 1,
      },
    },
    msg_uuid: "virtual_joystick_event_1",
  };
  return create_gocart_raw_command(gocart_cmd);
};
