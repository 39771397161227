import { Button, Modal } from "antd";
import {
  createCommand_MissionCancel,
  createCommand_MissionPause,
  createCommand_MissionResume,
  createCommand_MissionSkip,
  createCommand_MissionSkipJob,
} from "./mission-commands-builder";

import "./ui-mission-command.css";
import { createCommand_MissionFinish } from "../spot/spot-command-builder";

const MissionCommandButtons = (props) => {
  const onCommandCreated = props.OnCommandCreated;

  const handlePauseCommandButtonClicked = (cmd) => {
    if (onCommandCreated)
      Modal.confirm({
        title: "Do you want to pause the current mission?",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          if (onCommandCreated) onCommandCreated(cmd);
        },
        onCancel() {},
      });
  };

  const handleMissionResumeCommandButtonClicked = (cmd) => {
    if (onCommandCreated)
      Modal.confirm({
        title: "Do you want to resume the current mission?",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          if (onCommandCreated) onCommandCreated(cmd);
        },
        onCancel() {},
      });
  };

  const handleSkipJobCommandButtonClicked = (cmd) => {
    if (onCommandCreated)
      Modal.confirm({
        title: "Do you want to skip the current mission job?",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          if (onCommandCreated) onCommandCreated(cmd);
        },
        onCancel() {},
      });
  };

  const handleMissionCancelCommandButtonClicked = (cmd) => {
    if (onCommandCreated)
      Modal.confirm({
        title: "Do you want to cancel the current mission?",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          if (onCommandCreated) onCommandCreated(cmd);
        },
        onCancel() {},
      });
  };

  const handleMissionFinishCommandButtonClicked = (cmd) => {
    if (onCommandCreated)
      Modal.confirm({
        title: "Do you want to finish the current mission?",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          if (onCommandCreated) onCommandCreated(cmd);
        },
        onCancel() {},
      });
  };

  return (
    <div className="mission-command-buttons-grid">
      <button
        className="pause btn-primary"
        onClick={() =>
          handlePauseCommandButtonClicked(createCommand_MissionPause())
        }
      >
        Pause Mission
      </button>

      <button
        className="resume btn-primary"
        onClick={() =>
          handleMissionResumeCommandButtonClicked(createCommand_MissionResume())
        }
      >
        Resume Mission
      </button>

      <button
        className="next-job btn-primary"
        onClick={() =>
          handleSkipJobCommandButtonClicked(createCommand_MissionSkipJob())
        }
      >
        Skip Job
      </button>

      <button
        className="cancel btn-primary"
        onClick={() =>
          handleMissionCancelCommandButtonClicked(createCommand_MissionCancel())
        }
      >
        Cancel Mission
      </button>

      <button
        className="finish btn-primary"
        onClick={() =>
          handleMissionFinishCommandButtonClicked(createCommand_MissionFinish())
        }
      >
        Mission Finished
      </button>

      {/* <button
        className="failed btn-primary"
        // onClick={() =>
        //   handleMissionCancelCommandButtonClicked(createCommand_MissionCancel())
        // }
      >
        Mission Failed
      </button> */}
    </div>
  );
};
export default MissionCommandButtons;
