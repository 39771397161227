import "./user-profile.css";

import * as React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import { useHistory, useLocation } from "react-router-dom";
import { Avatar, Space } from "antd";

import { UserOutlined } from "@ant-design/icons";

const UserProfile = () => {
  const location = useLocation();
  const history = useHistory();

  const { auth, logoutHandler } = useAuth();

  return (
    <div className="center-container">
      {auth.isAuth && (
        <Space size={24}>
          <Link to="/user-profile">{auth.username}</Link>
          <Avatar shape="round" icon={<UserOutlined />}></Avatar>
          <button onClick={logoutHandler}>Logout</button>
        </Space>
      )}
      {!auth.isAuth && (
        <Space size={24}>
          <Link to={{ pathname: "/login", state: { from: location } }}>
            Login
          </Link>
        </Space>
      )}
    </div>
  );
};

export default UserProfile;
