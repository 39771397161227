export const createCommand_MissionPause = (mission_id) => {
  return {
    cmd: "command",
    data: {
      cmd_type: "mission_pause",
      params: {
        mission_id: mission_id,
      },
    },
  };
};

export const createCommand_MissionResume = (mission_id) => {
  return {
    cmd: "command",
    data: {
      cmd_type: "mission_resume",
      params: {
        mission_id: mission_id,
      },
    },
  };
};

export const createCommand_MissionSkip = (mission_id) => {
  return {
    cmd: "command",
    data: {
      cmd_type: "mission_skip",
      params: {
        mission_id: mission_id,
      },
    },
  };
};

export const createCommand_MissionSkipJob = (mission_id) => {
  return {
    cmd: "command",
    data: {
      cmd_type: "mission_skip_job",
      params: {
        mission_id: mission_id,
      },
    },
  };
};

export const createCommand_MissionCancel = (mission_id) => {
  return {
    cmd: "command",
    data: {
      cmd_type: "mission_cancel",
      params: {
        mission_id: mission_id,
      },
    },
  };
};
