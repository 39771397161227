import { Fragment, useEffect, useState } from "react";
import {
  createCommand_BackwardLeft,
  createCommand_BackwardRight,
  createCommand_ForwardLeft,
  createCommand_ForwardRight,
  createCommand_Sit,
  createCommand_Stand,
  createCommand_Stop,
  createCommand_StrafeLeft,
  createCommand_StrafeRight,
  createCommand_TurnLeft,
  createCommand_TurnRight,
  createCommand_WalkBackward,
  createCommand_WalkForward,
  createCommand_Connect,
  createCommand_EStop,
  createCommand_MissionCancel,
  createCommand_MissionPause,
  createCommand_MissionRestart,
  createCommand_MissionStart,
  createCommand_PowerOn,
  createCommand_Walk,
} from "../spot-command-builder";
import "./spot-move-control.css";
import { Tooltip } from "antd";

const SpotMoveCommander = (props) => {
  const onControlCommandCreated = props.OnControlCommandCreated;
  const onCommandCreated = props.OnMoveCommandCreated;
  const enableKeyboard = props.EnableKeyboard;

  const [keysPressed, setKeysPressed] = useState(new Set());
  const controlKeys = new Set([
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "w",
    "s",
    "a",
    "d",
    "q",
    "e",
    " ",
    "Control",
  ]);

  const handleCommandButtonClicked = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd);
  };

  const handleControlCommandButtonClicked = (cmd) => {
    if (onControlCommandCreated) onControlCommandCreated(cmd);
  };

  useEffect(() => {
    console.log("Keys pressed: ", keysPressed);
    if (!onCommandCreated) return;

    const ctrlKey = keysPressed.has("Control");
    // for (const key of keysPressed) {
    //   const cmd = getCommandByKey(key, ctrlKey);
    //   console.log("key: ", key);
    //   if (cmd != null) {
    //     onCommandCreated(cmd);
    //   }
    // }

    const cmd = getKeyspressedCommand(ctrlKey);
    if (cmd != null) onCommandCreated(cmd);
  }, [keysPressed]);

  const handleKeyDown = (event) => {
    if (!controlKeys.has(event.key)) return;
    setKeysPressed((prevKeys) => new Set([...prevKeys, event.key]));
    event.preventDefault();
  };

  const handleKeyUp = (event) => {
    if (!controlKeys.has(event.key)) return;
    setKeysPressed((prevKeys) => {
      const updatedKeys = new Set(prevKeys);
      updatedKeys.delete(event.key);
      return updatedKeys;
    });
    event.preventDefault();
  };

  const getKeyspressedCommand = () => {
    if (keysPressed.has(" ")) {
      return createCommand_Stop();
    }

    if (["w", "ArrowUp"].some((item) => keysPressed.has(item))) {
      if (keysPressed.has("Control")) {
        return createCommand_Stand();
      }
      if (["a", "ArrowLeft"].some((item) => keysPressed.has(item))) {
        return createCommand_ForwardLeft();
      }
      if (["d", "ArrowRight"].some((item) => keysPressed.has(item))) {
        return createCommand_ForwardRight();
      }

      return createCommand_WalkForward();
    }

    if (["s", "ArrowDown"].some((item) => keysPressed.has(item))) {
      if (keysPressed.has("Control")) {
        return createCommand_Sit();
      }
      if (["a", "ArrowLeft"].some((item) => keysPressed.has(item))) {
        return createCommand_BackwardLeft();
      }
      if (["d", "ArrowRight"].some((item) => keysPressed.has(item))) {
        return createCommand_BackwardRight();
      }
      return createCommand_WalkBackward();
    }

    if (["a", "ArrowLeft"].some((item) => keysPressed.has(item))) {
      if (keysPressed.has("Control")) {
        return createCommand_TurnLeft();
      }
      return createCommand_StrafeLeft();
    }
    if (["d", "ArrowRight"].some((item) => keysPressed.has(item))) {
      if (keysPressed.has("Control")) {
        return createCommand_TurnRight();
      }
      return createCommand_StrafeRight();
    }

    if (keysPressed.has("q")) {
      return createCommand_TurnLeft();
    }

    if (keysPressed.has("e")) {
      return createCommand_TurnRight();
    }

    return null;
  };

  const getCommandByKey = (key, ctrlKey = false) => {
    switch (key) {
      case "e":
      case "ArrowUp":
        if (ctrlKey) return createCommand_Stand();
        else return createCommand_WalkForward();
      case "d":
      case "ArrowDown":
        if (ctrlKey) return createCommand_Sit();
        else return createCommand_WalkBackward();

      case "s":
      case "ArrowLeft":
        if (ctrlKey) return createCommand_TurnLeft();
        else return createCommand_StrafeLeft();

      case "f":
      case "ArrowRight":
        if (ctrlKey) return createCommand_TurnRight();
        else return createCommand_StrafeRight();
      case " ":
        return createCommand_Stop();
      default:
        break;
    }
    return null;
  };

  const handleKeyDown1 = (event) => {
    console.log("Event key: ", event.key);
    switch (event.key) {
      case "e":
      case "ArrowUp":
        if (event.ctrlKey) handleCommandButtonClicked(createCommand_Stand());
        else handleCommandButtonClicked(createCommand_WalkForward());
        event.preventDefault();
        break;
      case "d":
      case "ArrowDown":
        if (event.ctrlKey) handleCommandButtonClicked(createCommand_Sit());
        else handleCommandButtonClicked(createCommand_WalkBackward());
        event.preventDefault();
        break;
      case "s":
      case "ArrowLeft":
        if (event.ctrlKey) handleCommandButtonClicked(createCommand_TurnLeft());
        else handleCommandButtonClicked(createCommand_StrafeLeft());
        event.preventDefault();
        break;
      case "f":
      case "ArrowRight":
        if (event.ctrlKey)
          handleCommandButtonClicked(createCommand_TurnRight());
        else handleCommandButtonClicked(createCommand_StrafeRight());
        event.preventDefault();
        break;
      case " ":
        handleCommandButtonClicked(createCommand_Stop());
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <div
        className="spot-control"
        tabIndex={0}
        onKeyDown={enableKeyboard ? handleKeyDown : (evt) => {}}
        onKeyUp={enableKeyboard ? handleKeyUp : (evt) => {}}
      >
        <div className="spot-control-buttons" tabIndex={0}>
          <button
            className="forward"
            onClick={() =>
              handleCommandButtonClicked(createCommand_WalkForward())
            }
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2 L2 15 L22 15 Z" />
            </svg>
          </button>
          <button
            className="sleft"
            onClick={() =>
              handleCommandButtonClicked(createCommand_StrafeLeft())
            }
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 12 L15 2 L15 22 Z" />
            </svg>
          </button>

          <button
            className="sright"
            onClick={() =>
              handleCommandButtonClicked(createCommand_StrafeRight())
            }
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 12 L9 2 L9 22 Z" />
            </svg>
          </button>
          <button
            className="turnleft"
            onClick={() => handleCommandButtonClicked(createCommand_TurnLeft())}
          >
            <svg fill="currentColor" viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M10 4.5A5.5 5.5 0 114.5 10a.5.5 0 00-1 0 6.5 6.5 0 103.25-5.63l.5.865A5.472 5.472 0 0110 4.5z"
                clip-rule="evenodd"
              />
              <path
                fill-rule="evenodd"
                d="M9.354 1.646a.5.5 0 00-.708 0l-2.5 2.5a.5.5 0 000 .708l2.5 2.5a.5.5 0 10.708-.708L7.207 4.5l2.147-2.146a.5.5 0 000-.708z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <button
            className="stop"
            onClick={() => handleCommandButtonClicked(createCommand_Stop())}
          >
            STOP
            {/* <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <rect x="6" y="6" width="12" height="12" />
          </svg> */}
          </button>
          <button
            className="turnright"
            onClick={() =>
              handleCommandButtonClicked(createCommand_TurnRight())
            }
          >
            <svg fill="currentColor" viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M10 4.5a5.5 5.5 0 105.5 5.5.5.5 0 011 0 6.5 6.5 0 11-3.25-5.63l-.5.865A5.472 5.472 0 0010 4.5z"
                clip-rule="evenodd"
              />
              <path
                fill-rule="evenodd"
                d="M10.646 1.646a.5.5 0 01.708 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 01-.708-.708L12.793 4.5l-2.147-2.146a.5.5 0 010-.708z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <button
            className="backward"
            onClick={() =>
              handleCommandButtonClicked(createCommand_WalkBackward())
            }
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22 L2 9 L22 9 Z" />
            </svg>
          </button>
          <Tooltip title="Sit">
            <button
              className="sit"
              onClick={() => handleCommandButtonClicked(createCommand_Sit())}
            >
              <svg
                width="41"
                height="41"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M13.354 12.146a.5.5 0 010 .708l-3 3a.5.5 0 01-.708 0l-3-3a.5.5 0 01.708-.708L10 14.793l2.646-2.647a.5.5 0 01.708 0z"
                  clip-rule="evenodd"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 8a.5.5 0 01.5.5V15a.5.5 0 01-1 0V8.5A.5.5 0 0110 8zM4 5.75a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </Tooltip>
          <Tooltip title="Stand">
            <button
              className="stand"
              onClick={() => handleCommandButtonClicked(createCommand_Stand())}
            >
              {/* Stand */}
              <svg
                width="41"
                height="41"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M13.354 7.854a.5.5 0 000-.708l-3-3a.5.5 0 00-.708 0l-3 3a.5.5 0 10.708.708L10 5.207l2.646 2.647a.5.5 0 00.708 0z"
                  clip-rule="evenodd"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 12a.5.5 0 00.5-.5V5a.5.5 0 00-1 0v6.5a.5.5 0 00.5.5zm-6 2.75a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </Tooltip>
          {/* -------------- */}
        </div>
        <div className="spot-command-pad">
          <button
            className="connect"
            onClick={() =>
              handleControlCommandButtonClicked(createCommand_Connect())
            }
          >
            Connect
          </button>

          <button
            className="power-on"
            onClick={() =>
              handleControlCommandButtonClicked(createCommand_PowerOn())
            }
          >
            Power On
          </button>

          <button
            className="e-stop"
            onClick={() =>
              handleControlCommandButtonClicked(createCommand_EStop())
            }
          >
            E-Stop
          </button>

          <button
            className="walk"
            onClick={() =>
              handleControlCommandButtonClicked(createCommand_Walk())
            }
          >
            Walk
          </button>

          <button
            className="start"
            onClick={() =>
              handleControlCommandButtonClicked(createCommand_MissionStart())
            }
          >
            Start
          </button>

          <button
            className="pause"
            onClick={() =>
              handleControlCommandButtonClicked(createCommand_MissionPause())
            }
          >
            Pause
          </button>

          <button
            className="restart"
            onClick={() =>
              handleControlCommandButtonClicked(createCommand_MissionRestart())
            }
          >
            Restart
          </button>

          <button
            className="cancel-mission"
            onClick={() =>
              handleControlCommandButtonClicked(createCommand_MissionCancel())
            }
          >
            Cancel Mission
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default SpotMoveCommander;
