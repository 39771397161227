import { useEffect, useState } from "react";

const useFetchState = (url) => {
  const [data, setData] = useState(null);
  const [isPending, setPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortCtrl = new AbortController();
    console.log("Fetch from url: ", url);
    fetch(url, { signal: abortCtrl.signal })
      .then((response) => {
        if (!response.ok)
          throw Error("Could not fetch the data from " + url + '"');
        return response.json();
      })
      .then((data) => {
        setData(data);
        setPending(false);
        setError(null);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          setPending(false);
          setError(err.message);
        }
      });

    return () => abortCtrl.abort();
  }, [url]);

  return { data, isPending, error, setData };
};

export const useFetchStateTriggers = (url, triggers) => {
  const [data, setData] = useState(null);
  const [isPending, setPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortCtrl = new AbortController();
    console.log("Fetch from url: ", url);
    fetch(url, { signal: abortCtrl.signal })
      .then((response) => {
        if (!response.ok)
          throw Error("Could not fetch the data from " + url + '"');
        return response.json();
      })
      .then((data) => {
        setData(data);
        setPending(false);
        setError(null);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          setPending(false);
          setError(err.message);
        }
      });

    return () => abortCtrl.abort();
  }, triggers);

  return { data, isPending, error, setData };
};

export default useFetchState;
