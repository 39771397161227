import { Fragment, useEffect, useState } from "react";
import "./TwistKeyboardMoveCommander.css";

const TwistKeyboardMoveCommander = (props) => {
  const onCommandCreated = props.OnCommandCreated;
  const enableKeyboard = props.EnableKeyboard;

  // // const [twist, setTwist] = useState({
  // //   linear: { x: 0, y: 0, z: 0 },
  // //   angular: { x: 0, y: 0, z: 0 },
  // // });
  // const [twistData, setTwistData] = useState({
  //   x: 0,
  //   y: 0,
  //   z: 0,
  //   th: 0,
  //   speed: 0.5,
  //   turn: 1.0,
  //   speed_limit: 1000,
  //   turn_limit: 1000,
  //   repeat: 0,
  //   key_timeout: 0.5,
  //   stamped: false,
  //   twist_frame: "",
  // });

  // const [keysPressed, setKeysPressed] = useState(new Set());

  const [stateData, setStateData] = useState({
    keysPressed: new Set(),
    twistData: {
      x: 0,
      y: 0,
      z: 0,
      th: 0,
      speed: 0.5,
      turn: 1.0,
    },
  });

  const speed_limit = 1000,
    turn_limit = 1000,
    repeat = 0,
    key_timeout = 0.5,
    stamped = false,
    twist_frame = "";

  const moveBindings = {
    w: [1, 0, 0, 0],
    e: [1, 0, 0, -1],
    a: [0, 0, 0, 1],
    d: [0, 0, 0, -1],
    q: [1, 0, 0, 1],
    s: [-1, 0, 0, 0],
    c: [-1, 0, 0, 1],
    z: [-1, 0, 0, -1],
    W: [1, 0, 0, 0],
    E: [1, -1, 0, 0],
    A: [0, 1, 0, 0],
    D: [0, -1, 0, 0],
    Q: [1, 1, 0, 0],
    S: [-1, 0, 0, 0],
    C: [-1, -1, 0, 0],
    Z: [-1, 1, 0, 0],
    t: [0, 0, 1, 0],
    b: [0, 0, -1, 0],
  };

  const speedBindings = {
    u: [1.1, 1.1],
    m: [0.9, 0.9],
    i: [1.1, 1],
    ",": [0.9, 1],
    o: [1, 1.1],
    ".": [1, 0.9],
  };

  const controlKeys = new Set([
    ...Object.keys(moveBindings),
    ...Object.keys(speedBindings),
  ]);

  const handleMove = (twist) => {
    const cmd_msg = twist;

    if (props.OnCommandCreated) props.OnCommandCreated(twist, true);
  };

  const handleStop = () => {
    let move_cmd = {
      cmd: "command",
      data: {
        cmd_type: "move",
        params: { direction: "stop", speed: 2 },
      },
    };
    // if (props.OnCommandCreated) props.OnCommandCreated(move_cmd, true);
  };

  const getUpdatedTwistState = (prevTwistState, key) => {
    if (key == null) {
      const x = 0,
        y = 0,
        z = 0,
        th = 0;
      return { ...prevTwistState, x, y, z, th };
    } else if (moveBindings.hasOwnProperty(key)) {
      const x = moveBindings[key][0],
        y = moveBindings[key][1],
        z = moveBindings[key][2],
        th = moveBindings[key][3];

      return { ...prevTwistState, x, y, z, th };
    } else if (speedBindings.hasOwnProperty(key)) {
      const speed = Math.min(
        speed_limit,
        prevTwistState.speed * speedBindings[key][0]
      );
      const turn = Math.min(
        turn_limit,
        prevTwistState.turn * speedBindings[key][1]
      );
      if (speed >= speed_limit) console.log("Linear speed limit reached!");
      if (turn >= turn_limit) console.log("Angular speed limit reached!");
      console.log("currently:\tspeed ", speed, " \tturn ", turn);

      return { ...prevTwistState, speed, turn };
    } else {
      return prevTwistState;
    }
  };

  const handleKeyDown = (event) => {
    const key = event.key;
    if (!controlKeys.has(key)) return;

    console.log("Key Down: ", key);

    setStateData((prevState) => {
      const prevKeys = prevState.keysPressed;
      const prevStateData = prevState.twistData;

      const newKeys = new Set([...prevKeys, event.key]);
      let newTwistData = getUpdatedTwistState(prevStateData, key);
      return { keysPressed: newKeys, twistData: newTwistData };
    });

    event.preventDefault();
  };

  const handleKeyUp = (event) => {
    const key = event.key;
    if (!controlKeys.has(event.key)) return;
    console.log("Key Up: ", key);

    setStateData((prevState) => {
      const prevKeys = prevState.keysPressed;
      const prevStateData = prevState.twistData;

      const updatedKeys = new Set(prevKeys);
      updatedKeys.delete(key);
      updatedKeys.delete(key.toLowerCase());
      updatedKeys.delete(key.toUpperCase());

      let lastKey = null;
      if (updatedKeys.size > 0) {
        const arr = Array.from(updatedKeys);
        lastKey = arr[arr.length - 1];
      }

      let newTwistData = getUpdatedTwistState(prevStateData, lastKey);
      return { keysPressed: updatedKeys, twistData: newTwistData };

      // return new Set([...prevState, event.key]);
    });
    event.preventDefault();
  };

  const handleKeyspressedCommand = () => {
    const twistData = stateData.twistData;
    const twist = {
      linear: { x: 0, y: 0, z: 0 },
      angular: { x: 0, y: 0, z: 0 },
    };

    twist.linear.x = twistData.x * twistData.speed;
    twist.linear.y = twistData.y * twistData.speed;
    twist.linear.z = twistData.z * twistData.speed;
    twist.angular.x = 0;
    twist.angular.y = 0;
    twist.angular.z = twistData.th * twistData.turn;

    handleMove(twist);
  };

  useEffect(() => {
    console.log("Keys pressed: ", stateData.keysPressed);
    console.log("Twist: ", stateData.twistData);
    if (!onCommandCreated) return;

    handleKeyspressedCommand();
  }, [stateData]);

  return (
    <Fragment>
      <div
        className="robot-control"
        tabIndex={0}
        onKeyDown={enableKeyboard ? handleKeyDown : (evt) => {}}
        onKeyUp={enableKeyboard ? handleKeyUp : (evt) => {}}
      >
        <h4> Keyboard control</h4>
        <div>Moving around keys: q w e a s d z c</div>
        {/* <div>For Holonomic mode (strafing): U I O J K L M '&lt &gt</div> */}
        <div>t : up (+z) </div>
        <div>b : down (-z)</div>
        <div>u/m : increase/decrease max speeds by 10%</div>
        <div>i/, : increase/decrease only linear speed by 10%</div>
        <div>o/. : increase/decrease only angular speed by 10%</div>

        {/* <div className="control-buttons">
          <button className="up" onClick={() => handleMove("forward")}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2 L2 15 L22 15 Z" />
            </svg>
          </button>
          <button className="left" onClick={() => handleMove("left")}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 12 L15 2 L15 22 Z" />
            </svg>
          </button>
          <button className="stop" onClick={handleStop}>
            STOP            
          </button>
          <button className="right" onClick={() => handleMove("right")}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 12 L9 2 L9 22 Z" />
            </svg>
          </button>
          <button className="down" onClick={() => handleMove("backward")}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22 L2 9 L22 9 Z" />
            </svg>
          </button>
        </div> */}
      </div>
    </Fragment>
  );
};

export default TwistKeyboardMoveCommander;
