import { Button, Col, Dropdown, Row, Slider, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { getFetchURL } from "../../common/setting";
import { makeDivResizable } from "../../utils/draggable";
import { log_debug } from "../../utils/logging";
import useFetchState, {
  useFetchStateTriggers,
} from "../../utils/useFetchState";

import RobotPoseSelectionUI from "./ui-commands/mapui-robot-pose-selector";
import {
  createCommand_dock,
  createCommand_move2Pos,
  createCommand_move2Pos_without_theta,
  createCommand_move2Waypoint,
  createCommand_relocate,
  createCommand_relocate_without_theta,
} from "./command-builder";

const mapImagesDir = "/images/maps";
const dockIconPath = mapImagesDir + "/icons/dock.png";
const wayPointIconPath = mapImagesDir + "/icons/waypoint.png";
const goCartRobotIcon = "/images/gocart.jpg";

const GoCartMapViewer = (props) => {
  const robotStatus = props.robotStatus;
  // const robots = props.Robots!==undefined?props.Robots:[];
  const mapId = props.MapId;
  const onRobotCommandCreated =
    props.onRobotCommandCreated === undefined
      ? (robot, command) => {}
      : props.onRobotCommandCreated;

  // const [displaySize, setDisplaySize] = useState({ width: 640, height: 340 });

  const [showRobotPoseSelector, setShowRobotPoseSelector] = useState(false);
  const [robotPoseSelectorDefaultPose, setRobotPoseSelectorDefaultPose] =
    useState({ x: 0, y: 0, theta: 0 });
  // const [showRobotPoseSelector, setShowRelocationCommandBuilderUI] =
  // useState(false);

  const [mapDisplayOptions, setMapDisplayOptions] = useState({
    ZoomRatio: 1.0,
    Origin: { x: 0, y: 0 },
  });

  const [mapInfo, setMapInfo] = useState(null);

  const [activeRobot, setActiveRobot] = useState(null);
  let robots = [];

  const fetchMapURL = getFetchURL("/maps/" + mapId);
  // const fetchMapURL =
  //   "http://" + window.location.hostname + ":8180/maps/" + mapId;

  const {
    data: mapData,
    error: mapDataError,
    isPending: isPendingMap,
  } = useFetchStateTriggers(fetchMapURL, [mapId]);

  const canvasMap = useRef(null);
  const imageMap = useRef(null);

  const mapImagesCaches = useRef({});

  const getImageCache = (imgPath) => {
    const cache = mapImagesCaches.current;

    if (!cache.hasOwnProperty(imgPath)) {
      console.log("Cache: ", cache);
      console.log("Load image: ", imgPath);
      const img = new Image();
      img.src = imgPath;
      cache[imgPath] = img;
    }

    return cache[imgPath];
  };
  // const [imageMapData, setImageMapData] = useState(null);

  // const [mapOriginalImageData, setMapOriginalImageData] = useState(null);

  const getScale = () => {
    const canvas = canvasMap.current;
    const widthScale = canvas.width / mapInfo.Size.width;
    const heightScale = canvas.height / mapInfo.Size.height;
    return Math.min(widthScale, heightScale);
  };

  const transformMapLocation2DrawingPos = ({ x: locX, y: locY }) => {
    const mapPosInPixel = {
      x: locX * mapInfo.Scale_m2px,
      y: locY * mapInfo.Scale_m2px,
    };

    return {
      x:
        (mapInfo.Origin.x + mapPosInPixel.x + mapDisplayOptions.Origin.x) *
        getScale() *
        mapDisplayOptions.ZoomRatio,

      y:
        (mapInfo.Origin.y - mapPosInPixel.y + mapDisplayOptions.Origin.y) *
        getScale() *
        mapDisplayOptions.ZoomRatio,
    };
  };

  const transformDrawingPos2MapLocation = ({ x: mapX, y: mapY }) => {
    const locX =
      mapX / mapDisplayOptions.ZoomRatio / getScale() -
      mapInfo.Origin.x -
      mapDisplayOptions.Origin.x;
    const locY = -(
      mapY / mapDisplayOptions.ZoomRatio / getScale() -
      mapInfo.Origin.y -
      mapDisplayOptions.Origin.y
    );
    const locInMeter = {
      x: locX / mapInfo.Scale_m2px,
      y: locY / mapInfo.Scale_m2px,
    };
    return locInMeter;
  };

  const convertMeters2Pixels = (w) => {
    return w * mapInfo.Scale_m2px;
  };

  useEffect(() => {
    if (!mapData) return;

    const mapImgPath = mapImagesDir + "/" + mapData["file_name"];
    const chargingStations = mapData["resources"]["charging-stations"];
    const waypoints = mapData["resources"]["waypoints"];
    const zones = mapData["resources"]["zones"];

    const mapSize = {
      width: mapData["metadata"]["gridmap"]["width"],
      height: mapData["metadata"]["gridmap"]["height"],
    };

    const mapScale_m2px = mapData["metadata"]["gridmap"]["scale_m2px"];

    const mapOrigin = mapData["metadata"]["gridmap"]["origin"];

    const canvas = canvasMap.current;

    const map = {
      Origin: mapOrigin,
      ImagePath: mapImgPath,
      MapImageData: null,
      Size: mapSize,
      Scale_m2px: mapScale_m2px,
      // Scale: scale,
      ChargingStations: chargingStations,
      WayPoints: waypoints,
      Zones: zones,
    };
    // console.log("SetMapInfo: ", map);
    setMapInfo(map);
    imageMap.current = null;
    // displayMap();
  }, [mapData]);

  useEffect(() => {
    if (!mapData) return;
    // console.log("MapViewer:: mapData: ", mapData);
    displayMap();
  }, [mapInfo, mapDisplayOptions]);

  useEffect(() => {
    if (robotStatus == null) return;

    if (robots.length == 0) {
      robots.push(robotStatus);
    } else {
      robots[0] = robotStatus;
    }

    setActiveRobot(robots[0]);

    displayMap();
  }, [robotStatus]);

  const displayMap = () => {
    // console.log("displayMap(), mapData is null? ", mapData == null);
    if (mapData == null) {
      console.log("displayMap(), mapData is null ");
      return;
    }

    const canvas = canvasMap.current;
    // // draw map image
    // const ctx = canvas.getContext("2d");

    let img = imageMap.current;
    if (img == null) {
      img = new Image(mapInfo.Size.width, mapInfo.Size.height);
      img.src = mapInfo.ImagePath;
      imageMap.current = img;
    }
    if (img.complete) {
      // console.log("Redraw map");
      drawMap(canvas, img);
    } else {
      // console.log("Draw map");
      img.onload = () => {
        // console.log("Map Image loaded");
        drawMap(canvas, img);
      };
    }
  };

  // Drawing the map
  const drawMap = (canvas, img) => {
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (img.naturalWidth === 0) {
      ctx.fillStyle = "WhiteSmoke"; // Set the background color
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const font_size = 48 * mapDisplayOptions.ZoomRatio;
      ctx.font = font_size + "px Arial";
      ctx.fillStyle = "red";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        "[Map image file not found!!!]",
        canvas.width / 2,
        50 + font_size
      );
    } else {
      ctx.drawImage(
        img,
        -mapDisplayOptions.Origin.x,
        -mapDisplayOptions.Origin.y,
        mapInfo.Size.width / mapDisplayOptions.ZoomRatio,
        mapInfo.Size.height / mapDisplayOptions.ZoomRatio,
        0,
        0,
        Math.min(mapInfo.Size.width * getScale(), canvas.width),
        Math.min(mapInfo.Size.height * getScale(), canvas.height)
      );
    }

    // console.log("Debug: Draw map: 3");

    displayChargingStations(ctx);

    displayWayPoints(ctx);

    displayRobots(ctx);
  };

  const displayRobots = (ctx) => {
    for (let i = 0; i < robots.length; i++) {
      const rb = robots[i];
      displayRobot(rb, ctx);
    }
  };

  const getGocartRobotIcon = (robot) => {
    let filePath = "/images/gocart/status/idle.png";
    if (robot == null) return filePath;

    if (robot.status === "idle") filePath = "/images/gocart/status/idle.png";
    else if (robot.status === "busy" || robot.status === "busy_self")
      filePath = "/images/gocart/status/busy.png";
    else if (robot.status === "error")
      filePath = "/images/gocart/status/error.png";
    else filePath = "/images/gocart/status/warning.png";
    // console.log("Robot icon: ", filePath);
    return filePath;
  };

  const displayRobot = (robot, ctx) => {
    // console.log("DisplayRobot: ", robot);
    if (robot.pose == null) {
      console.log("Robot.pose is null");
      return;
    }

    let robotPose = robot.pose;
    const size = { width: robot.width, height: robot.length };

    if (showRobotPoseSelector) {
      robotPose = robotPoseSelectorDefaultPose;
      // console.log("temp pose: ", robotPose);
    } else {
      robotPose = robot.pose;
      // console.log("robot pose: ", robotPose);
    }

    const iconPath = getGocartRobotIcon(robot);
    // const img = new Image();
    // img.src = iconPath;
    // console.log("icon: ", iconPath);

    // const img = getImageCache(iconPath);
    // if (img.complete) {
    //   drawMapIcon(ctx, img, size, robotPose);
    // } else {
    //   img.onload = () => {
    //     drawMapIcon(ctx, img, size, robotPose);
    //   };
    // }

    drawIconOnMap(ctx, iconPath, size, robotPose);

    if (robot.path_plan != null) {
      const globalPathPlan = robot.path_plan.global;
      const localPathPlan = robot.path_plan.local;
      const lineWidth = convertMeters2Pixels(0.1) * mapDisplayOptions.ZoomRatio;
      drawPathPlan(ctx, globalPathPlan, "#0000ff", lineWidth);
      drawPathPlan(ctx, localPathPlan, "#ff0000", lineWidth);
    }
  };

  const drawIconOnMap = (ctx, imgPath, size, pose) => {
    const img = getImageCache(imgPath);
    if (img.complete) {
      drawMapIcon(ctx, img, size, pose);
    } else {
      img.onload = () => {
        drawMapIcon(ctx, img, size, pose);
      };
    }
  };

  const drawMapIcon = (ctx, img, size, pose) => {
    const drawPos = transformMapLocation2DrawingPos(pose);
    const scaled_theta = -pose.theta - Math.PI / 2;
    const [width, height] = [
      convertMeters2Pixels(size.width) * mapDisplayOptions.ZoomRatio,
      convertMeters2Pixels(size.height) * mapDisplayOptions.ZoomRatio,
    ];

    // console.log(
    //   "Draw image at: ",
    //   { x: drawPos.x, y: drawPos.y, theta: scaled_theta },
    //   "Size: ",
    //   size
    // );

    const x = drawPos.x - width / 2.0; // x position of the image
    const y = drawPos.y - height / 2.0; // y position of the image
    const w = width; // width of the image
    const h = height; // height of the image
    const theta = scaled_theta; // angle to rotate the image (in degrees)
    ctx.translate(x + w / 2, y + h / 2);
    // Rotate the canvas by theta degrees
    ctx.rotate(theta);
    // Draw the image with its center at the canvas origin (0,0)
    ctx.drawImage(img, -w / 2, -h / 2, w, h);
    // Restore the canvas transformation matrix to its original state
    ctx.setTransform(1, 0, 0, 1, 0, 0);
  };

  const drawPathPlan = (ctx, pathPlan, color, lineWidth) => {
    if (pathPlan == null || pathPlan.length == 0) return;

    const transformedPathPlan = pathPlan.map((item) => {
      return transformMapLocation2DrawingPos(item);
    });

    ctx.beginPath();
    ctx.lineWidth = lineWidth;
    // ctx.setLineDash([5, 15]);
    ctx.strokeStyle = color;
    ctx.moveTo(transformedPathPlan[0].x, transformedPathPlan[0].y);
    for (let i = 1; i < transformedPathPlan.length; i++) {
      ctx.lineTo(transformedPathPlan[i].x, transformedPathPlan[i].y);
    }
    ctx.stroke();
  };

  const displayChargingStations = (ctx) => {
    //display dock
    // console.log("# of chargingStations: ", mapInfo.ChargingStations.length);

    // const canvas = canvasMap.current;
    // // draw map image
    // const ctx = canvas.getContext("2d");

    const img = new Image();
    img.src = dockIconPath;

    img.onload = () => {
      for (let i = 0; i < mapInfo.ChargingStations.length; i++) {
        const station = mapInfo.ChargingStations[i];
        if (!station["resource_active"]) continue;
        drawChargingStation(station, ctx, img);
      }
    };
  };

  const drawChargingStation = (station, ctx, img) => {
    const pose = station["pose"];
    const drawPos = transformMapLocation2DrawingPos(pose);
    const [cs_width, cs_height] = [
      convertMeters2Pixels(1) * mapDisplayOptions.ZoomRatio,
      convertMeters2Pixels(1) * mapDisplayOptions.ZoomRatio,
    ];

    // console.log("Dock org pose: ", pose);
    // console.log("Dock draw pose: ", drawPos);
    ctx.drawImage(
      img,
      drawPos.x - cs_width / 2.0,
      drawPos.y - cs_height / 2.0,
      cs_width,
      cs_height
    );
  };

  const displayWayPoints = (ctx) => {
    //display dock
    // console.log("# of WayPoints: ", mapInfo.WayPoints.length);

    // const canvas = canvasMap.current;
    // // draw map image
    // const ctx = canvas.getContext("2d");

    // const img = new Image();
    // img.src = wayPointIconPath;

    // img.onload = () => {
    //   for (let i = 0; i < mapInfo.WayPoints.length; i++) {
    //     const wayPoint = mapInfo.WayPoints[i];
    //     if (!wayPoint["resource_active"]) continue;
    //     drawWayPoint(wayPoint, ctx, img);
    //   }
    // };

    for (let i = 0; i < mapInfo.WayPoints.length; i++) {
      const wayPoint = mapInfo.WayPoints[i];
      if (!wayPoint["resource_active"]) continue;
      drawWayPoint2(wayPoint, ctx);
    }
  };

  const drawWayPoint2 = (wayPoint, ctx) => {
    const pose = wayPoint["pose"];
    const size = { width: 1.5, height: 1.5 };
    drawIconOnMap(ctx, wayPointIconPath, size, pose);
  };

  const drawWayPoint = (wayPoint, ctx, img) => {
    // console.log("waypoint: ", wayPoint);
    const pose = wayPoint["pose"];
    drawMapIcon(ctx, img, { width: 1.5, height: 1.5 }, pose);
    // const drawPos = transformMapLocation2DrawingPos(pose);
    // const [width, height] = [
    //   convertMeters2Pixels(1.5) * mapDisplayOptions.ZoomRatio,
    //   convertMeters2Pixels(1.5) * mapDisplayOptions.ZoomRatio,
    // ];

    // ctx.drawImage(
    //   img,
    //   drawPos.x - width / 2.0,
    //   drawPos.y - height / 2.0,
    //   width,
    //   height
    // );
  };

  const getWayPointsAtLocation = (x, y) => {
    let items = [];
    for (let i = 0; i < mapInfo.WayPoints.length; i++) {
      const wayPoint = mapInfo.WayPoints[i];
      if (!wayPoint["resource_active"]) continue;

      const pose = wayPoint["pose"];
      const { x: wp_x, y: wp_y } = transformMapLocation2DrawingPos(pose);
      const [width, height] = [
        (convertMeters2Pixels(2) * mapDisplayOptions.ZoomRatio) / 2.0,
        (convertMeters2Pixels(2) * mapDisplayOptions.ZoomRatio) / 2.0,
      ];
      // checkif the location is inside the draw point
      if (
        x >= wp_x - width &&
        x <= wp_x + width &&
        y > wp_y - height &&
        y <= wp_y + height
      ) {
        items.push(wayPoint);
      }
    }
    return items;
  };

  const getChargeStationsAtLocation = (x, y) => {
    let result = [];
    for (let i = 0; i < mapInfo.ChargingStations.length; i++) {
      const chargeStation = mapInfo.ChargingStations[i];
      if (!chargeStation["resource_active"]) continue;

      const pose = chargeStation["pose"];
      const { x: wp_x, y: wp_y } = transformMapLocation2DrawingPos(pose);
      const [width, height] = [
        (convertMeters2Pixels(2) * mapDisplayOptions.ZoomRatio) / 2.0,
        (convertMeters2Pixels(2) * mapDisplayOptions.ZoomRatio) / 2.0,
      ];
      // checkif the location is inside the draw point
      if (
        x >= wp_x - width &&
        x <= wp_x + width &&
        y > wp_y - height &&
        y <= wp_y + height
      ) {
        result.push(chargeStation);
      }
    }
    return result;
  };

  const getMapPointAtLocation = (x, y) => {
    return transformDrawingPos2MapLocation({ x, y });
  };

  const goToChargeStationMenuClicked = (chargeStation) => {
    console.log("goToChargeStationMenuClicked(): ", chargeStation);
    const command = createCommand_dock(chargeStation);
    onRobotCommandCreated(activeRobot, command);
  };

  const goToWaypointMenuClicked = (waypoint) => {
    console.log("goToWaypointMenuClicked(): ", waypoint);

    const command = createCommand_move2Waypoint(waypoint);

    onRobotCommandCreated(activeRobot, command);
  };

  const goToPointMenuClicked = ({ x, y }) => {
    console.log("goToPointMenuClicked(): ", { x, y });
    const { x: real_x, y: real_y } = transformDrawingPos2MapLocation({ x, y });
    console.log("real point value: ", { real_x, real_y });
    const command = createCommand_move2Pos_without_theta(real_x, real_y);
    onRobotCommandCreated(activeRobot, command);
  };

  // const relocateToPointMenuClicked = ({ x, y }) => {
  //   console.log("RelocateToPointMenuClicked(): ", { x, y });
  //   const { x: real_x, y: real_y } = transformDrawingPos2MapLocation({ x, y });
  //   console.log("real point value: ", { real_x, real_y });
  //   const command = createCommand_relocate_without_theta(real_x, real_y);
  //   onRobotCommandCreated(activeRobot, command);
  // };

  const relocateToPointMenuClicked = ({ x, y }) => {
    if (!activeRobot) {
      console.log("Active Robot is null: ");

      return;
    }
    const map_pose = transformDrawingPos2MapLocation({ x, y });
    const pose = { ...map_pose, theta: activeRobot.pose.theta };
    setRobotPoseSelectorDefaultPose(pose);
    setShowRobotPoseSelector(true);
  };

  const onRobotPoseSelectorConfirmed = ({ x, y, theta }) => {
    console.log("Relocated robot to: ", { x, y, theta });
    const command = createCommand_relocate(x, y, theta);
    console.log("Command: ", command);
    setShowRobotPoseSelector(false);
    onRobotCommandCreated(activeRobot, command);
  };

  const onRobotPoseSelectorCancled = () => {
    setShowRobotPoseSelector(false);
  };
  const onRobotPoseSelectorValueChanged = ({ x, y, theta }) => {
    setRobotPoseSelectorDefaultPose({ x, y, theta });
  };

  const getContextMenuItemsForChargeStations = (chargeStations) => {
    const dockPointItems = chargeStations.map((chargeStation, index) => {
      return {
        key: chargeStation.id,
        label: "GoTo: " + chargeStation.name,
        onClick: () => goToChargeStationMenuClicked(chargeStation),
      };
    });
    const result = [
      {
        key: "chargingStation",
        // type: "group",
        label: "Charging Dock",
        children: dockPointItems,
      },
    ];
    return result;
  };

  const getContextMenuItemsForWayPoint = (waypoints) => {
    const wayPointItems = waypoints.map((waypoint, index) => {
      return {
        key: waypoint.id,
        label: "GoTo: " + waypoint.name,
        onClick: () => goToWaypointMenuClicked(waypoint),
      };
    });
    const result = [
      {
        key: "waypoint",
        // type: "group",
        label: "Waypoints",
        children: wayPointItems,
      },
    ];
    return result;
  };

  const getContextMenuItemsForMapGeneralPoint = ({ x, y }) => {
    const items = [
      {
        key: "point{" + x + "," + y + "}",
        // type: "group",
        label: "Move To Here",
        onClick: () => goToPointMenuClicked({ x, y }),
      },
      {
        key: "relocation{" + x + "," + y + "}",
        // type: "group",
        label: "Relocate Here",
        onClick: () => relocateToPointMenuClicked({ x, y }),
      },
    ];
    return items;
  };

  // const [mapRightClickPosition, setMapRightClickPosition] = useState(null);
  const [popupContextMenuState, setPopupContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
    contextObj: null,
    menuItems: [],
  });

  const canvasMap_OnContextMenu = (event) => {
    console.log("context menu: ", event);
    event.preventDefault();

    const canvas_tag = canvasMap.current;
    const rect = canvas_tag.getBoundingClientRect();
    const pickpoint_x = Math.round(event.clientX - rect.left);
    const pickpoint_y = Math.round(event.clientY - rect.top);
    const map_x = (pickpoint_x * canvas_tag.width) / rect.width;
    const map_y = (pickpoint_y * canvas_tag.height) / rect.height;

    let contextMenuItems = [];
    const mapPoint = getMapPointAtLocation(map_x, map_y);
    if (mapPoint != null) {
      const pointMenu = getContextMenuItemsForMapGeneralPoint({
        x: map_x,
        y: map_y,
      });
      console.log("pointMenu: ", pointMenu);
      contextMenuItems = [...contextMenuItems, ...pointMenu];
    }
    console.log("menuItems: ", contextMenuItems);

    const clickedWayPoints = getWayPointsAtLocation(map_x, map_y);
    if (clickedWayPoints != null && clickedWayPoints.length > 0) {
      const wayPointMenu = getContextMenuItemsForWayPoint(clickedWayPoints);
      contextMenuItems = [...contextMenuItems, ...wayPointMenu];
    }
    console.log("menuItems: ", contextMenuItems);

    const clickedChargeStations = getChargeStationsAtLocation(map_x, map_y);
    if (clickedChargeStations != null && clickedChargeStations.length > 0) {
      const chargeStationMenu = getContextMenuItemsForChargeStations(
        clickedChargeStations
      );
      contextMenuItems = [...contextMenuItems, ...chargeStationMenu];
    }

    if (contextMenuItems.length == 0) {
      setPopupContextMenuState({ ...popupContextMenuState, visible: false });
      return;
    }

    const popupState = {
      visible: true,
      x: event.clientX,
      y: event.clientY,
      contextObj: mapPoint,
      menuItems: contextMenuItems,
    };
    console.log("popupState: ", popupState);
    setPopupContextMenuState(popupState);
  };

  const popupOnOpenChange = (e) => {
    console.log("popupOnOpenChange:", e);
    setPopupContextMenuState({ ...popupContextMenuState, visible: e });
  };

  const popupDropdownRender = (menus) => {
    console.log("popupDropdownRender:", menus);
    return menus;
  };

  const canvasOnWheel = (evt) => {
    const canvas = canvasMap.current;
    if (canvas == null) return;
    if (!evt.altKey) return;
    evt.preventDefault();
    var mousex = evt.clientX - canvas.offsetLeft;
    var mousey = evt.clientY - canvas.offsetTop;
    var wheel = -evt.deltaY / 120; //n or -n

    const zoomFactor = Math.pow(1 + Math.abs(wheel) / 2, wheel > 0 ? 1 : -1);

    setMapDisplayOptions((prevVal) => {
      const prevZoomRatio = prevVal.ZoomRatio;
      const newZoomRatio = Math.max(prevZoomRatio * zoomFactor, 1);

      const prevOrigin = prevVal.Origin;

      const newOriginX =
        (mousex / getScale()) * (1 / newZoomRatio - 1 / prevZoomRatio) +
        prevOrigin.x;

      const newOriginY =
        (mousey / getScale()) * (1 / newZoomRatio - 1 / prevZoomRatio) +
        prevOrigin.y;

      const newOrig = {
        x: newOriginX,
        y: newOriginY,
      };

      return { ZoomRatio: newZoomRatio, Origin: newOrig };
    });
  };

  function handleMouseDown(e) {
    // console.log("map canvas mouseDown");
    e.preventDefault();
    const startX = e.clientX;
    const startY = e.clientY;
    let isPanding = true;

    function handleMouseMove(e) {
      if (!isPanding) return;
      e.preventDefault();
      const dx = e.clientX - startX;
      const dy = e.clientY - startY;

      const newX = mapDisplayOptions.Origin.x + dx;
      const newY = mapDisplayOptions.Origin.y + dy;

      setMapDisplayOptions({
        ...mapDisplayOptions,
        Origin: {
          x: newX,
          y: newY,
        },
      });
    }

    function handleMouseUp(e) {
      if (!isPanding) return;
      e.preventDefault();
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      isPanding = false;
    }

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }

  const divMapContainer = useRef(null);
  useEffect(() => {
    if (divMapContainer.current == null) return;
    makeDivResizable(divMapContainer.current);
  }, []);

  useEffect(() => {
    const canvas = canvasMap.current;
    if (canvas != null) canvas.addEventListener("wheel", canvasOnWheel, true);

    return () => {
      if (canvas != null)
        canvas.removeEventListener("wheel", canvasOnWheel, true);
    };
  }, [mapInfo]);

  return (
    <div id="media" className="panel-resizable" ref={divMapContainer}>
      {isPendingMap && <div>Loading map...!</div>}
      {mapDataError && <div>Map loading error: {mapDataError}</div>}
      {!mapInfo && <div>Map data is null</div>}
      {mapInfo && (
        <div id="divMapContainer">
          <Dropdown
            menu={{
              items: popupContextMenuState.menuItems,
            }}
            trigger={["contextMenu"]}
            // open={popupContextMenuState.visible}
            // align={{
            //   offset: [popupContextMenuState.x, popupContextMenuState.y],
            // }}
            // trigger={["click"]}
            // onOpenChange={popupOnOpenChange}
            // dropdownRender={popupDropdownRender}
          >
            <canvas
              style={{ borderStyle: "dashed", width: "100%" }}
              ref={canvasMap}
              id="canvas_map"
              // width={mapInfo?.Size.width ?? 680}
              // height={mapInfo?.Size.height ?? 420}
              width={mapInfo.Size.width}
              height={mapInfo.Size.height}
              // style="border:1px solid #000000;"
              onContextMenu={canvasMap_OnContextMenu}
              onMouseDown={handleMouseDown}
              // onClick={mapCanvasClick}
              // onWheel={canvasOnWheel}
            >
              Sorry, your browser doesn't support the &lt;canvas&gt; element.
            </canvas>
          </Dropdown>
          <Row>
            <Col span={2} style={{ textAlign: "left" }}>
              <label>Zoom:</label>
            </Col>
            <Col span={14}>
              <Slider
                // defaultValue={1}
                min={1}
                max={100}
                value={(mapDisplayOptions.ZoomRatio - 1) * 10}
                // tooltip={{
                //   open: true,
                // }}
                onChange={(val) => {
                  setMapDisplayOptions({
                    ...mapDisplayOptions,
                    ZoomRatio: val / 10 + 1,
                  });
                }}
              />
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    setMapDisplayOptions({
                      ...mapDisplayOptions,
                      ZoomRatio: 1,
                      Origin: { x: 0, y: 0 },
                    });
                  }}
                >
                  Reset map
                </Button>
              </Space>
            </Col>
          </Row>

          <RobotPoseSelectionUI
            Title="Relocation"
            Pose={robotPoseSelectorDefaultPose}
            Show={showRobotPoseSelector}
            OnValueChanged={onRobotPoseSelectorValueChanged}
            OnConfirmed={onRobotPoseSelectorConfirmed}
            OnCanceled={onRobotPoseSelectorCancled}
          ></RobotPoseSelectionUI>
        </div>
      )}
    </div>
  );
};

export default GoCartMapViewer;
