import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useAuth } from "./AuthProvider";
import toast from "react-hot-toast";
import { useLocation, useHistory } from "react-router-dom";
import {
  initialMissionsData,
  robotMissionsDataReducer,
} from "../reducer/robotMissionsDataReducer";
import { getUserRobotMissionListService } from "../services/mission-services";

const RobotMissionsDataContext = createContext();

export function RobotMissionsProvider({ children }) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState("");
  const [robotMissionsDataState, dispatch] = useReducer(
    robotMissionsDataReducer,
    initialMissionsData
  );

  const { auth } = useAuth();

  const history = useHistory();
  const location = useLocation();

  const getRobotMissionList = async () => {
    if (auth.isAuth) {
      try {
        setDataLoading(true);
        const response = await getUserRobotMissionListService(auth.token);
        dispatch({ type: "SET_MISSIONLIST", payload: response.data });
      } catch (error) {
        console.error(error);
      } finally {
        setDataLoading(false);
      }
    } else {
      toast("Please login first!");
      history.push({
        pathname: "/login",
        state: { from: location },
      });
    }
  };

  useEffect(() => {
    getRobotMissionList();
  }, [auth]);

  return (
    <RobotMissionsDataContext.Provider
      value={{
        robotMissionsDataState,
        error,
        dispatch,
        dataLoading,
        getRobotMissionList,
      }}
    >
      {children}
    </RobotMissionsDataContext.Provider>
  );
}

export const useRobotMissionsData = () => useContext(RobotMissionsDataContext);
