import * as React from "react";
import { Link } from "react-router-dom";

const handleSensorClick = (sensor) => {
  console.log("sensor clicked: ", sensor);
};

const SensorsList = (props) => {
  const sensors = props.sensors;
  const onSensorClick = props.onSensorClick;

  console.log("Robots-list component render");
  console.log("Robots count: ", sensors.length);
  return (
    <div className="table-responsive">
      {/* <h3>Available sensors</h3> */}
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Sensor Id</th>
            <th scope="col">Name</th>
            <th scope="col">Status</th>
            <th scope="col">Type</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {sensors &&
            sensors.map((sensor, index) => (
              <tr key={sensor.id}>
                <td>{index}</td>
                <td>
                  <Link to="#" onClick={() => onSensorClick(sensor)}>
                    {sensor.id}
                  </Link>
                </td>
                <td>{sensor.name}</td>
                <td>{sensor.status}</td>
                <td>{sensor.type}</td>
                <td>
                  <button className="btn btn-primary btn-sm">Callibrate</button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SensorsList;
