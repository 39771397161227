import "./App.css";

import MenuBar from "./components/menubar";
import RobotsDashBoard from "./pages/robots-dashboard";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import RobotDetails from "./components/robot-details";
import { getConnect2RobotWsURL } from "./common/setting";
import DeveloperWerRTC from "./components/debugging/dev-webrtc-viewer";
import DeliveryDashBoard from "./pages/delivery-dashboard";
import WearableRobotReservationDashBoard from "./pages/wearable-robot-reservation-dashboard";
import GocartMapManagerDashboard from "./pages/gocart-map-manager";
import SpotLogViewer from "./pages/spot_log_viewer";
import { Layout } from "antd";
import { useState } from "react";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";

import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import GoCartTeleControlView from "./pages/robot-control-pages/gocart-tele-control-view";
import MissionsDashboard from "./pages/missions-dashboard";
import SpotTeleControlView from "./pages/robot-control-pages/spot-tele-control-view";
import PageRobotCamera from "./pages/robot-camera-view";
import { AuthProvider } from "./contexts/AuthProvider";
import { Login } from "./pages/auth/Login/Login";
import { RequiresAuth } from "./components/auth/RequiresAuth";
import { ServerConnectionProvider } from "./contexts/ServerConnectionContext";
import SpotTeleControlView_NEW from "./pages/robot-control-pages/spot-tele-control-view-new";
import GoCartTeleControlView_NEW from "./pages/robot-control-pages/gocart-tele-control-view-new";
import MissionsDashboardAdmin from "./pages/missions-dashboard-admin";
import { UserProfileEditPage } from "./pages/auth/Login/UserProfileEdit";
import SysconTeleControlView from "./pages/robot-control-pages/syscon-tele-control-view";
import UserRobotsDashBoard from "./pages/robots-dashboard-user";
import { UserProvider } from "./contexts/UserDataProvider";
import { RobotMissionsProvider } from "./contexts/RobotMissionsDataProvider";
import MissionsDashboardUser from "./pages/missions-dashboard-user";
import Demobot1TeleControlView from "./demobot1/pages/demobot1-tele-control-view";
import Demobot2TeleControlView from "./demobot2/pages/demobot2-tele-control-view";
import { RobotPeerConnectionProvider } from "./contexts/RobotConnectionContext";
import SpotJobListManager from "./components/spot/spot-job-list-manager";
import DemobotControlPage from "./demobot/pages/demobot-control-page";
import { Signup } from "./pages/auth/Signup/Signup";

function App() {
  console.log("App - render");

  const searchParams = new URLSearchParams(window.location.search);
  const qsHideMenuBar =
    searchParams.has("hide-menu-bar") &&
    searchParams.get("hide-menu-bar").trim().toLocaleLowerCase() == "true";

  const qsStatsEnabled = searchParams.has("show-stats")
    ? searchParams.get("show-stats").trim().toLocaleLowerCase() == "true"
    : null;
  const qsLocalMicAllowed = searchParams.has("local-mic")
    ? searchParams.get("local-mic").trim().toLocaleLowerCase() == "true"
    : null;
  const qsRemoteMicAllowed = searchParams.has("remote-mic")
    ? searchParams.get("remote-mic").trim().toLocaleLowerCase() == "true"
    : null;
  const qsRemoteCameraAllowed = searchParams.has("remote-cam")
    ? searchParams.get("remote-cam").trim().toLocaleLowerCase() == "true"
    : null;
  const qsTrickleICE = searchParams.has("trickle-ice")
    ? searchParams.get("trickle-ice").trim().toLocaleLowerCase() == "true"
    : null;

  const [collapsed, setCollapsed] = useState(false);

  const zeroWidthTriggerStyle = {
    top: 35,
    right: collapsed ? -15 : 0,
    width: 24,
    height: 24,
    // borderRadius: "50%",
    transition: "all 0.3s",
    // background: "#001529",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    cursor: "pointer",
  };

  return (
    <Router>
      <AuthProvider>
        <ServerConnectionProvider wsUrl={getConnect2RobotWsURL()}>
          <Layout>
            {!qsHideMenuBar && (
              <Sider
                theme="dark"
                trigger={
                  collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                }
                zeroWidthTriggerStyle={zeroWidthTriggerStyle}
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                breakpoint="lg"
                collapsedWidth="0"
              >
                <div
                  style={{
                    height: 32,
                    margin: 16,
                    // background: "rgba(255, 255, 255, 0.2)",
                  }}
                >
                  {!collapsed && (
                    <Link
                      className="navbar-brand px-0 fs-6"
                      style={{
                        // height: 32,
                        margin: 6,
                        color: "rgba(255, 255, 255, 0.9)",
                        background: "rgba(255, 255, 255, 0.2)",
                      }}
                      to="/"
                    >
                      KETI RUBBERNECK
                    </Link>
                  )}
                </div>
                <MenuBar />
              </Sider>
            )}

            <Layout>
              {/* <Header theme="dark">
            <NavBar />
          </Header> */}
              <Content>
                {/* <div className="App">
              <NavBar />
              <div className="container-fluid">
                <div className="row">
                  <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4"> */}
                <Switch>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/signup">
                    <Signup />
                  </Route>
                  <Route path="/user-profile">
                    <RequiresAuth>
                      <UserProfileEditPage />
                    </RequiresAuth>
                  </Route>
                  <Route path="/robots/:id/gocart">
                    <RequiresAuth requiredRoles={["operator"]}>
                      <RobotPeerConnectionProvider
                        StatsEnabled={true}
                        dataChannels={["chat"]}
                      >
                        <GoCartTeleControlView></GoCartTeleControlView>
                      </RobotPeerConnectionProvider>
                    </RequiresAuth>
                  </Route>
                  <Route path="/robots/:id/gocart-new">
                    <RequiresAuth requiredRoles={["operator"]}>
                      <RobotPeerConnectionProvider
                        StatsEnabled={true}
                        dataChannels={["chat"]}
                      >
                        <GoCartTeleControlView_NEW></GoCartTeleControlView_NEW>
                      </RobotPeerConnectionProvider>
                    </RequiresAuth>
                  </Route>
                  <Route path="/robots/:id/syscon">
                    <RequiresAuth requiredRoles={["operator"]}>
                      <RobotPeerConnectionProvider
                        StatsEnabled={true}
                        dataChannels={["chat"]}
                      >
                        <SysconTeleControlView></SysconTeleControlView>
                      </RobotPeerConnectionProvider>
                    </RequiresAuth>
                  </Route>
                  <Route path="/robots/:id/spot-old">
                    <RequiresAuth requiredRoles={["operator"]}>
                      <RobotPeerConnectionProvider
                        StatsEnabled={true}
                        dataChannels={["chat"]}
                      >
                        <SpotTeleControlView></SpotTeleControlView>
                      </RobotPeerConnectionProvider>
                    </RequiresAuth>
                  </Route>
                  <Route path="/robots/:id/spot">
                    <RequiresAuth requiredRoles={["operator"]}>
                      <RobotPeerConnectionProvider
                        StatsEnabled={qsStatsEnabled ?? true}
                        allowLocalAudio={qsLocalMicAllowed ?? true}
                        allowRemoteAudio={qsRemoteMicAllowed ?? true}
                        isReceiveAudio={qsRemoteMicAllowed ?? true}
                        isSendingAudio={qsLocalMicAllowed ?? true}
                        trickleICE={qsTrickleICE ?? true}
                        disableChannelMessageState={true}
                        dataChannels={["chat"]}
                      >
                        <SpotTeleControlView_NEW></SpotTeleControlView_NEW>
                      </RobotPeerConnectionProvider>
                    </RequiresAuth>
                  </Route>
                  <Route path="/robots/:id/tracer">
                    <RequiresAuth requiredRoles={["operator"]}>
                      <RobotPeerConnectionProvider
                        StatsEnabled={qsStatsEnabled ?? true}
                        allowLocalAudio={qsLocalMicAllowed ?? true}
                        allowRemoteAudio={qsRemoteMicAllowed ?? true}
                        isReceiveAudio={qsRemoteMicAllowed ?? true}
                        isSendingAudio={qsLocalMicAllowed ?? true}
                        trickleICE={qsTrickleICE ?? true}
                        disableChannelMessageState={true}
                        dataChannels={["chat"]}
                      >
                        <Demobot1TeleControlView></Demobot1TeleControlView>
                      </RobotPeerConnectionProvider>
                    </RequiresAuth>
                  </Route>
                  <Route path="/robots/:id/demobot2">
                    <RequiresAuth requiredRoles={["operator"]}>
                      <RobotPeerConnectionProvider
                        StatsEnabled={qsStatsEnabled ?? true}
                        allowLocalAudio={qsLocalMicAllowed ?? true}
                        allowRemoteAudio={qsRemoteMicAllowed ?? true}
                        isReceiveAudio={qsRemoteMicAllowed ?? true}
                        isSendingAudio={qsLocalMicAllowed ?? true}
                        trickleICE={qsTrickleICE ?? true}
                        disableChannelMessageState={true}
                        dataChannels={["chat"]}
                      >
                        <Demobot2TeleControlView></Demobot2TeleControlView>
                      </RobotPeerConnectionProvider>
                    </RequiresAuth>
                  </Route>
                  <Route path="/robot-control/:id">
                    <RequiresAuth requiredRoles={["operator"]}>
                      <RobotPeerConnectionProvider
                        StatsEnabled={qsStatsEnabled ?? true}
                        allowLocalAudio={qsLocalMicAllowed ?? true}
                        allowRemoteAudio={qsRemoteMicAllowed ?? true}
                        isReceiveAudio={qsRemoteMicAllowed ?? true}
                        isSendingAudio={qsLocalMicAllowed ?? true}
                        // trickleICE={qsTrickleICE ?? true}
                        // disableChannelMessageState={true}
                        dataChannels={["chat"]}
                      >
                        <DemobotControlPage></DemobotControlPage>
                      </RobotPeerConnectionProvider>
                    </RequiresAuth>
                  </Route>
                  <Route path="/robots/:id">
                    <RobotDetails></RobotDetails>
                  </Route>
                  {/* <Route path="/dashboard2">
                    <RobotsDashBoard></RobotsDashBoard>
                  </Route> */}
                  <Route path="/conn-dev/:id">
                    <RobotPeerConnectionProvider
                      StatsEnabled={qsStatsEnabled ?? true}
                      allowLocalAudio={qsLocalMicAllowed ?? true}
                      allowRemoteAudio={qsRemoteMicAllowed ?? true}
                      isReceiveAudio={qsRemoteMicAllowed ?? true}
                      isSendingAudio={qsLocalMicAllowed ?? true}
                      trickleICE={qsTrickleICE ?? true}
                      // disableChannelMessageState={true}
                      dataChannels={["chat"]}
                    >
                      <DeveloperWerRTC></DeveloperWerRTC>
                    </RobotPeerConnectionProvider>
                  </Route>
                  {/* <Route path="/delivery">
                    <RequiresAuth requiredRoles={["log_manager"]}>
                      <DeliveryDashBoard></DeliveryDashBoard>
                    </RequiresAuth>
                  </Route> */}

                  <Route path="/missions-old">
                    <RequiresAuth requiredRoles={["log_manager"]}>
                      <MissionsDashboard></MissionsDashboard>
                    </RequiresAuth>
                  </Route>

                  <Route path="/missions">
                    <RequiresAuth requiredRoles={["log_manager"]}>
                      <RobotMissionsProvider>
                        <MissionsDashboardUser></MissionsDashboardUser>
                      </RobotMissionsProvider>
                    </RequiresAuth>
                  </Route>

                  <Route path="/missions-admin">
                    <RequiresAuth requiredRoles={["log_manager"]}>
                      <MissionsDashboardAdmin></MissionsDashboardAdmin>
                    </RequiresAuth>
                  </Route>
                  <Route path="/wearable-reservation">
                    <RequiresAuth>
                      <WearableRobotReservationDashBoard></WearableRobotReservationDashBoard>
                    </RequiresAuth>
                  </Route>
                  <Route path="/map-manager">
                    <RequiresAuth requiredRoles={["admin"]}>
                      <GocartMapManagerDashboard></GocartMapManagerDashboard>
                    </RequiresAuth>
                  </Route>

                  <Route path="/spot-log">
                    <RequiresAuth requiredRoles={["log_manager"]}>
                      <SpotLogViewer></SpotLogViewer>
                    </RequiresAuth>
                  </Route>

                  <Route path="/robot-camera/:id">
                    <RobotPeerConnectionProvider
                      StatsEnabled={qsStatsEnabled ?? true}
                      allowLocalAudio={qsLocalMicAllowed ?? true}
                      allowRemoteAudio={qsRemoteMicAllowed ?? true}
                      isReceiveAudio={qsRemoteMicAllowed ?? true}
                      isSendingAudio={qsLocalMicAllowed ?? true}
                      trickleICE={qsTrickleICE ?? true}
                      disableChannelMessageState={true}
                      dataChannels={["chat"]}
                    >
                      <PageRobotCamera></PageRobotCamera>
                    </RobotPeerConnectionProvider>
                  </Route>
                  <Route path="/delivery">
                    <DeliveryDashBoard></DeliveryDashBoard>
                  </Route>
                  <Route path="/home">
                    <RobotsDashBoard></RobotsDashBoard>
                  </Route>
                  <Route path="/dashboard">
                    <UserProvider>
                      <UserRobotsDashBoard></UserRobotsDashBoard>
                    </UserProvider>
                  </Route>
                  <Route path="/spot-jobs-manager">
                    <RequiresAuth requiredRoles={["operator", "admin"]}>
                      <SpotJobListManager></SpotJobListManager>
                    </RequiresAuth>
                  </Route>
                  <Route path="/">
                    <UserProvider>
                      <UserRobotsDashBoard></UserRobotsDashBoard>
                    </UserProvider>
                  </Route>
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </ServerConnectionProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
