export function makeDraggable(div) {
  if (div == null) return;
  console.log("Make div draggable:", div.id);
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  div.onmousedown = dragMouseDown;

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // console.log("dis_position before: ",{top:div.style})
    div.style.top = div.offsetTop - pos2 + "px";
    div.style.left = div.offsetLeft - pos1 + "px";
  }

  function closeDragElement() {
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

export function makeDivResizable(resizableDiv) {
  if (resizableDiv == null) return;
  console.log("make resiable for div: ", resizableDiv.id);
  let mouseX, mouseY;
  let isDragging = false;
  const resizeAreaSize = 3;
  let xDirections = 0;
  let yDirections = 0;

  const checkResizeArea = (x, y) => {
    const directions = [];
    const divRect = resizableDiv.getBoundingClientRect();
    if (y <= divRect.top + resizeAreaSize && y >= divRect.top - resizeAreaSize)
      directions.push("n");

    if (
      y <= divRect.bottom + resizeAreaSize &&
      y >= divRect.bottom - resizeAreaSize
    )
      directions.push("s");

    if (
      x <= divRect.left + resizeAreaSize &&
      x >= divRect.left - resizeAreaSize
    )
      directions.push("w");

    if (
      x <= divRect.right + resizeAreaSize &&
      x >= divRect.right - resizeAreaSize
    )
      directions.push("e");

    if (directions.length == 0) return null;

    return directions;
  };

  // Event listener for when the mouse button is pressed down on the div
  resizableDiv.addEventListener("mousedown", (event) => {
    // Record the starting position of the mouse
    mouseX = event.clientX;
    mouseY = event.clientY;
    // console.log("divId='" + resizableDiv.id + "', mousedown");

    const resizeDirs = checkResizeArea(event.clientX, event.clientY);

    if (!resizeDirs) return;

    if (resizeDirs.includes("n")) yDirections = 1;
    else if (resizeDirs.includes("s")) yDirections = -1;

    if (resizeDirs.includes("w")) xDirections = 1;
    else if (resizeDirs.includes("e")) xDirections = -1;

    // resizableDiv.style.cursor = "se-resize";

    // Set isDragging to true
    isDragging = true;
    // Prevent default browser behavior

    event.preventDefault();
  });

  // Event listener for when the mouse moves
  document.addEventListener("mousemove", (event) => {
    // console.log("divId='" + resizableDiv.id + "', mousemove");
    const resizeDirs = checkResizeArea(event.clientX, event.clientY);
    if (resizeDirs) {
      const cursorStyle = resizeDirs.join("");
      resizableDiv.style.cursor = cursorStyle + "-resize";
      // console.log("divId='" + resizableDiv.id + "', cursor:", cursorStyle);
    } else {
      resizableDiv.style.cursor = "auto";
    }

    // If the mouse isn't being dragged, do nothing
    if (!isDragging) {
      return;
    }

    // Calculate the change in mouse position since the last mousemove event
    // const deltaX = (event.clientX - mouseX) * xDirections;
    // const deltaY = (event.clientY - mouseY) * yDirections;

    const deltaX = event.clientX - mouseX;
    const deltaY = event.clientY - mouseY;

    // console.log("xDir: ", xDirections, "yDir: ", yDirections, "delta: ", {
    //   x: deltaX,
    //   y: deltaY,
    // });

    const divRect = resizableDiv.getBoundingClientRect();
    // Resize the div based on the change in mouse position
    resizableDiv.style.width = `${resizableDiv.offsetWidth + deltaX}px`;
    resizableDiv.style.height = `${resizableDiv.offsetHeight + deltaY}px`;

    // Update the starting position of the mouse
    mouseX = event.clientX;
    mouseY = event.clientY;
  });

  // Event listener for when the mouse button is released
  document.addEventListener("mouseup", () => {
    // Set isDragging to false
    isDragging = false;
    xDirections = 0;
    yDirections = 0;
  });
}
