const config = {
  sdpSemantics: "unified-plan",
  iceServers: [
    { urls: "stun:stun.l.google.com:19302" },
    // { urls: "stun:stun.relay.metered.ca:80" },
    // {
    //   urls: "turn:standard.relay.metered.ca:80",
    //   username: "d635e054b6c6f05aeae5e2ec",
    //   credential: "AtVsbBNQX37F9bPs",
    // },
    // {
    //   urls: "turn:standard.relay.metered.ca:80?transport=tcp",
    //   username: "d635e054b6c6f05aeae5e2ec",
    //   credential: "AtVsbBNQX37F9bPs",
    // },
    // {
    //   urls: "turn:standard.relay.metered.ca:443",
    //   username: "d635e054b6c6f05aeae5e2ec",
    //   credential: "AtVsbBNQX37F9bPs",
    // },
    // {
    //   urls: "turns:standard.relay.metered.ca:443?transport=tcp",
    //   username: "d635e054b6c6f05aeae5e2ec",
    //   credential: "AtVsbBNQX37F9bPs",
    // },
  ],
};

export const getDefaultPeerConnectionConfig = () => {
  return config;
};
