import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { getConnect2RobotWsURL } from "../../common/setting";
import { useServerConnection } from "../../contexts/ServerConnectionContext";
import { useRobotPeerConnection } from "../../contexts/RobotConnectionContext";
import VideoTrackViewerNew from "../video-viewer/VideoTrackViewerNew";

const DeveloperWerRTC = () => {
  const { id } = useParams();
  const signalling_url = getConnect2RobotWsURL();

  const txtSendData = useRef(null);
  const txtReceivedData = useRef(null);

  const {
    isOpen: signal_ready,
    isConnecting,
    message: signal_message,
    sendMessage: signal_send,
    subscribe,
    error: signal_Error,
    reconnectCountDown,
  } = useServerConnection();

  const {
    setRobotId,
    connectionState: rtcConnectionState,
    mediaTracks,
    channelMessage,
    channelSend,
    connect,
    // close: closePeerConnection,
    error,
    robotOnlineStatus,
  } = useRobotPeerConnection();

  const [peerMessage, setPeerMessage] = useState(null);

  const handleSendBtnClick = () => {
    if (channelSend == null) {
      alert("data channel is null!");
      return;
    }

    let msg_data = JSON.parse(txtSendData.current.value);
    let msg = JSON.stringify({
      header: { type: "data" },
      body: msg_data,
    });

    const channelLabel = "chat";

    channelSend(channelLabel, msg);
    console.log("command has been sent:", msg_data);
  };

  useEffect(() => {
    setRobotId(id);
  }, [id]);

  useEffect(() => {
    if (!channelMessage) return;
    if (channelMessage.channel == "chat") {
      // const data = JSON.parse(channelMessage.data);
      const msg_data = channelMessage.data;
      txtReceivedData.current.value += msg_data;
    }
  }, [channelMessage]);

  return (
    <div>
      <h1>Go-Cart: {id}</h1>
      <h2>Connection status: {rtcConnectionState} </h2>

      <div id="media">
        <VideoTrackViewerNew mediaTracks={mediaTracks}></VideoTrackViewerNew>
      </div>
      <div className="input-group">
        <button
          type="button"
          id="btnSendData"
          className="btn btn-primary"
          onClick={handleSendBtnClick}
        >
          Send
        </button>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked"
        />
        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
          Json
        </label>
      </div>
      <div className="input-group">
        <span className="input-group-text">Send data</span>
        <textarea
          ref={txtSendData}
          id="txtSendData"
          className="form-control"
          aria-label="Send data"
          style={{ height: "268px" }}
        ></textarea>
      </div>
      <div className="input-group">
        <span className="input-group-text">Received data</span>
        <textarea
          ref={txtReceivedData}
          id="txtReceivedData"
          className="form-control"
          aria-label="Received data"
          style={{ height: "268px" }}
        ></textarea>
        <button type="button" className="btn btn-primary">
          Clear
        </button>
      </div>
    </div>
  );
};

export default DeveloperWerRTC;
