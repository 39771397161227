export function downloadJsonFile(objectData, filename) {
  var jsonData = JSON.stringify(objectData, null, 2);
  var blob = new Blob([jsonData], { type: "application/json" });
  var url = URL.createObjectURL(blob);

  var a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  URL.revokeObjectURL(url);
}

export function downloadCsvFile(csvData, filename) {
  const BOM = "\uFEFF";
  const csvContent = BOM + csvData;
  var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  var url = URL.createObjectURL(blob);

  var a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  URL.revokeObjectURL(url);
}

export function readJsonFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      try {
        const jsonData = JSON.parse(event.target.result);
        resolve(jsonData);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = function (event) {
      reject(new Error("Error reading file."));
    };

    reader.readAsText(file);
  });
}

export const generateNextKey = (existingKeysList) => {
  const keysSet = new Set(existingKeysList);

  let i = 0;
  while (keysSet.has(i)) {
    i++;
  }
  return i;
};

export const jsonToCsv = (jsonArray) => {
  if (!Array.isArray(jsonArray) || jsonArray.length === 0) {
    return "";
  }

  const headers = Array.from(
    new Set(jsonArray.flatMap((obj) => Object.keys(obj)))
  );

  const escapeCsvValue = (value) => {
    if (value == null) return "";

    if (typeof value === "object") {
      value = JSON.stringify(value);
    } else {
      value = String(value);
    }

    if (value.includes('"')) {
      value = value.replace(/"/g, '""');
    }

    if (value.includes(",") || value.includes("\n") || value.includes('"')) {
      value = `"${value}"`;
    }
    return value;
  };

  const csvRows = [];

  csvRows.push(headers.map(escapeCsvValue).join(","));

  for (const obj of jsonArray) {
    const row = headers.map((header) => escapeCsvValue(obj[header] || ""));
    csvRows.push(row.join(","));
  }

  return csvRows.join("\n");
};
