import "./robot-connection-status.css";
import { Alert, Progress, Space, Tooltip } from "antd";
import { Fragment } from "react";
import {
  LoadingOutlined,
  ApiOutlined,
  LinkOutlined,
  DisconnectOutlined,
} from "@ant-design/icons";

const RobotConnectionStatus = (props) => {
  const robot_id = props.RobotId;
  const rtcConnectionState = props.PeerConnectionStatus;
  const serverConnectionStatus = props.ServerConnectionStatus;
  const robotOnlineStatus = props.RobotOnlineStatus;
  const reconnectHandler = props.ReconnectHandler;
  const showAlert = props.ShowAlert;
  const showStatus = props.ShowStatus;

  return (
    <Fragment>
      {showAlert && (
        <div
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
            display:
              rtcConnectionState != "connected" || !robotOnlineStatus
                ? "block"
                : "none",
          }}
        >
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            {serverConnectionStatus &&
              serverConnectionStatus != "connected" && (
                <Alert
                  message={
                    "Server is OFFLINE. Please check the connection to server!"
                  }
                  type="warning"
                  showIcon
                  closable
                />
              )}
            {!robotOnlineStatus && (
              <Alert
                message={
                  "robot '" +
                  robot_id +
                  "' is OFFLINE. The robot is not connected to server"
                }
                type="warning"
                showIcon
                closable
              />
            )}
            {rtcConnectionState &&
              (rtcConnectionState == "failed" ||
                rtcConnectionState == "disconnected") && (
                <Alert
                  message={
                    "Peer connection to the robot is '" +
                    rtcConnectionState +
                    "'. " +
                    (robotOnlineStatus
                      ? "Reconnecting ...!"
                      : "Auto-reconnect when robot is ONLINE")
                  }
                  type="error"
                  showIcon
                  closable
                />
              )}
            {rtcConnectionState &&
              robotOnlineStatus &&
              rtcConnectionState != "failed" &&
              rtcConnectionState != "disconnected" &&
              rtcConnectionState != "connected" && (
                <Alert
                  message={
                    "(Re-)connecting to the robot. Connection state: " +
                    rtcConnectionState
                  }
                  type="error"
                  // action={
                  //   <Space>
                  //     <Button size="small" type="ghost">
                  //       Done
                  //     </Button>
                  //   </Space>
                  // }
                  showIcon
                  closable
                />
              )}
          </Space>
        </div>
      )}
      {showStatus && (
        <div className="connections-status-container">
          <div className="server ">
            <Space>
              Server:{" "}
              {serverConnectionStatus == "connected" ? (
                <Tooltip title="Server is on">
                  <ApiOutlined className="connected" />
                </Tooltip>
              ) : (
                <Tooltip title="Server is off">
                  <DisconnectOutlined className="disconnected" />
                </Tooltip>
              )}
            </Space>
          </div>
          <div>
            <Space>
              Robot:
              {robotOnlineStatus ? (
                <Tooltip title="Robot Online">
                  <ApiOutlined className="connected" />
                </Tooltip>
              ) : (
                <Tooltip title="Robot Offline">
                  <DisconnectOutlined className="disconnected" />
                </Tooltip>
              )}
              {/* {robotOnlineStatus ? "Online" : "Offline"} */}
            </Space>
          </div>
          <div>
            <Space>
              Connection:{" "}
              {rtcConnectionState == "connected" ? (
                <Tooltip title="Connected to robot">
                  <LinkOutlined className="connected" />
                </Tooltip>
              ) : (
                <Tooltip title="Connecting to robot...">
                  <LoadingOutlined className="disconnected" />
                </Tooltip>
              )}{" "}
            </Space>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default RobotConnectionStatus;
