import React, { Component, useContext, useEffect } from "react";
import useFetchState from "../utils/useFetchState";
// import DeliveryJobsList from "./delivery-jobs-list";
import { getDBFetchURL } from "../common/db_setting_jobs";
import {
  Badge,
  Dropdown,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import { getFetchURL } from "../common/setting";
import { DownOutlined } from "@ant-design/icons";
import { useState } from "react";

const items = [
  {
    key: "1",
    label: "Action 1",
  },
  {
    key: "2",
    label: "Action 2",
  },
];

const filters_job_status = [
  {
    text: "Submitted",
    value: "Submitted",
  },
  {
    text: "Rejected",
    value: "Rejected",
  },
  {
    text: "Waiting",
    value: "Waiting",
  },
  {
    text: "Started",
    value: "Started",
  },
  {
    text: "Finished",
    value: "Finished",
  },
];

const filters_job_result = [
  {
    text: "Ok",
    value: "OK",
  },
  {
    text: "Fail",
    value: "Fail",
  },
  {
    text: "NG",
    value: "NG",
  },
  // {
  //   text: "Error",
  //   value: "Error",
  // },
];

const job_result_items = filters_job_result.map((item) => {
  return { label: item.text, value: item.value };
});

const job_status_items = filters_job_status.map((item) => {
  return { label: item.text, value: item.value };
});

const dictTaskStatus = { success: "success", failure: "error" };

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    dataIndex === "Result" ? (
      <Select style={{ width: 80 }} options={job_result_items} />
    ) : dataIndex === "Status" ? (
      <Select style={{ width: 110 }} options={job_status_items} />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const DeliveryDashBoard = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.JobId === editingKey;
  const [jobsPagination, setJobsPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [messageApi, contextHolder] = message.useMessage();

  const displayNotification = (type, msg) => {
    messageApi.open({
      type: type,
      content: msg,
      style: {
        marginTop: "15vh",
      },
    });
  };

  const edit = (record) => {
    form.setFieldsValue({
      Status: "",
      Result: "",
      Events: "",
      ...record,
    });
    setEditingKey(record.JobId);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      console.log("editing key: ", key);
      const row = await form.validateFields();

      console.log("Edited row: ", row);

      const newData = [...jobs];
      const index = newData.findIndex((item) => key === item.JobId);
      if (index > -1) {
        const job = newData[index];
        console.log("Editing job: ", job);

        const updated_item = {
          ...job,
          ...row,
        };
        update_job_to_server(updated_item)
          .then((updated_Job) => {
            if (updated_Job == null) {
              // alert("Job update failed!\nJobId: " + updated_item.JobId);
              displayNotification(
                "error",
                "Job update failed!\nJobId: " + updated_item.JobId
              );
            } else {
              newData.splice(index, 1, updated_item);
              setJobsData(newData);
              setEditingKey("");
              // alert("Job update succeed!\nJobID: " + updated_Job.JobId);
              displayNotification(
                "success",
                "Job update succeed!\nJobID: " + updated_Job.JobId
              );
            }
          })
          .catch((err) => {
            if (err.name === "AbortError") {
            } else {
              alert("Error: " + err.message);
            }
          });
      } else {
        // newData.push(row);
        // setJobsData(newData);

        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const update_job_to_server = async (job) => {
    const url = getFetchURL("/robot-jobs/" + job.JobId);

    const response = await fetch(url, {
      body: JSON.stringify(job),
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
    });
    if (!response.ok) throw Error("Failed to update job");
    return await response.json();
  };

  // const {
  //   data: presets,
  //   error: isPresetsLoadingError,
  //   isPending: isPresetsLoading,
  //   setData: setPresetData,
  // } = useFetchState(getFetchURL("/robot-job-presets"));

  const {
    data: jobs,
    error,
    isPending,
    setData: setJobsData,
  } = useFetchState(getFetchURL("/robot-jobs"));

  const [filtersRobotItems, setFiltersRobotItems] = useState([]);
  useEffect(() => {
    if (jobs == null) return;

    console.log("Jobs size: ", jobs.length);

    let set = new Set();
    for (const job of jobs) {
      set.add(job.RobotId);
    }
    console.log("set size: ", set);

    const filters = [];
    for (const robotId of set) {
      filters.push({ text: robotId, value: robotId });
    }

    console.log("Robot filters list: ", filters);

    setFiltersRobotItems(filters);
  }, [jobs]);

  const getJobEventRowKey = (job_event) => {};

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Time",
        dataIndex: "Time",
        key: "Time",
      },
      {
        title: "Task",
        dataIndex: "TaskId",
        key: "TaskId",
      },
      {
        title: "Event",
        dataIndex: "EventId",
        key: "EventId",
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        render: (st) => <Badge status={dictTaskStatus[st]} text={st} />,
      },
      {
        title: "Battery",
        dataIndex: "Battery",
        key: "Battery",
      },
      // {
      //   title: "Action",
      //   dataIndex: "operation",
      //   key: "operation",
      //   render: () => (
      //     <Space size="middle">
      //       <a>Pause</a>
      //       <a>Stop</a>
      //       <Dropdown
      //         menu={{
      //           items,
      //         }}
      //       >
      //         <a>
      //           More <DownOutlined />
      //         </a>
      //       </Dropdown>
      //     </Space>
      //   ),
      // },
    ];
    // console.log("expandedRowRender: ", record.JobId, record.DeliveryDetail);
    return (
      <Table
        columns={columns}
        dataSource={record["Events"]}
        pagination={false}
        rowKey="Time"
        size="small"
      />
    );
  };

  const rowExpandable = (record) => {
    return record.Events != undefined && record.Events.length > 0;
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (id, record, index) => {
        return (
          (jobsPagination.current - 1) * jobsPagination.pageSize + index + 1
        );
      },
      // sortDirections: ["ascend", "descend"],
      // defaultSortOrder: "descend",
      // sorter: (a, b, sortOrder) => a > b,
    },
    {
      title: "Job ID",
      dataIndex: "JobId",
      key: "JobId",
      // render: (JobId) => <Link to={"/delivery/" + JobId}>{JobId}</Link>,
      // sorter: (a, b) => a > b,
    },
    {
      title: "User",
      dataIndex: "UserId",
      key: "UserId",
    },
    {
      title: "Robot ID",
      dataIndex: "RobotId",
      key: "RobotId",
      filters: filtersRobotItems,
      onFilter: (value, record) => record.RobotId.includes(value),
    },
    {
      title: "CreateTime",
      dataIndex: "CreateTime",
      key: "CreateTime",
    },
    {
      title: "Pickup Location",
      dataIndex: "RoutePaths",
      key: "RoutePaths",
      render: (routePaths) => routePaths[0]["Name"],
    },
    {
      title: "Delivery Location",
      dataIndex: "RoutePaths",
      key: "RoutePaths",
      render: (routePaths) => routePaths[1]["Name"],
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      editable: true,
      filters: filters_job_status,
      onFilter: (value, record) => record.Status == value,
    },
    // {
    //   title: "StartTime",
    //   dataIndex: "StartTime",
    //   key: "StartTime",
    // },
    // {
    //   title: "Finished Time",
    //   dataIndex: "EndTime",
    //   key: "EndTime",
    // },
    {
      title: "Result",
      dataIndex: "Result",
      key: "Result",
      editable: true,
      filters: filters_job_result,
      onFilter: (value, record) => record.Result == value,
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.JobId)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "operation",
    //   key: "operation",
    //   render: () => (
    //     <Space size="middle">
    //       <Dropdown
    //         menu={{
    //           items,
    //         }}
    //       >
    //         <a>
    //           Action <DownOutlined />
    //         </a>
    //       </Dropdown>
    //     </Space>
    //   ),
    // },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "Result" ? "JobResultDropdown" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const tableJobs_onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    setJobsPagination(pagination);
  };

  return (
    <div>
      {contextHolder}
      <h2>Delivery Jobs</h2>
      {error && <div> {error}</div>}
      {isPending && <div>Loading ...</div>}

      {jobs && (
        <div className="table-responsive">
          {/* <DeliveryJobsList Jobs={jobs}></DeliveryJobsList> */}
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={mergedColumns}
              expandable={{
                expandedRowRender,
                // defaultExpandedRowKeys: ["id"],
                // expandRowByClick: true,
                rowExpandable: rowExpandable,
              }}
              dataSource={jobs}
              size="small"
              rowKey="JobId"
              onChange={tableJobs_onChange}
              pagination={{
                onChange: cancel,
              }}
            />
          </Form>
        </div>
      )}
      <div className="table-responsive"></div>
    </div>
  );
};

export default DeliveryDashBoard;
