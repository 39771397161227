import { useEffect, useState } from "react";
import RobotsCard from "../components/robots-card";
import { getFetchURL, getRobotsListFetchURL } from "../common/setting";

import useFetchState from "../utils/useFetchState";
import LogViewer from "../components/common/LogViewer";
import { useServerConnection } from "../contexts/ServerConnectionContext";

const RobotsDashBoard = () => {
  const [connectionLogRecords, setConnectionLogRecords] = useState([]);
  const {
    data: robots,
    error,
    isPending,
    setData,
  } = useFetchState(getRobotsListFetchURL());

  // const [ready, message, send, wsError] = useContext(WebsocketContext);
  // const [ready, val, send, wsError] = error: useWnnect2RobotWsURL());
  const {
    isOpen: ready,
    message,
    sendMessage: send,
    subscribe,
    error: wsError,
  } = useServerConnection();

  useEffect(() => {
    console.log("useEffect message, ", message);
    if (!message) {
      return;
    }
    const data = JSON.parse(message);
    if (data["type"] === "robot_event") {
      const event_data = data["data"];
      const robot = event_data["event_data"];
      if (event_data["event_type"] === "connected") {
        new_robot_connected(robot);
      } else if (event_data["event_type"] === "disconnected") {
        robot_disconnected(robot);
      }
    }
  }, [message]);

  const new_robot_connected = (robot) => {
    console.log("new robot: ", robot);
    addLogRecord(
      "'" + robot.id + "' robot connected. " //+ JSON.stringify(robot)
    );
    if (robots == null) return;

    const robot_id = robot.id;
    const index = robots.findIndex((rb) => {
      return rb.id == robot_id;
    });
    if (index == -1) {
      const arr = [...robots, robot];
      setData(arr);
    } else {
      robots[index] = robot;
      setData(robots);
    }
  };

  const addLogRecord = (msg) => {
    const time = new Date();
    const logRecord = { Time: time, Message: msg };
    setConnectionLogRecords([...connectionLogRecords, logRecord]);
  };

  const robot_disconnected = (robot) => {
    console.log("disconnected robot: ", robot);
    addLogRecord(
      "'" + robot.id + "' robot disconnected. " //+ JSON.stringify(robot)
    );

    if (robots == null) {
      console.log("Warning: robots list is null");
      return;
    }

    const robot_id = robot.id;
    const index = robots.findIndex((rb) => {
      return rb.id === robot_id;
    });
    if (index > -1) {
      robots.splice(index, 1);
      setData([...robots]);
    }
  };

  const restart_robot = (robot) => {
    console.log("restart robot: ", robot);
    const robot_id = robot.id;

    fetch(getFetchURL("/restart-robot"), {
      body: JSON.stringify({
        cmd: "command",
        type: "restart_robot",
        robot_id: robot_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => {
        if (!response.ok)
          throw Error("Could not restart the robot " + robot_id + '"');
        return response.json();
      })
      .then((data) => {
        if (data["result"] === "success") {
          alert("Robot " + robot_id + "is restarting");
          console.log("restarting robot_id: ", robot_id);

          // console.log("robots before delete:\n", robots);
          // const arr = robots.filter((rb) => {
          //   return rb.id !== robot_id;
          // });
          // setData(arr);
          // console.log("robots after delete:\n", arr);
        } else {
          alert("Restart result: " + data["result"] + "\n" + data["msg"]);
        }
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          alert(err.message);
        }
      });
  };

  const shutdown_robot = (robot) => {
    console.log("shutdown robot: ", robot);
    const robot_id = robot.id;

    fetch(getFetchURL("/disconnect"), {
      body: JSON.stringify({
        cmd: "command",
        type: "shutdown_robot",
        robot_id: robot_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => {
        if (!response.ok)
          throw Error("Could not shutdown the robot " + robot_id + '"');
        return response.json();
      })
      .then((data) => {
        if (data["result"] === "success") {
          alert("Robot " + robot_id + "is shutdowned");
          console.log("shutdown robot_id: ", robot_id);

          console.log("robots before delete:\n", robots);
          const arr = robots.filter((rb) => {
            return rb.id !== robot_id;
          });
          setData(arr);
          console.log("robots after delete:\n", arr);
        } else {
          alert("Shutdown result: " + data["result"] + "\n" + data["msg"]);
        }
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          alert(err.message);
        }
      });
  };

  return (
    <div>
      {error && <div> {error}</div>}
      {isPending && <div>Loading ...</div>}
      <div>
        Connection to server: {(ready && "Ready") || (!ready && "Not Ready")}
      </div>
      {robots && (
        <RobotsCard
          robots={robots}
          shutdownRobotClick={shutdown_robot}
          restartRobotClick={restart_robot}
        ></RobotsCard>
      )}
      {/* {robots && (
        <RobotsList
          robots={robots}
          shutdownRobotClick={shutdown_robot}
        ></RobotsList>
      )} */}
      <div>
        Connection event log:
        {/* {JSON.stringify(connectionLogRecords)} */}
      </div>
      <LogViewer LogRecords={connectionLogRecords}></LogViewer>
    </div>
  );
};

export default RobotsDashBoard;
