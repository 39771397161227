import React, { Component, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import useFetchState from "../utils/useFetchState";
import { getRobotReservationDBFetchURL } from "../common/db_setting_jobs";

const RobotReservationsList = (props) => {
  const reservations = props.Reservations;

  const history = useHistory();

  console.log("Reservations count: ", reservations.length);
  return (
    <table className="table table-striped table-sm">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Id</th>
          <th scope="col">Name</th>
          <th scope="col">Phone</th>
          <th scope="col">Company</th>
          <th scope="col">Wearable count</th>
          <th scope="col">Reservation date</th>
          <th scope="col">Reservation time</th>
          <th scope="col">Working location</th>
          <th scope="col">Returned</th>
        </tr>
      </thead>
      <tbody>
        {(!reservations || reservations.length == 0) && (
          <tr className="table-danger">
            <td colSpan={9} align="center">
              No data
            </td>
          </tr>
        )}
        {reservations &&
          reservations.map((item, index) => (
            <tr key={item.id}>
              <td>{index}</td>
              <td>
                <Link to={"/robot-reservation/" + item.id}>{item.id}</Link>
              </td>
              <td>{item.name}</td>
              <td>{item.phone}</td>
              <td>{item.company}</td>
              <td>{item.wearable_counts}</td>
              <td>{item.reservation_date}</td>
              <td>{item.reservation_time}</td>
              <td>{item.working_location}</td>
              <td>
                {" "}
                <input type="checkbox" checked={item.returned}></input>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

const WearableRobotReservationDashBoard = () => {
  const {
    data: reservations,
    error,
    isPending,
    setData,
  } = useFetchState(getRobotReservationDBFetchURL("/Reservations"));

  return (
    <div>
      <h2>Reservations</h2>
      {error && <div> {error}</div>}
      {isPending && <div>Loading ...</div>}

      {reservations && (
        <div className="table-responsive">
          <RobotReservationsList
            Reservations={reservations}
          ></RobotReservationsList>
        </div>
      )}
      <div className="table-responsive"></div>
    </div>
  );
};

export default WearableRobotReservationDashBoard;
