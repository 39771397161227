import { useEffect, useRef, useState, useCallback } from "react";

const Logger = window.console;

export const useMediaManager = (constraints = { audio: true, video: true }) => {
  const [mediaAllowed, setMediaAllowed] = useState(false);
  const [availableCameras, setAvailableCameras] = useState(null);
  const [availableMicrophones, setAvailableMicrophones] = useState(null);
  const [mediaError, setMediaError] = useState(null);

  const refMediaStream = useRef(null);

  const [mediaStream, setMediaStreamState] = useState(null);

  const setMediaStream = (stream) => {
    if (refMediaStream.current != null) {
      stopMediaStream();
    }
    refMediaStream.current = stream;
  };

  // const stopMediaStream = useCallback(() => {
  //   if (mediaStream) {
  //     mediaStream.getTracks().forEach((track) => track.stop());
  //     setMediaStream(null);
  //   }
  // }, [mediaStream]);
  const stopMediaStream = () => {
    if (refMediaStream.current != null) {
      refMediaStream.current.getTracks().forEach((track) => track.stop());
      refMediaStream.current = null;
      setMediaStreamState(null);
    }
  };

  useEffect(() => {
    if (checkWebRTCPermissions()) {
      setMediaAllowed(true);
      // init devices list
      updateAvailableDevices();
      trackDeviceChange();
    } else {
      setMediaAllowed(false);
    }
    return () => {
      stopMediaStream();
    };
  }, []);

  const getMediaInputDevices = async () => {
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        let deviceArray = new Array();
        devices.forEach((device) => {
          if (device.kind == "audioinput" || device.kind == "videoinput") {
            deviceArray.push(device);
          }
        });

        return deviceArray;
      })
      .catch((err) => {
        Logger.error(
          "Cannot get devices -> error name: " + err.name + ": " + err.message
        );
        throw err;
      });
  };

  const updateAvailableDevices = () => {
    getMediaInputDevices().then((devices) => {
      // const microphones = devices.filter(d => d.kind == "audioinput");
      // const cameras = devices.filter(d => d.kind == "videoinput");
      var microphones = new Array();
      var cameras = new Array();
      devices.forEach((device) => {
        if (device.kind == "audioinput") {
          microphones.push(device);
        } else if (device.kind == "videoinput") {
          cameras.push(device);
        }
      });
      setAvailableCameras(cameras);
      setAvailableMicrophones(microphones);
    });
  };

  const trackDeviceChange = () => {
    navigator.mediaDevices.ondevicechange = (dv, e) => {
      console.log("device changed");
      updateAvailableDevices();
    };
  };

  function checkWebRTCPermissions() {
    if (typeof navigator.mediaDevices == "undefined") {
      Logger.debug(
        "Cannot open camera and mic because of unsecure context. Please Install SSL(https)"
      );
      setMediaError(
        "Cannot open camera and mic because of unsecure context. Please Install SSL(https)"
      );
      setMediaAllowed(false);
      return false;
    }

    if (navigator.mediaDevices == undefined || navigator.mediaDevices == null) {
      Logger.debug("getUserMediaIsNotAllowed");
      setMediaError("Get mediaDevices is not allowed");
      setMediaAllowed(false);
      return false;
    }
    return true;
  }

  const startMediaStream = useCallback(
    async (mediaConstraint) => {
      let stream = null;
      setMediaError(null);
      setMediaAllowed(true);
      if (!mediaConstraint.audio && !mediaConstraint.video) {
        stream = null;
      } else if (typeof navigator.mediaDevices == "undefined") {
        Logger.debug(
          "Cannot open media devices because of unsecure context. Please Install SSL(https)"
        );
        setMediaError(
          "Cannot open media devices because of unsecure context. Please Install SSL(https)"
        );
        setMediaAllowed(false);
        throw new Error(
          "Cannot open media devices because of unsecure context. Please Install SSL(https)"
        );
      } else if (
        navigator.mediaDevices == undefined ||
        navigator.mediaDevices == null
      ) {
        console.log("Media devices not found", navigator.mediaDevices);
        setMediaError("Media devices not found!");
        setMediaAllowed(false);
        throw new Error("Media devices not found!");
      } else {
        try {
          stream = await navigator.mediaDevices.getUserMedia(mediaConstraint);
        } catch (err) {
          if (err instanceof DOMException) {
            if (
              err.name === "PermissionDeniedError" ||
              err.name === "NotAllowedError"
            ) {
              setMediaError("Permission denied for microphone!");
              setMediaAllowed(false);
            } else {
              setMediaError(err.message);
            }
          } else {
            setMediaError(err.message);
          }

          throw err;
        }
      }
      if (mediaStream != null) {
        stopMediaStream();
      }
      setMediaStream(stream);
      return stream;
    },
    [constraints]
  );

  const muteAudioTrack = () => {
    if (mediaStream != null) {
      mediaStream.getAudioTracks().forEach((t) => {
        t.enabled = false;
        console.log("Audio track muted: ", t);
      });
    }
  };

  const unmuteAudio = () => {
    if (mediaStream != null) {
      mediaStream.getAudioTracks().forEach((t) => {
        t.enabled = true;
        console.log("Audio track unmuted: ", t);
      });
    }
  };

  // const getMediaStream = async (mediaConstraint) => {
  //   return await startMediaStream(mediaConstraint);
  //   // // if (mediaStream != null) {
  //   // //   return mediaStream;
  //   // // }
  //   // // const finalConstraint = {
  //   // //   video: false,
  //   // //   audio: false,
  //   // //   ...mediaConstraint,
  //   // // };
  //   // let stream = null;

  //   // if (!mediaConstraint.audio && !mediaConstraint.video) {
  //   //   stream = null;
  //   // } else if (
  //   //   typeof navigator.mediaDevices == "undefined" ||
  //   //   navigator.mediaDevices == undefined ||
  //   //   navigator.mediaDevices == null
  //   // ) {
  //   //   console.log(
  //   //     "There is media Devices found. MediaDevices=",
  //   //     navigator.mediaDevices
  //   //   );
  //   //   stream = null;
  //   // } else
  //   //   try {
  //   //     stream = await navigator.mediaDevices.getUserMedia(mediaConstraint);
  //   //   } catch (err) {
  //   //     throw err;
  //   //   }
  //   // // if (constraints.audio && !mediaConstraint.audio) {
  //   // //   if (stream == null) {
  //   // //     stream = new MediaStream();
  //   // //   }
  //   // //   const audioTrack = getSilentAudioTrack();
  //   // //   stream.addTrack(audioTrack);
  //   // // }
  //   // // if (mediaStream != null) {
  //   // //   stopMediaStream();
  //   // // }
  //   // setMediaStream(stream);
  //   // return stream;
  // };

  const getMediaStream1 = (mediaConstraint) => {
    return new Promise((resolve, reject) => {
      const constraint = {
        video: false,
        audio: false,
        ...mediaConstraint,
      };

      if (!mediaConstraint.audio && !mediaConstraint.video) {
        // const stream = new MediaStream();
        // const blackVideoStream = getBlackVideoTrack();
        // stream.addTrack(blackVideoStream.getVideoTracks()[0]);
        resolve(null);
      } else if (
        typeof navigator.mediaDevices == "undefined" ||
        navigator.mediaDevices == undefined ||
        navigator.mediaDevices == null
      ) {
        console.log(
          "There is media Devices found. MediaDevices=",
          navigator.mediaDevices
        );
        resolve(null);
      } else
        navigator.mediaDevices
          .getUserMedia(mediaConstraint)
          .then((stream) => {
            resolve(stream);
          })
          .catch((er) => {
            reject(er);
          });
    });
  };

  const refAudioContext = useRef(new AudioContext());
  const refOscillator = useRef(null);
  const refSilentAudioTrack = useRef(null);
  const refAudioGainNode = useRef(null);

  /**
   * Silent audio track
   */
  const getSilentAudioTrack = () => {
    stopSilentAudioTrack();
    const audioContext = refAudioContext.current;

    const oscillator = audioContext.createOscillator();

    const gainNode = audioContext.createGain();

    refOscillator.current = oscillator;
    refAudioGainNode.current = gainNode;

    oscillator.connect(gainNode);

    const mediaStreamDestination = audioContext.createMediaStreamDestination();

    let dst = gainNode.connect(mediaStreamDestination);

    // oscillator.detune.value = 100;
    oscillator.start();

    oscillator.onended = function () {
      console.log("Your tone has now stopped playing!");
    };

    const initialVol = 0.01;
    // oscillator.frequency.value = 0;
    gainNode.gain.value = initialVol;
    gainNode.gain.minValue = initialVol;
    gainNode.gain.maxValue = initialVol;

    const silentAudioTrack = dst.stream.getAudioTracks()[0];

    refSilentAudioTrack.current = silentAudioTrack;
    return silentAudioTrack;
  };

  const stopSilentAudioTrack = () => {
    if (refOscillator.current != null) {
      const oscillator = refOscillator.current;
      oscillator.stop();
      oscillator.disconnect();
      refOscillator.current = null;
    }

    if (refSilentAudioTrack.current != null) {
      const silentAudioTrack = refSilentAudioTrack.current;
      silentAudioTrack.stop();
      refSilentAudioTrack.current = null;
    }
  };

  /**
   * Black Video track
   */
  const getBlackVideoTrack = ({ width = 320, height = 240 }) => {
    const canvas = Object.assign(document.createElement("canvas"), {
      width,
      height,
    });
    const ctx = canvas.getContext("2d");
    const stream = canvas.captureStream();

    //We need to send black frames within a time interval, because when the user turn off the camera,
    //player can't connect to the sender since there is no data flowing.
    //Sending a black frame in each 3 seconds resolves it.
    const synthesisVideoInterval = setInterval(() => {
      ctx.fillRect(0, 0, width, height);
    }, 3000);

    stream.getVideoTracks()[0].onended = (evt) => {
      clearInterval(synthesisVideoInterval);
    };
    return stream;
  };

  const ret = {
    mediaAllowed,
    mediaError,
    availableCameras,
    availableMicrophones,
    getMediaStream: startMediaStream,
    muteAudioTrack,
    unmuteAudio,
    stopMediaStream,
  };
  return ret;
};
