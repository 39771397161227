import "./Signup.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import React from "react";
import { useAuth } from "../../../contexts/AuthProvider";
import { signupService } from "../../../services/auth-services/signupService";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Alert, Button, Form, Input, Spin, Tooltip } from "antd";

import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";

function isValidUsername(username) {
  const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/;
  return regex.test(username);
}

export const Signup = () => {
  const [signUpLoading, setSignUpLoading] = useState(false);
  const { auth } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (auth.isAuth) {
      history.replace("/user-profile");
    }
  }, []);

  const [signupCredential, setSignupCredential] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
  });

  const signupHandler = async () => {
    try {
      setSignUpLoading(true);
      setError("");
      setMessage("");
      if (signupCredential.password === signupCredential.confirmPassword) {
        const response = await signupService(
          signupCredential.username,
          signupCredential.password,
          signupCredential.firstName,
          signupCredential.lastName
        );
        if (response.status === 201) {
          setSignUpLoading(false);
          setMessage(
            `Hello ${response.data.data.UserId}! You've successfully signed up. Please go to the Login page.`
          );
        }
      }
    } catch (err) {
      setSignUpLoading(false);
      if (err?.response?.data?.error != null) {
        setError(err.response.data.error);
      } else {
        setError(err.message);
      }
    } finally {
      setSignUpLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>

      {error && <Alert type="error" message={error} banner />}
      {message && (
        <Alert
          type="success"
          message={message}
          banner
          action={<Link to="/login">Login</Link>}
        />
      )}

      <Form layout="vertical" onFinish={signupHandler} className="signup-body">
        <Spin spinning={signUpLoading} tip="Loading">
          <div className="email-container">
            <Form.Item
              label="Username"
              name="username"
              onChange={(e) =>
                setSignupCredential({
                  ...signupCredential,
                  username: e.target.value,
                })
              }
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
                ({}) => ({
                  validator(_, value) {
                    if (!value || isValidUsername(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Username is invalid!"));
                  },
                }),
              ]}
            >
              <Input
                placeholder="Username"
                prefix={
                  <UserOutlined
                  // style={{
                  //   color: "rgba(0,0,0,.25)",
                  // }}
                  />
                }
                suffix={
                  <Tooltip title="Only alphabet, numbers, and _ is allowed. Must start with an alphabet.">
                    <InfoCircleOutlined
                    // style={{
                    //   color: "rgba(0,0,0,.45)",
                    // }}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          </div>

          <div className="password-container">
            <Form.Item
              label="Password"
              name="password"
              onChange={(e) =>
                setSignupCredential({
                  ...signupCredential,
                  password: e.target.value,
                })
              }
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </div>

          <div className="confirm-password-container">
            <Form.Item
              name="confirm-password"
              label="Confirm password"
              dependencies={["password"]}
              onChange={(e) =>
                setSignupCredential({
                  ...signupCredential,
                  confirmPassword: e.target.value,
                })
              }
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The confirm password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </div>

          <div className="name-container">
            <label htmlFor="first-name">First Name</label>
            <input
              onChange={(e) =>
                setSignupCredential({
                  ...signupCredential,
                  firstName: e.target.value,
                })
              }
              id="first-name"
              placeholder="Enter First Name"
              type="text"
            />
          </div>

          <div className="name-container">
            <label htmlFor="last-name">Last Name</label>
            <input
              onChange={(e) =>
                setSignupCredential({
                  ...signupCredential,
                  lastName: e.target.value,
                })
              }
              id="last-name"
              placeholder="Enter Last Name"
              type="text"
            />
          </div>

          <div className="signup-btn-container">
            <input value="Sign Up" type="submit" />
          </div>
          <Link to="/login">Already have an account?</Link>
        </Spin>
      </Form>
    </div>
  );
};
