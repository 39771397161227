import { useEffect } from "react";

const LogViewer = (props) => {
  const logRecords = props.LogRecords;

  // useEffect(() => {
  //   console.log("log changed, records count: ", logRecords.length);
  // }, [logRecords]);

  const options = {
    timeZone: "Asia/Seoul",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hourCycle: "h24",
    millisecond: "numeric",
  };

  return (
    <div>
      {logRecords &&
        logRecords.map((rc, idx) => (
          <div key={idx}>
            {idx}) {rc["Time"].toLocaleDateString("en-US", options)}:
            {rc["Time"].getMilliseconds()}: {rc["Message"]}
          </div>
        ))}
      {/* <textarea>        
        {logRecords && logRecords.map((rc) => JSON.stringify(rc) + "\n")}
      </textarea> */}
    </div>
  );
};

export default LogViewer;
