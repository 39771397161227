import * as React from "react";
import { useParams } from "react-router-dom";
import { getFetchURL } from "../common/setting";
import useFetch from "../utils/useFetch";
import SensorsList from "./sensors-list";

const handleSensorClick = (sensor) => {
  console.log("sensor clicked: ", sensor);
};

const RobotDetails = () => {
  const { id } = useParams();

  const {
    data: robot,
    error,
    isPending,
  } = useFetch(getFetchURL("/robots/" + id));

  return (
    <div>
      {isPending && <div> Loading... </div>}
      {error && <div>Error: {error}</div>}
      {robot && (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Robot details - {robot.id}</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group me-2">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                Send Command
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                TeleControl
              </button>
            </div>
            {/* <button
              type="button"
              className="btn btn-sm btn-outline-secondary dropdown-toggle"
            >
              <span
                data-feather="calendar"
                className="align-text-bottom"
              ></span>
              This week
            </button> */}
          </div>
        </div>
      )}
      {robot && robot.sensors && (
        <div>
          <SensorsList
            sensors={robot.sensors}
            onSensorClick={handleSensorClick}
          ></SensorsList>
        </div>
      )}
    </div>
  );
};

export default RobotDetails;
