import { useContext, useEffect, useState } from "react";
import RobotsCard from "../components/robots-card";
import { getFetchURL } from "../common/setting";

import LogViewer from "../components/common/LogViewer";
import { useUserData } from "../contexts/UserDataProvider";
import { useServerConnection } from "../contexts/ServerConnectionContext";

const UserRobotsDashBoard = () => {
  const [connectionLogRecords, setConnectionLogRecords] = useState([]);

  const { userDataState, dispatch } = useUserData();
  const robots = userDataState.robotList;
  const error = userDataState.error;
  const isPending = userDataState.robotListLoading;

  const {
    isOpen: ready,
    isConnecting,
    message: message,
    sendMessage: send,
    subscribe,
    error: wsError,
    reconnectCountDown,
  } = useServerConnection();

  useEffect(() => {
    const unsubscribe_connected = subscribe(
      "robot_event/connected",
      robotConnectedHandler
    );
    const unsubscribe_disconnected = subscribe(
      "robot_event/disconnected",
      robotDisconnectedHandler
    );

    return () => {
      if (unsubscribe_connected) {
        unsubscribe_connected();
      }
      if (unsubscribe_disconnected) {
        unsubscribe_disconnected();
      }
    };
  }, []);

  const robotConnectedHandler = (evt) => {
    console.log("EVENT: robot connected event: ", evt);
    dispatch({ type: "SET_ROBOT_CONNECTED", payload: evt });
    addLogRecord(
      "'" + evt.id + "' robot connected. " //+ JSON.stringify(robot)
    );
  };

  const robotDisconnectedHandler = (evt) => {
    console.log("EVENT: robot disconnected event: ", evt);
    dispatch({ type: "SET_ROBOT_DISCONNECTED", payload: evt });
    addLogRecord(
      "'" + evt.id + "' robot disconnected. " //+ JSON.stringify(robot)
    );
  };

  const addLogRecord = (msg) => {
    const time = new Date();
    const logRecord = { Time: time, Message: msg };
    setConnectionLogRecords((prev) => {
      return [...prev, logRecord];
    });
  };

  const restart_robot = (robot) => {
    console.log("restart robot: ", robot);
    const robot_id = robot.id;

    fetch(getFetchURL("/restart-robot"), {
      body: JSON.stringify({
        cmd: "command",
        type: "restart_robot",
        robot_id: robot_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => {
        if (!response.ok)
          throw Error("Could not restart the robot " + robot_id + '"');
        return response.json();
      })
      .then((data) => {
        if (data["result"] === "success") {
          alert("Robot " + robot_id + "is restarting");
          console.log("restarting robot_id: ", robot_id);

          // console.log("robots before delete:\n", robots);
          // const arr = robots.filter((rb) => {
          //   return rb.id !== robot_id;
          // });
          // setData(arr);
          // console.log("robots after delete:\n", arr);
        } else {
          alert("Restart result: " + data["result"] + "\n" + data["msg"]);
        }
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          alert(err.message);
        }
      });
  };

  const shutdown_robot = (robot) => {
    console.log("shutdown robot: ", robot);
    const robot_id = robot.id;

    fetch(getFetchURL("/disconnect"), {
      body: JSON.stringify({
        cmd: "command",
        type: "shutdown_robot",
        robot_id: robot_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => {
        if (!response.ok)
          throw Error("Could not shutdown the robot " + robot_id + '"');
        return response.json();
      })
      .then((data) => {
        if (data["result"] === "success") {
          dispatch({
            type: "SET_ROBOT_DISCONNECTED",
            payload: { id: robot_id },
          });
          alert("Robot " + robot_id + "is shutdowned");
          console.log("shutdown robot_id: ", robot_id);
        } else {
          alert("Shutdown result: " + data["result"] + "\n" + data["msg"]);
        }
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          alert(err.message);
        }
      });
  };

  return (
    <div>
      {error && <div> {error}</div>}
      {isPending && <div>Loading ...</div>}
      <div>
        Connection to server: {(ready && "Ready") || (!ready && "Not Ready")}
      </div>
      {robots && (
        <RobotsCard
          robots={robots}
          shutdownRobotClick={shutdown_robot}
          restartRobotClick={restart_robot}
          ShowOfflineRobots={true}
        ></RobotsCard>
      )}
      {/* {robots && (
        <RobotsList
          robots={robots}
          shutdownRobotClick={shutdown_robot}
        ></RobotsList>
      )} */}
      <div>
        Connection event log:
        {/* {JSON.stringify(connectionLogRecords)} */}
      </div>
      <LogViewer LogRecords={connectionLogRecords}></LogViewer>
    </div>
  );
};

export default UserRobotsDashBoard;
