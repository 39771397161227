import { Fragment, useEffect, useState } from "react";
import "./gocart-move-control.css";

const GoCartMoveCommander = (props) => {
  const onCommandCreated = props.OnCommandCreated;
  const enableKeyboard = props.EnableKeyboard;

  const [keysPressed, setKeysPressed] = useState(new Set());
  const controlKeys = new Set([
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    " ",
    "e",
    "d",
    "f",
    "s",
  ]);

  const handleMove = (moveDirection) => {
    let move_cmd = {
      cmd: "command",
      data: {
        cmd_type: "move",
        params: { direction: moveDirection, speed: 2 },
      },
    };
    if (props.OnCommandCreated) props.OnCommandCreated(move_cmd);
  };

  const handleStop = () => {
    let move_cmd = {
      cmd: "command",
      data: {
        cmd_type: "move",
        params: { direction: "stop", speed: 2 },
      },
    };
    if (props.OnCommandCreated) props.OnCommandCreated(move_cmd);
  };

  const handleKeyDown = (event) => {
    if (!controlKeys.has(event.key)) return;
    setKeysPressed((prevKeys) => new Set([...prevKeys, event.key]));
    event.preventDefault();
  };

  const handleKeyUp = (event) => {
    if (!controlKeys.has(event.key)) return;
    setKeysPressed((prevKeys) => {
      const updatedKeys = new Set(prevKeys);
      updatedKeys.delete(event.key);
      return updatedKeys;
    });
    event.preventDefault();
  };

  const handleKeyspressedCommand = () => {
    if (keysPressed.has(" ")) {
      return handleStop();
    }

    if (["e", "ArrowUp"].some((item) => keysPressed.has(item))) {
      return handleMove("forward");
    }

    if (["d", "ArrowDown"].some((item) => keysPressed.has(item))) {
      return handleMove("backward");
    }

    if (["s", "ArrowLeft"].some((item) => keysPressed.has(item))) {
      return handleMove("left");
    }
    if (["f", "ArrowRight"].some((item) => keysPressed.has(item))) {
      return handleMove("right");
    }
    return null;
  };

  useEffect(() => {
    console.log("Keys pressed: ", keysPressed);
    if (!onCommandCreated) return;

    handleKeyspressedCommand();
  }, [keysPressed]);

  return (
    <Fragment>
      <div
        className="robot-control"
        tabIndex={0}
        onKeyDown={enableKeyboard ? handleKeyDown : (evt) => {}}
        onKeyUp={enableKeyboard ? handleKeyUp : (evt) => {}}
      >
        <div className="control-buttons">
          <button className="up" onClick={() => handleMove("forward")}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2 L2 15 L22 15 Z" />
            </svg>
          </button>
          <button className="left" onClick={() => handleMove("left")}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 12 L15 2 L15 22 Z" />
            </svg>
          </button>
          <button className="stop" onClick={handleStop}>
            STOP
            {/* <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <rect x="6" y="6" width="12" height="12" />
          </svg> */}
          </button>
          <button className="right" onClick={() => handleMove("right")}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 12 L9 2 L9 22 Z" />
            </svg>
          </button>
          <button className="down" onClick={() => handleMove("backward")}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22 L2 9 L22 9 Z" />
            </svg>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default GoCartMoveCommander;
