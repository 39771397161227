import { Button, Col, Row, Select, Space, Tooltip, Upload } from "antd";

import {
  PlusOutlined,
  CloseOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { readJsonFile } from "../../../common/utilities";
import { validateMissionDescription } from "../../../services/mission-services";

const SpotMissionBuilder = (props) => {
  const onMissionDescriptionChanged = props.OnMissionDescriptionChanged;
  const missionDescription = props.missionDescription;

  const mission_type = missionDescription.mission_type;
  const mission_name = missionDescription.mission_name;
  const description = missionDescription.description;
  const mission_jobs = missionDescription.mission_jobs;

  const spotJobDescriptionList = props.spotJobDescriptionList;

  const findJobDescByJobName = (jobName) => {
    const spotJob = spotJobDescriptionList.find(
      (item) => item.job_name == jobName
    );
    return spotJob;
  };

  const getDisplayingMissionItems = () => {
    if (spotJobDescriptionList == null) return [];

    const items = spotJobDescriptionList.map((item) => {
      return {
        value: item.job_name,
        label: item.job_desc,
      };
    });

    return items;
  };

  const changeMissionDescriptionText = (missionDesc) => {
    let missionDescText = "SPOT patrol mission";
    if (missionDesc?.mission_jobs.length == 1) {
      const job = missionDesc.mission_jobs[0];
      if (job?.job_description?.job_desc !== null) {
        missionDescText = job.job_description.job_desc;
      }
    }
    return { ...missionDesc, description: missionDescText };
  };

  const handleEditJobAt = (index, jobDesc) => {
    if (onMissionDescriptionChanged) {
      const newJobList = missionDescription.mission_jobs.map((job, idx) => {
        if (idx === index) {
          return { ...job, job_description: jobDesc };
        } else {
          return job;
        }
      });

      const newMissionDesc = {
        ...missionDescription,
        mission_jobs: newJobList,
      };

      const mission = changeMissionDescriptionText(newMissionDesc);
      onMissionDescriptionChanged(mission);
    }
  };

  const handleDeleteJobAt = (index) => {
    if (onMissionDescriptionChanged) {
      const updatedJobList = missionDescription.mission_jobs.filter(
        (_, i) => i !== index
      );

      const newJobList = updatedJobList.map((job, idx) => {
        return { ...job, job_id: idx };
      });

      const newMissionDesc = {
        ...missionDescription,
        mission_jobs: newJobList,
      };

      const mission = changeMissionDescriptionText(newMissionDesc);
      onMissionDescriptionChanged(mission);
    }
  };

  const handleAddNewJobAt = (index, jobDesc) => {
    if (onMissionDescriptionChanged) {
      const updatedJobList = [...missionDescription.mission_jobs];
      updatedJobList.splice(index + 1, 0, {
        job_id: index + 1,
        job_description: null,
      });

      const newJobList = updatedJobList.map((job, idx) => {
        return { ...job, job_id: idx };
      });

      const newMissionDesc = {
        ...missionDescription,
        mission_jobs: newJobList,
      };

      const mission = changeMissionDescriptionText(newMissionDesc);
      onMissionDescriptionChanged(mission);
    }
  };

  const loadMissionFromFile = (file) => {
    console.log("File: ", file);
    if (file) {
      readJsonFile(file)
        .then(function (missionDesc) {
          console.log("Loaded JSON:", missionDesc);
          if (!validateMissionDescription(missionDesc)) {
            alert("Mission file is invalid.");
            return;
          }

          if (onMissionDescriptionChanged) {
            onMissionDescriptionChanged(missionDesc);
          }

          // const payload = { index: index, missionDesc: missionDesc };
          // dispatch({
          //   type: "UPDATE_MISSION_AT",
          //   payload: payload,
          // });
        })
        .catch(function (error) {
          console.error("Error loading mission from file:", error);
          alert("Loading mission file error:" + error.name);
        });
    } else {
      console.error("No file selected.");
      alert("No file selected.");
    }
    return false;
  };

  return (
    <>
      {mission_jobs &&
        mission_jobs.map((job, index) => {
          const job_id = job?.job_id;
          const job_desc = job?.job_description;
          return (
            <Row gutter={24} key={index}>
              <Col style={{ textAlign: "left" }}>
                <Space block="true" size="small">
                  Job {job_id}:
                  <Select
                    showSearch
                    style={{
                      width: 200,
                    }}
                    placeholder="Select mission"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={getDisplayingMissionItems()}
                    onSelect={(val) => {
                      const jobDesc = findJobDescByJobName(val);
                      handleEditJobAt(index, jobDesc);
                    }}
                    value={job_desc?.job_name}
                  />{" "}
                  <Tooltip title="Add job">
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        {
                          handleAddNewJobAt(index, null);
                        }
                      }}
                    />
                  </Tooltip>
                  {mission_jobs?.length > 1 && (
                    <Tooltip title="Del job">
                      <Button
                        type="primary"
                        icon={<CloseOutlined />}
                        onClick={() => {
                          {
                            handleDeleteJobAt(index);
                          }
                        }}
                      />
                    </Tooltip>
                  )}
                </Space>
              </Col>
            </Row>
          );
        })}
      {/* <Space size={"large"}>
        <Button
          icon={<DownloadOutlined />}
          size={"default"}
          block
          // onClick={btnExportClicked}
        >
          Export
        </Button>

        <Upload
          beforeUpload={loadMissionFromFile}
          name="file"
          accept=".mission,.json"
          multiple={false}
          maxCount={1}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />} block>
            Import
          </Button>
        </Upload>
      </Space> */}
    </>
  );
};

export default SpotMissionBuilder;
