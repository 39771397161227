import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useAuth } from "./AuthProvider";
import toast from "react-hot-toast";
import { useLocation, useHistory } from "react-router-dom";
import { userDataReducer, initialUserData } from "../reducer/userDataReducer";
import { getUserRobotListService } from "../services/robot-services/getUserRobotListService";

const UserDataContext = createContext();

export function UserProvider({ children }) {
  const [robotListLoading, setRobotListLoading] = useState(false);
  const [error, setError] = useState("");
  const [userDataState, dispatch] = useReducer(
    userDataReducer,
    initialUserData
  );

  const { auth } = useAuth();

  const history = useHistory();
  const location = useLocation();

  const getRobotList = async () => {
    if (auth.isAuth) {
      try {
        setRobotListLoading(true);
        const response = await getUserRobotListService(auth.token);
        dispatch({ type: "SET_ROBOTLIST", payload: response.data });
      } catch (error) {
        console.error(error);
      } finally {
        setRobotListLoading(false);
      }
    } else {
      toast("Please login first!");
      history.push({
        pathname: "/login",
        state: { from: location },
      });
    }
  };

  const isRobotOnline = (robot) => {
    const found = userDataState.robotList.find((rb) => rb.id === robot.id);
    return found && robot.status == "online";
  };

  useEffect(() => {
    getRobotList();
  }, [auth]);

  return (
    <UserDataContext.Provider
      value={{
        userDataState,
        error,
        dispatch,
        isRobotOnline,
        robotListLoading,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
}

export const useUserData = () => useContext(UserDataContext);
