import React, { useState, useEffect } from "react";
import {
  addJobDescription,
  deleteJobDescription,
  fetchJobDescriptions,
  updateJobDescription,
} from "../../services/robot-job-schemas-services";
import JobDescriptionEditor from "./ui-commands/job-description-editor";
import { Button, Popconfirm, Space, Table, Tooltip, Typography } from "antd";

import {
  DeleteTwoTone,
  EditTwoTone,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";

const SpotJobListManager = () => {
  const [jobDescriptions, setJobDescriptions] = useState([]);
  const [newJobDescription, setNewJobDescription] = useState({
    job_type: "",
    job_desc: "",
    job_name: "",
    job_params: { name: "" },
  });
  const [editingJobDescription, setEditingJobDescription] = useState(null);
  const isEditing = (record) =>
    editingJobDescription != null && record._id === editingJobDescription._id;

  useEffect(() => {
    fetchJobDescriptions()
      .then((data) => {
        setJobDescriptions(data);
      })
      .catch((error) => {
        console.error("Error fetching job descriptions:", error);
      });
  }, []);

  const handleInputChange = (e, field, isNested = false) => {
    const value = e.target.value;
    if (isNested) {
      setNewJobDescription((prevState) => ({
        ...prevState,
        job_params: {
          ...prevState.job_params,
          [field]: value,
        },
      }));
    } else {
      setNewJobDescription((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const handleEditChange = (e, field, isNested = false) => {
    const value = e.target.value;
    if (isNested) {
      setEditingJobDescription((prevState) => ({
        ...prevState,
        job_params: {
          ...prevState.job_params,
          [field]: value,
        },
      }));
    } else {
      setEditingJobDescription((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const btnAddJobDescriptionClicked = async () => {
    try {
      const addedJobDesc = await addJobDescription(newJobDescription);
      setJobDescriptions([...jobDescriptions, addedJobDesc]);
      setNewJobDescription({
        job_type: "",
        job_desc: "",
        job_name: "",
        job_params: { name: "" },
      });
    } catch (error) {
      console.error("Error adding job description:", error);
    }
  };

  const btnUpdateJobDescriptionClicked = async (id) => {
    try {
      const updatedJobDesc = await updateJobDescription(
        id,
        editingJobDescription
      );
      if (updatedJobDesc !== null) {
        setJobDescriptions(
          jobDescriptions.map((job) =>
            job._id === updatedJobDesc._id ? updatedJobDesc : job
          )
        );
        setEditingJobDescription(null);
      }
    } catch (error) {
      console.error("Error updating job description:", error);
    }
  };

  const btnDeleteJobDescriptionClicked = async (id) => {
    try {
      const data = await deleteJobDescription(id);
      setJobDescriptions(jobDescriptions.filter((job) => job._id !== id));
    } catch (error) {
      console.error("Error deleting job description:", error);
    }
  };

  const jobsColumns = [
    {
      title: "",
      dataIndex: "index",
      key: "index",
      render: (id, record, index) => {
        return index;
      },
    },
    {
      title: "Type",
      dataIndex: "job_type",
      key: "job_type",
      editable: true,
      render: (_, record) => {
        return isEditing(record) ? (
          <input
            type="text"
            value={editingJobDescription.job_type}
            onChange={(e) => handleEditChange(e, "job_type")}
          />
        ) : (
          record.job_type
        );
      },
    },
    {
      title: "Name",
      dataIndex: "job_name",
      key: "job_name",
      editable: true,
      render: (_, record) => {
        return isEditing(record) ? (
          <input
            type="text"
            value={editingJobDescription.job_name}
            onChange={(e) => {
              handleEditChange(e, "job_name");
              //   handleEditChange(e, "name", true);
            }}
          />
        ) : (
          record.job_name
        );
      },
    },
    {
      title: "Description",
      dataIndex: "job_desc",
      key: "job_desc",
      editable: true,
      render: (_, record) => {
        return isEditing(record) ? (
          <input
            type="text"
            value={editingJobDescription.job_desc}
            onChange={(e) => handleEditChange(e, "job_desc")}
          />
        ) : (
          record.job_desc
        );
      },
    },

    {
      title: "job_params",
      dataIndex: "job_params",
      key: "job_params",
      render: (_, record) => {
        return isEditing(record) ? (
          <input
            type="text"
            value={editingJobDescription.job_params.name}
            onChange={(e) => handleEditChange(e, "name", true)}
          />
        ) : (
          record.job_params.name
        );
      },
    },
    {
      title: "operation",
      dataIndex: "operation",
      key: "operation",
      render: (_, record) => {
        return isEditing(record) ? (
          <Space>
            <Popconfirm
              title="Sure to save?"
              onConfirm={() => btnUpdateJobDescriptionClicked(record._id)}
            >
              <Typography.Link
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Typography.Link>
            </Popconfirm>

            <a onClick={() => setEditingJobDescription(null)}>Cancel</a>
          </Space>
        ) : (
          <Space>
            <Tooltip title={"Edit mission"}>
              <Typography.Link
                disabled={editingJobDescription !== null}
                onClick={() => setEditingJobDescription(record)}
              >
                <EditTwoTone />
              </Typography.Link>
            </Tooltip>

            <Tooltip title="Delete">
              <Popconfirm
                title={"Sure to delete job '" + record.job_name + "'?"}
                onConfirm={() => btnDeleteJobDescriptionClicked(record._id)}
              >
                <DeleteTwoTone />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <h1>Job Description Manager</h1>
      <div>
        <h2>Add New Job</h2>
        <Space>
          <input
            type="text"
            placeholder="Job Type"
            value={newJobDescription.job_type}
            onChange={(e) => handleInputChange(e, "job_type")}
          />
          <input
            type="text"
            placeholder="Job Name"
            value={newJobDescription.job_name}
            onChange={(e) => {
              handleInputChange(e, "job_name");
              handleInputChange(e, "name", true);
            }}
          />
          <input
            type="text"
            placeholder="Job Description"
            value={newJobDescription.job_desc}
            onChange={(e) => handleInputChange(e, "job_desc")}
          />
          <input
            type="text"
            placeholder="Job Params Name"
            value={newJobDescription.job_params.name}
            onChange={(e) => handleInputChange(e, "name", true)}
          />

          <Popconfirm
            title={"Sure to add job description?"}
            onConfirm={() => btnAddJobDescriptionClicked()}
          >
            <Space>
              <PlusCircleTwoTone />
              <Typography.Link
                style={{
                  width: 180,
                }}
                type="default"
              >
                Add Job Description
              </Typography.Link>
            </Space>
          </Popconfirm>
        </Space>
      </div>
      <div>
        <h2>Job Descriptions</h2>
        <Table
          dataSource={jobDescriptions}
          columns={jobsColumns}
          pagination={false}
          rowKey={"_id"}
        ></Table>
        {/* <Button
          // onClick={handleAdd}
          block
          style={{
            marginTop: 10,
          }}
          icon={<PlusCircleTwoTone />}
        >
          Add row
        </Button> */}
      </div>
      <div>
        {editingJobDescription && (
          <JobDescriptionEditor
            value={editingJobDescription}
          ></JobDescriptionEditor>
        )}
      </div>
    </div>
  );
};

export default SpotJobListManager;
