import axios from "axios";
import { getFetchURL } from "../../common/setting";

export const signupService = async (
  username,
  password,
  firstName,
  lastName
) => {
  const url = getFetchURL("/auth/signup");
  return await axios.post(url, {
    username: username,
    password: password,
    firstname: firstName,
    lastname: lastName,
  });
};
