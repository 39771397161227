import React, { Component, useContext, useEffect, useState } from "react";

import useFetchState from "../utils/useFetchState";
import {
  getMapDBFetchURL,
  getRobotReservationDBFetchURL,
} from "../common/db_setting_jobs";
import { Link } from "react-router-dom";

const GocartMapManagerDashboard = () => {
  const {
    data: maps,
    error,
    isPending,
    setData: setMaps,
  } = useFetchState(getMapDBFetchURL("/maps"));

  const [selectedMap, setSelectedMap] = useState(null);

  const [editingMapLocation, setEditingMapLocation] = useState(null);

  const [addingMap, setAddingMap] = useState({
    name: "",
    deleted: false,
    Locations: [],
  });

  const [addingMapLocation, setAddingMapLocation] = useState({
    id: -1,
    location_preset_id: "",
    name: "",
    deleted: false,
  });

  const addNewMap = () => {
    const msg_data = addingMap;

    const POST_URL = getMapDBFetchURL("/maps");

    fetch(POST_URL, {
      body: JSON.stringify(msg_data),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => {
        if (!response.ok) throw Error("Failed to save records to database");
        return response.json();
      })
      .then((data) => {
        console.log("Success");
        console.log(JSON.stringify(data));
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          alert("Error: " + err.message);
        }
      });

    setEditingMapLocation(null);
  };

  const addNewMapLocation = () => {
    console.log("add map location:", addingMapLocation);

    selectedMap.Locations = [...selectedMap.Locations, addingMapLocation];

    update_map_to_db(selectedMap);
  };

  const saveEditingMapLocation = (mapLocation) => {
    console.log("Save edit map location:", mapLocation);

    console.log("Modified value:", editingMapLocation);
    // save to database

    console.log("Map:", selectedMap);
    // save to database

    const locationId = editingMapLocation.id;
    const index = selectedMap.Locations.findIndex((loc) => {
      return loc.id == locationId;
    });

    if (index != -1) {
      selectedMap.Locations[index] = editingMapLocation;
    }

    update_map_to_db(selectedMap);

    setEditingMapLocation(null);
  };

  const update_map_to_db = (map) => {
    console.log("Edited Map:", map);

    const msg_data = map;

    const POST_URL = getMapDBFetchURL("/maps/" + map.id);

    fetch(POST_URL, {
      body: JSON.stringify(msg_data),
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
    })
      .then((response) => {
        if (!response.ok) throw Error("Failed to save to database");
        return response.json();
      })
      .then((data) => {
        console.log("Success");
        console.log(JSON.stringify(data));
        return data;
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          alert("Error: " + err.message);
          return null;
        }
      });
  };

  const deleteMapLocation = (mapLocation) => {
    console.log("delete edit map location:", mapLocation);

    console.log("Modified value:", mapLocation);
    // save to database

    const locationId = mapLocation.id;
    const index = selectedMap.Locations.findIndex((loc) => {
      return loc.id == locationId;
    });

    if (index != -1) {
      selectedMap.Locations.splice(index, 1);
    }

    update_map_to_db(selectedMap);

    setEditingMapLocation(null);
    setSelectedMap(selectedMap);
  };

  return (
    <div>
      <h2>Available Maps</h2>
      {error && <div> {error}</div>}
      {isPending && <div>Loading ...</div>}

      {maps && (
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Id</th>
                <th scope="col">Map Name</th>
                <th scope="col">No. Locations</th>
                <th scope="col">Is deleted</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {(!maps || maps.length == 0) && (
                <tr className="table-danger">
                  <td colSpan={9} align="center">
                    No data
                  </td>
                </tr>
              )}
              {maps &&
                maps.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index}</td>
                    <td>
                      <Link
                        to="#"
                        onClick={() => {
                          setSelectedMap(item);
                        }}
                      >
                        {item.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to="#"
                        onClick={() => {
                          setSelectedMap(item);
                        }}
                      >
                        {item.name}
                      </Link>
                    </td>
                    <td>{item.Locations.length}</td>
                    <td>
                      {" "}
                      <input
                        type="checkbox"
                        readOnly
                        checked={item.deleted}
                      ></input>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => {
                          setSelectedMap(item);
                        }}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {selectedMap && (
        <div className="table-responsive">
          <h4 className="mb-3">{selectedMap.name}</h4>
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Location present</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {(!selectedMap.Locations ||
                selectedMap.Locations.length == 0) && (
                <tr className="table-danger">
                  <td colSpan={9} align="center">
                    No data
                  </td>
                </tr>
              )}
              {selectedMap.Locations &&
                selectedMap.Locations.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index}</td>
                    <td>{item.id}</td>
                    <td>
                      {editingMapLocation == null ||
                      editingMapLocation.id != item.id ? (
                        item.name
                      ) : (
                        <input
                          type="text"
                          defaultValue={editingMapLocation.name}
                          onChange={(e) =>
                            setEditingMapLocation({
                              ...editingMapLocation,
                              name: e.target.value,
                            })
                          }
                        ></input>
                      )}
                    </td>
                    <td>
                      {editingMapLocation == null ||
                      editingMapLocation.id != item.id ? (
                        item.location_preset_id
                      ) : (
                        <input
                          type="text"
                          defaultValue={editingMapLocation.location_preset_id}
                          onChange={(e) =>
                            setEditingMapLocation({
                              ...editingMapLocation,
                              location_preset_id: e.target.value,
                            })
                          }
                        ></input>
                      )}
                    </td>
                    <td>
                      {editingMapLocation == null ||
                      editingMapLocation.id != item.id ? (
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary"
                          onClick={() => {
                            setEditingMapLocation(item);
                          }}
                        >
                          Edit
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary"
                          onClick={() => {
                            saveEditingMapLocation(item);
                          }}
                        >
                          Save
                        </button>
                      )}

                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => {
                          deleteMapLocation(item);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}

              <tr key="-1">
                <td>{"."}</td>
                <td>
                  <input
                    type="text"
                    defaultValue={addingMapLocation.id}
                    onChange={(e) =>
                      setAddingMapLocation({
                        ...addingMapLocation,
                        id: e.target.value,
                      })
                    }
                  ></input>
                </td>
                <td>
                  <input
                    type="text"
                    defaultValue={addingMapLocation.name}
                    onChange={(e) =>
                      setAddingMapLocation({
                        ...addingMapLocation,
                        name: e.target.value,
                      })
                    }
                  ></input>
                </td>
                <td>
                  <input
                    type="text"
                    defaultValue={addingMapLocation.location_preset_id}
                    onChange={(e) =>
                      setAddingMapLocation({
                        ...addingMapLocation,
                        location_preset_id: e.target.value,
                      })
                    }
                  ></input>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => {
                      addNewMapLocation();
                    }}
                  >
                    Add
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default GocartMapManagerDashboard;
