import axios from "axios";
import { getFetchURL } from "../common/setting";

const apiUrl = getFetchURL("/api/spot-job-desc-list");

export const fetchJobDescriptions = async () => {
  const response = await axios.get(apiUrl);
  return response.data;
};

export const addJobDescription = async (jobDescription) => {
  const response = await axios.post(apiUrl, jobDescription);
  return response.data;
};

export const updateJobDescription = async (id, jobDesc) => {
  const response = await axios.put(`${apiUrl}/${id}`, jobDesc);
  return response.data;
};

export const deleteJobDescription = async (id) => {
  const response = await axios.delete(`${apiUrl}/${id}`);
  return response.data;
};
