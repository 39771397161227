import React from "react";
import "./Login.css";
import { BsEyeSlash } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { useState } from "react";
import { useAuth } from "../../../contexts/AuthProvider";
import { Link } from "react-router-dom/cjs/react-router-dom";

export const Login = () => {
  const [hidePassword, setHidePassword] = useState(true);
  const { error, loginCredential, setLoginCredential, loginHandler } =
    useAuth();

  const { username, password } = loginCredential;

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form
        onSubmit={(e) => loginHandler(e, username, password)}
        className="login-body"
      >
        {error && <span className="error">{error}</span>}
        <div className="email-container">
          <label htmlFor="email">Username</label>
          <input
            value={loginCredential.username}
            required
            onChange={(e) =>
              setLoginCredential({
                ...loginCredential,
                username: e.target.value,
              })
            }
            id="email"
            placeholder="Username"
          />
        </div>

        <div className="password-container">
          <label htmlFor="password">Password</label>
          <div className="input-container">
            <input
              value={loginCredential.password}
              required
              onChange={(e) =>
                setLoginCredential({
                  ...loginCredential,
                  password: e.target.value,
                })
              }
              id="password"
              placeholder="Password"
              type={hidePassword ? "password" : "text"}
            />{" "}
            {!hidePassword ? (
              <BsEye
                className="hide-show-password-eye"
                onClick={() => setHidePassword(!hidePassword)}
              />
            ) : (
              <BsEyeSlash
                className="hide-show-password-eye"
                onClick={() => setHidePassword(!hidePassword)}
              />
            )}
          </div>
        </div>

        {/* <div className="remember-me-container">
          <div>
            <input name="remember-me" type="checkbox" />
            <label htmlFor="remember-me">Keep me signed in</label>
          </div>

          <p>Forgot your password?</p>
        </div> */}

        <div className="login-btn-container">
          <input value="Login" type="submit" />
          {/* <button
            onClick={(e) => {
              loginHandler(
                e,
                loginCredential.username,
                loginCredential.password
              );
            }}
          >
            Login
          </button> */}
        </div>
        <Link className="new-account" to="/signup">
          Create a new account?
        </Link>
      </form>
    </div>
  );
};
